import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logoMarket from "../../../assets/images/logo-market.png";
import { useDispatch } from "react-redux";
import { fetchModelsSuccess } from "../../../redux/modules/models/actions";
import { useHttp } from "../../../hooks/useHttp";
import SidebarFiltersCustomFields from "../forms/SidebarFiltersCustomFields";
import { getMarketModelsRequest } from "../../../api/modules/models";
import { on, trigger } from "../../../helpers/events";

const SidebarMarketPlace = (props) => {
  const dispatch = useDispatch();
  const { http } = useHttp();
  const [searchValue, setSearchValue] = useState();

  React.useEffect(() => {
    on("searchValueChanged", (event) => {
      setSearchValue(event.detail);
    });
  }, []);

  const onSubmit = async (data) => {
    trigger("sidebarFiltersChanged", data);
    const models = await http(() =>
      getMarketModelsRequest(
        {
          sortBy: "createdAt",
          sortValue: "desc",
          page: 0,
          ...(searchValue ? { search: searchValue } : {}),
        },
        data
      )
    );

    dispatch(fetchModelsSuccess(models));
  };

  return (
    !props.hideSideMenu && (
      <>
        <div
          style={{ zIndex: "99" }}
          className={`fixed top-0 bottom-0 lg:left-0 ${props.showSidebar} overflow-y-auto flex-row flex-nowrap  shadow-xl bg-white w-64 px-6 transition-all duration-300`}
        >
          <NavLink to="/models" className=" text-center w-full inline-block">
            <div className="logo-container">
              <img src={logoMarket} className={"w-32 lg:mx-12"} />
            </div>
          </NavLink>
          <div
            className="flex flex-col overflow-y-auto items-stretch flex-nowrap px-0 relative py-2"
            style={{ maxHeight: "calc(100vh - 70px)" }}
          >
            <br />
            <div className="flex flex-col flex-1">
              <div className="w-full  items-center justify-center flex-row ">
                <SidebarFiltersCustomFields onSubmit={onSubmit} />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default SidebarMarketPlace;
