import {
  END_USER_LOGIN_ERROR,
  END_USER_LOGIN_LOADING,
  END_USER_LOGIN_SUCCESS,
  RESET_STATE,
  VERIFY_CODE_SUCCESS,
} from "./constants";

export const initialState = {
  loginHasError: [],
  loginIsLoading: false,
  loginSuccess: false,
  verifyCodeSuccess: false,
};

const loginEndUserError = (state, action) => {
  return {
    ...state,
    loginIsLoading: false,
    loginSuccess: false,
    loginHasError: action.payload,
  };
};

const loginSucccess = (state, action) => {
  return {
    ...state,
    loginHasError: [],
    loginIsLoading: false,
    loginSuccess: true,
  };
};

const loginIsLoading = (state, action) => {
  return {
    ...state,
    loginHasError: [],
    loginSuccess: false,
    loginIsLoading: true,
  };
};

const verifyCodeSuccess = (state, action) => {
  return {
    ...state,
    loginHasError: [],
    loginSuccess: false,
    verifyCodeSuccess: true,
  };
};

const resetEndUserLoginState = (state, action) => {
  return {
    ...state,
    loginHasError: [],
    loginIsLoading: false,
    loginSuccess: false,
    verifyCodeSuccess: false,
  };
};

const handlers = {
  [END_USER_LOGIN_ERROR]: loginEndUserError,
  [END_USER_LOGIN_LOADING]: loginIsLoading,
  [END_USER_LOGIN_SUCCESS]: loginSucccess,
  [VERIFY_CODE_SUCCESS]: verifyCodeSuccess,
  [RESET_STATE]: resetEndUserLoginState,
};

export default function loginEndUser(state = initialState, action) {
  const handler = handlers[action.type];

  if (!handler) return state;
  return handler(state, action);
}
