import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoadingIcon from "../icons/LoadingIcon";
const MainButton = ({
  action,
  type,
  title,
  link,
  extraClasses,
  width,
  isLoading,
  alert,
  Icon,
  success,
  padding,
  disabled,
}) => {
  const [actionIsLoading, setActionIsLoading] = useState(false);

  const classList = `relative text-center text-white bg-gradient-to-r ${
    alert
      ? "bg-alertFade"
      : disabled
      ? "bg-gray-500"
      : success
      ? "bg-green"
      : "from-primary to-lightPrimary"
  } font-bold   rounded-4xl shadow hover:shadow-lg outline-none focus:outline-nonenpm ease-linear transition-all duration-150 flex justify-center items-center
   ${disabled ? "cursor-default" : "cursor-pointer"}
   ${extraClasses || ""} ${width || "w-full"} ${
    !padding ? "px-4 sm:px-8 py-3" : padding
  }`;
  if (link) {
    return (
      <Link to={link}>
        <div className={classList}>
          {Icon && <span className={"mr-1"}>{Icon}</span>}
          {title}
        </div>
      </Link>
    );
  }

  return (
    <button
      disabled={disabled || isLoading || actionIsLoading}
      type={type}
      onClick={async (e) => {
        setActionIsLoading(true);
        try {
          await action(e);
        } finally {
          setActionIsLoading(false);
        }
      }}
      className={classList}
    >
      {(isLoading || actionIsLoading) && <LoadingIcon />}
      {Icon && <span className={"mr-1"}>{Icon}</span>}
      {title}
    </button>
  );
};

export default MainButton;
