export const ADMIN_CATEGORIES_TABLE_COLUMNS = [
  {
    field: "name",
    valueGetter: (params) => `${params.row.name}`,
    headerName: "Name",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "subcategories",
    valueGetter: (params) => {
      const subCatsNames =
        params.row.subcategories.map((row) => row.name) || [];

      return subCatsNames.join(", ");
    },
    headerName: "Subcategories",
    minWidth: 150,
    flex: 1,
  },
];
