import MainButton from "../buttons/MainButton";
import Modal from "../pageElementes/Modal";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { CheckCircleOutlined } from "@mui/icons-material";
import { useState } from "react";
const ConfirmModal = ({
  setVisibility,
  visibility,
  title,
  description,
  submitAction,
  buttonText,
  isGreenConfirmation,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const hideModal = () => {
    setVisibility(false);
  };
  return (
    <Modal visibility={visibility} setVisibility={hideModal}>
      <div className="w-full flex flex-col justify-start items-center flex-1">
        <div className="w-full text-center flex flex-col justify-center items-center">
          {isGreenConfirmation ? (
            <CheckCircleOutlined
              className="text-green"
              sx={{ fontSize: 131 }}
            />
          ) : (
            <CancelOutlinedIcon
              className="text-red-500"
              sx={{ fontSize: 131 }}
            />
          )}
        </div>
        <h3>{title}</h3>
        <p
          className="flex  align-center text-center justify-center"
          style={{ color: "rgb(220 38 38)", fontWeight: "bold" }}
        >
          {" "}
          {description}
        </p>
        <div className="w-full flex-col sm:flex-row flex justify-center items-center mt-4">
          <div className="w-full sm:w-1/2 px-2">
            <MainButton
              action={() => hideModal()}
              title="Cancel"
              extraClasses={"cursor-pointer"}
              width="w-full "
            />
          </div>
          <div className="w-full sm:w-1/2 p-2">
            <MainButton
              isLoading={isLoading}
              alert={!isGreenConfirmation}
              success={isGreenConfirmation}
              action={async () => {
                setIsLoading(true);
                try {
                  await submitAction();
                  hideModal();
                } catch (e) {
                } finally {
                  setIsLoading(false);
                }
              }}
              title={buttonText || "Remove"}
              width="w-full"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmModal;
