export const ADMIN_CUSTOM_FIELDS_TABLE_COLUMNS = [
  {
    field: "category",
    valueGetter: (params) => `${params.row.category?.name || "N/A"}`,
    headerName: "Category",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "subCategory",
    valueGetter: (params) => `${params.row.subCategory?.name || "N/A"}`,
    headerName: "Subcategory",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "name",
    headerName: "Name",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "filter",
    renderCell: (params) => {
      return (
        <span
          className={`${
            params.row.isFilter ? "bg-blue-100" : "bg-red-300"
          } text-blue-800 text-xs font-semibold mr-2 mb-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800`}
        >
          {params.row?.isFilter ? "Yes" : "No"}
        </span>
      );
    },
    headerName: "Filter",
    flex: 1,
  },
  {
    field: "isMandatory",
    renderCell: (params) => {
      return (
        <span
          className={`${
            params.row.isMandatory ? "bg-blue-100" : "bg-red-300"
          } text-blue-800 text-xs font-semibold mr-2 mb-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800`}
        >
          {params.row?.isMandatory ? "Yes" : "No"}
        </span>
      );
    },
    headerName: "Is Mandatory?",
    flex: 1,
  },
  {
    field: "type",
    renderCell: (params) => {
      return (
        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 mb-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
          {params.row?.type}
        </span>
      );
    },
    headerName: "Type",
    minWidth: 100,
    flex: 1,
  },
];
