import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { MenuItem, Select, Switch, TextareaAutosize } from "@mui/material";
import { useSelector } from "react-redux";
import MainButton from "../buttons/MainButton";
import { useNavigate, useParams } from "react-router-dom";
import { useModalContext } from "../../../contexts/SuccessModalContext";

import { getAllCustomFieldsRequest } from "../../../api/modules/customFields";
import { useHttp } from "../../../hooks/useHttp";
import ConfirmModal from "../modals/ConfirmModal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SecondaryButton from "../buttons/SecondaryButton";
import LoadingIcon from "../icons/LoadingIcon";
import * as Yup from "yup";
import ModelFormCustomFields from "./ModelFormCustomFields";
import { getCategoriesRequest } from "../../../api/modules/categories";
import {
  createModelRequest,
  deleteModelRequest,
  updateModelCoverImageRequest,
  updateModelRequest,
} from "../../../api/modules/models";
import { isAdminOrSupport } from "../../../helpers/isAdminOrSupport";
import ModelFormCoverImageUpload from "./ModelFormCoverImageUpload";
import ModelFormFileUpload from "./ModelFormFileUpload";

const ModelForm = ({ singleModel }) => {
  const { id } = useParams();

  const [filesModel, setFilesModel] = React.useState([]);
  const [filesCoverImage, setFilesCoverImage] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({});
  const [initialCustomFields, setInitialCustomFields] = useState({});

  const [subcategories, setSubcategories] = React.useState([]);
  const [removeModal, setRemoveModal] = useState(false);
  const navigate = useNavigate();
  const { http, backendErrors, isLoading } = useHttp();
  const { user } = useSelector((state) => state.userData);
  const [categories, setCategories] = useState([]);
  const state = useSelector((state) => state.auth);
  const currentUser = state.user;
  const formData = new FormData();
  const [customFields, setCustomFields] = useState();

  const notAllFilesUploaded = !(filesModel.length && filesCoverImage?.length);

  const initialValidations = {
    name: Yup.string().required("This field is Required"),
    category: Yup.string().required("This field is required"),
    subcategory: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is Required"),
  };

  const [yupValidations, setYupValidations] = useState(initialValidations);

  useEffect(() => {
    if (!customFields) return;

    const customFieldsValidations = {};
    customFields?.map((customField) => {
      customFieldsValidations[`customFields_${customField._id}`] =
        customField.isMandatory &&
        Yup.mixed().required("This field is required");
    });

    setYupValidations({
      ...initialValidations,
      ...customFieldsValidations,
    });
  }, [customFields]);

  const { setOpenState } = useModalContext();

  useEffect(() => {
    if (!singleModel) return;

    setSubcategories(singleModel?.category?.subcategories);

    if (singleModel?.customFields?.length) {
      setCustomFields(singleModel?.customFields || []);

      const customFormFields = {};
      singleModel?.customFields?.map((customField) => {
        customFormFields[`customFields_${customField._id}`] = customField.value;
      });
      setInitialCustomFields(customFormFields);
    } else if (singleModel?.subcategory) {
      getCustomFieldsBySubCategoryId(singleModel?.subcategory?._id).then(
        (res) => setCustomFields(res)
      );
    } else {
      setCustomFields([]);
    }
  }, [singleModel]);

  useEffect(() => {
    http(() => getCategoriesRequest()).then((res) => setCategories(res));
  }, []);

  const callback = () => {
    if (isAdminOrSupport(user.role)) {
      navigate("/models");
    } else if (user.role === "userA") {
      navigate("/my-models");
    }
    setOpenState(true);
  };

  const validateFileUploads = () => {
    return filesModel?.length && filesCoverImage?.length;
  };

  const prepareCustomFields = (formFields) => {
    const _customFields = [];
    Object.keys(formFields).map((field) => {
      if (!field.startsWith("customFields_")) {
        return;
      }

      const customFieldId = field.replace("customFields_", "");
      const customField = customFields.find(
        (customField) => customField._id === customFieldId
      );
      if (!customField) {
        return;
      }

      _customFields.push({
        ...customField,
        value: formFields[field],
      });
    });

    return _customFields;
  };

  const prepareFormData = (data) => {
    const isMarketplace = data.marketplace?.[0] === "on" ? "true" : "false";

    formData.set("name", data.name);
    formData.set("otherDetail", data.otherDetail);
    formData.set("description", data.description);
    formData.set("category", data.category);
    formData.set("subcategory", data.subcategory);
    formData.set("marketplace", isMarketplace);

    if (data.customFields) {
      formData.set("customFields", JSON.stringify(data.customFields));
    }

    if (filesModel.length) {
      formData.set("model", filesModel[0]);
    }

    if (filesCoverImage.length) {
      formData.set("coverImage", filesCoverImage[0]);
    }

    return formData;
  };

  const handleSubmit = async (data) => {
    data.customFields = prepareCustomFields(data);

    if (id) {
      if (filesCoverImage?.length > 0) {
        const formData = new FormData();
        formData.set("coverImage", filesCoverImage[0]);
        await updateModelCoverImageRequest(id, formData);
      }
      await http(() => updateModelRequest(id, data)).then(() => callback());
    } else {
      if (validateFileUploads()) {
        const formData = prepareFormData(data);
        await http(() => createModelRequest(formData)).then(() => callback());
      }
    }
  };

  const showBackendErrors = () => {
    return backendErrors?.map((e) => (
      <li className="text-xs text-red-500 " key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };

  const removeModel = () => {
    http(() => deleteModelRequest(singleModel._id)).then(() => callback());
  };

  const getCustomFieldsBySubCategoryId = async (subCategoryId) => {
    return http(() => getAllCustomFieldsRequest({ subCategoryId }));
  };

  useEffect(() => {
    if (!singleModel) return;

    setInitialValues({
      ...singleModel,
      category: singleModel?.category?._id,
      subcategory: singleModel?.subcategory?._id,
      ...initialCustomFields,
    });
  }, [singleModel, initialCustomFields]);

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: Yup.object().shape(yupValidations),
  });

  const handleCategoryChange = async (catId) => {
    setCustomFields([]);

    const catObject = (categories || []).find((cat) => cat.id === catId);
    setSubcategories(catObject?.subcategories || []);
    await formik.setFieldValue("subcategory", "");
  };

  const handleSubcategoryChange = async (subCatId) => {
    setCustomFields([]);

    await formik.setFieldValue("subcategory", subCatId);

    if (subCatId) {
      const res = await getCustomFieldsBySubCategoryId(subCatId);
      setCustomFields(res);
      res?.map((customField) => {
        formik.setFieldTouched(`customFields_${customField._id}`, true);
      });
    }
  };

  useEffect(() => {
    handleCategoryChange(formik.values?.category);
  }, [formik.values?.category]);

  useEffect(() => {
    handleSubcategoryChange(formik.values?.subcategory);
  }, [formik.values?.subcategory]);

  if (id && (!singleModel || !customFields)) {
    return <LoadingIcon />;
  }

  return (
    <div className="py-2 align-middle inline-block min-w-full w-full">
      <form
        onSubmit={formik.handleSubmit}
        className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap"
      >
        <ModelFormFileUpload
          onUpload={setFilesModel}
          singleModel={singleModel}
          onFinalModelUploadCallback={callback}
        />
        <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Model name
              <span className="text-xs text-red-500">*</span>
            </h3>
            <span className="text-xs text-blue-400">Mandatory field</span>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <input
                type={"text"}
                name={"name"}
                placeholder={"Model name"}
                onChange={formik.handleChange}
                value={formik?.values?.name || ""}
                className={`flex flex-1 appearance-none w-1/2 py-3 leading-tight focus:outline-none`}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {formik.errors?.name}
            </p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex font-medium leading-6 text-gray-900">
              Other detail
            </h3>
            <span className="text-xs text-blue-400 invisible">
              Not mandatory field
            </span>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <input
                type={"text"}
                name={"otherDetail"}
                placeholder={"Other detail"}
                onChange={formik.handleChange}
                value={formik?.values?.otherDetail || ""}
                className={`flex flex-1 appearance-none w-1/2 py-3 leading-tight focus:outline-none`}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {formik.errors?.otherDetail}
            </p>
          </div>
        </div>
        <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Category
              <span className="text-xs text-red-500">*</span>
            </h3>
            <span className="text-xs text-blue-400">Mandatory field</span>
            <div
              className={`w-full flex justify-center items-center px-7 border border-fade rounded-4xl my-4`}
            >
              <Select
                name={"category"}
                style={{ width: "100%" }}
                value={formik.values?.category || ""}
                label="Category"
                onChange={formik.handleChange}
              >
                {categories?.map((cat) => (
                  <MenuItem value={cat.id} key={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <p className="text-xs text-red-500 ">{formik.errors?.category}</p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Subcategory
              <span className="text-xs text-red-500">*</span>
            </h3>
            <span className="text-xs text-blue-400">Mandatory field</span>
            <div
              className={`w-full flex justify-center items-center px-7 border border-fade rounded-4xl my-4`}
            >
              <Select
                name={"subcategory"}
                style={{ width: "100%" }}
                value={formik.values?.subcategory || ""}
                label="Subcategory"
                onChange={formik.handleChange}
              >
                {subcategories?.map((subcat) => (
                  <MenuItem value={subcat._id} key={subcat._id}>
                    {subcat.name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <p className="text-xs text-red-500 ">
              {formik.errors?.subcategory}
            </p>
          </div>
        </div>

        {customFields && (
          <ModelFormCustomFields
            customFields={customFields}
            formikProps={formik}
          />
        )}

        <div className="w-full px-2">
          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
            Client description
            <span className="text-xs text-red-500">*</span>
          </h3>
          <span className="text-xs text-blue-400">Mandatory field</span>

          <TextareaAutosize
            className="textArea"
            aria-label="minimum height"
            minRows={3}
            name={"description"}
            placeholder="A few words about your client..."
            onChange={formik.handleChange}
            value={formik?.values?.description}
          />
          <p className="text-xs text-red-500 ">{formik.errors?.description}</p>
        </div>

        <ModelFormCoverImageUpload
          onUpload={setFilesCoverImage}
          singleModel={singleModel}
        />

        <div className="w-full flex justify-center items-center px-2">
          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
            Add this model to marketplace
          </h3>

          <div className={` flex justify-center  items-center`}>
            <Switch
              name={"marketplace"}
              defaultChecked={singleModel?.marketplace}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div
          className={"w-full flex justify-center items-center flex-col py-4"}
        >
          <MainButton
            action={formik.handleSubmit}
            title="Save"
            type={"submit"}
            isLoading={isLoading}
            width="w-full sm:w-2/6"
            disabled={
              !formik.isValid || isLoading || (!id && notAllFilesUploaded)
            }
          />
          <br />
          <ul>{showBackendErrors()}</ul>

          <div
            className={"w-full flex justify-center items-center flex-col py-4"}
          >
            {id &&
              (isAdminOrSupport(currentUser.role) ||
                singleModel.userId._id === currentUser.id) && (
                <>
                  {/* TODO: Uncomment this when duplicate modal functionality is needed */}
                  {/* <SecondaryButton */}
                  {/*  Icon={<FileCopyIcon />} */}
                  {/*  action={() => setDuplicateModal(true)} */}
                  {/*  title="Duplicate model" */}
                  {/*  color="text-primary" */}
                  {/*  width="w-full sm:w-2/6" */}
                  {/* /> */}
                  <SecondaryButton
                    Icon={<DeleteForeverIcon />}
                    action={() => setRemoveModal(true)}
                    title="Remove this model"
                    width="w-full :w-2/6"
                  />
                </>
              )}
          </div>
        </div>
      </form>

      {removeModal && (
        <ConfirmModal
          visibility={removeModal}
          setVisibility={setRemoveModal}
          submitAction={removeModel}
          title="Are you sure you want to remove this model?"
          buttonText="Remove model"
          description={`This action is irreversible`}
        />
      )}

      {/* TODO: Uncomment this when duplicate modal functionality is needed */}
      {/* {duplicateModal && <DuplicateModelModal singleModel={singleModel} />} */}
    </div>
  );
};

export default ModelForm;
