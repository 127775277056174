import {
  PARTNERS_LOADING,
  PARTNERS_ERROR,
  PARTNERS_SUCCESS,
  PARTNERS_FETCH_USERS,
  PARTNERS_ERROR_CLEAR,
  PARTNERS_FETCH_SINGLE_USER,
} from "./constants";
import axios from "axios";
import cookie from "js-cookie";
import clearAllStoreData from "../../resetAction";
export const getCookie = (key) => {
  if (process.browser) {
    return cookie.get(key);
  }
};
export function partnersError(data) {
  return {
    type: PARTNERS_ERROR,
    payload: data,
  };
}

export function partnersLoading() {
  return {
    type: PARTNERS_LOADING,
  };
}

export function partnersSuccess(data) {
  return {
    type: PARTNERS_SUCCESS,
    payload: data,
  };
}
export function fetchPartnerUsers(data) {
  return {
    type: PARTNERS_FETCH_USERS,
    payload: data,
  };
}
export function fetchPartnerSingleUser(data) {
  return {
    type: PARTNERS_FETCH_SINGLE_USER,
    payload: data,
  };
}
export function createPartnerUsers(data) {
  return {
    type: PARTNERS_SUCCESS,
    payload: data,
  };
}

export function errorClear() {
  return {
    type: PARTNERS_ERROR_CLEAR,
  };
}

export function fetchUsers({ search, page, limit }) {
  return (dispatch) => {
    dispatch(partnersLoading());
    const token = getCookie("uac");
    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }${search ? "&search=" + search : ""}`;
    axios
      .get(`${process.env.REACT_APP_API}/partner/users${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(fetchPartnerUsers(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        if (err.response && err.response.data)
          dispatch(partnersError(err.response.data.errors));
      });
  };
}
export const addArchitectUser = (data, callback) => async (dispatch) => {
  dispatch(partnersLoading());
  const token = getCookie("uac");

  axios
    .post(`${process.env.REACT_APP_API}/partner/users/create`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(createPartnerUsers(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(partnersError(err.response.data.errors));
      }
    });
};
export const fetchSingleUser = (id) => async (dispatch) => {
  dispatch(partnersLoading());
  const token = getCookie("uac");
  axios
    .get(`${process.env.REACT_APP_API}/partner/users/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(fetchPartnerSingleUser(response.data));
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(partnersError(err.response.data.errors));
      }
    });
};
export const singleUserUpdate =
  (id, formData, callback) => async (dispatch) => {
    dispatch(partnersLoading());
    const token = getCookie("uac");

    axios
      .put(
        `${process.env.REACT_APP_API}/partner/users/update/${id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(fetchPartnerSingleUser(response.data));
        if (callback) callback();
      })

      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        dispatch(partnersError(err.response.data.errors));
      });
  };
export const removeAUser = (id, callback) => async (dispatch) => {
  dispatch(partnersLoading());
  const token = getCookie("uac");
  axios
    .delete(`${process.env.REACT_APP_API}/partner/users/remove/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(partnersSuccess(response.data));

      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(partnersError(err.response.data.errors));
      }
    });
};

export const clearError = () => async (dispatch) => {
  dispatch(errorClear());
};
