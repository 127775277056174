import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import GroupIcon from "@mui/icons-material/Group";
import { ADMIN_SUBSCRIPTION_PLANS_TABLE_COLUMNS } from "./adminSubscriptionPlans.constants";
import {
  deleteSubscriptionPlanRequest,
  getSubscriptionPlansRequest,
} from "../../api/modules/subscriptionPlans";
import SquareButton from "../../Components/ui/buttons/SquareButton";
import ConfirmModal from "../../Components/ui/modals/ConfirmModal";
import { useHttp } from "../../hooks/useHttp";
const AdminSubscriptionPlansList = ({ data }) => {
  const navigate = useNavigate();
  const { http, isLoading } = useHttp();
  const [backendToastMessage, setBackendToastMessage] = useState();

  const [removeModal, setRemoveModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const fetchSubscriptionPlans = () => {
    http(() => getSubscriptionPlansRequest()).then((res) =>
      setSubscriptionPlans(res)
    );
  };

  useEffect(() => {
    fetchSubscriptionPlans();
  }, []);

  const removeSubmit = async () => {
    try {
      await http(() => deleteSubscriptionPlanRequest(selectedID));
      fetchSubscriptionPlans();
    } catch (e) {
      const errorMessage = e.data.message;
      setBackendToastMessage(errorMessage);
      throw e;
    }
  };

  const columns = [
    ...ADMIN_SUBSCRIPTION_PLANS_TABLE_COLUMNS,
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <Fragment key={params.id}>
          <SquareButton
            action={() => {
              navigate(`/subscription-plans/${params.id}`);
            }}
            extraClasses={"text-xs"}
            padding={"px-2 py-1"}
            title="Edit"
            fill={true}
          />
          <SquareButton
            padding={"px-2 py-1"}
            extraClasses={"text-xs"}
            action={() => {
              setSelectedID(params.id);
              setRemoveModal(true);
            }}
            title="Delete"
            fill={false}
          />
        </Fragment>
      ),
    },
  ];

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        Icon={GroupIcon}
        action={() => navigate("/subscription-plans/new")}
        buttonTitle="Add Subscription Plan"
      />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadowBox overflow-hidden ">
              <div style={{ width: "100%" }}>
                <DataGrid
                  loading={isLoading}
                  style={{ border: "0", padding: "10px 20px" }}
                  rows={subscriptionPlans}
                  columns={columns}
                  checkboxSelection={false}
                  pageSize={10}
                  autoHeight={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        buttonText="Remove Subscription Plan"
        visibility={removeModal}
        setVisibility={() => {
          setRemoveModal(false);
          setBackendToastMessage();
        }}
        submitAction={removeSubmit}
        title="Are you sure you want to remove this subscription plan?"
        description={backendToastMessage || `This action is irreversible.`}
      />
    </div>
  );
};

export default AdminSubscriptionPlansList;
