import React, { useMemo } from "react";

import rightMainImg from "../../assets/images/landing-page/about-big.png";
import icon1 from "../../assets/images/landing-page/about-icon-1.png";
import icon2 from "../../assets/images/landing-page/about-icon-2.png";
import icon3 from "../../assets/images/landing-page/about-icon-3.png";

import bgGraphics1 from "../../assets/images/landing-page/small-graphics-5.png";
import bgGraphics2 from "../../assets/images/landing-page/small-graphics-6.png";

const AboutSection = () => {
  const steps = useMemo(() => {
    return [
      {
        title: "AR design for your home",
        description:
          "Make sure your house is perfect before you buy it with our AR design tool.",
        icon: icon1,
      },
      {
        title: "Find the perfect model for your needs",
        description:
          "Customize models and discuss them with your architect to get the most out of your project.",
        icon: icon2,
      },
      {
        title: "Experience architecture in a whole new way",
        description:
          "Check out some of the best projects from our marketplace and bookmark your favorites.",
        icon: icon3,
      },
    ];
  });
  return (
    <div id="about">
      <div className="relative flex w-full flex-col lg:flex-row my-24 lg:my-0">
        <div className="relative w-full lg:w-1/2 flex flex-col justify-center z-10 text-left px-12 lg:my-0 lg:pl-48 space-y-4">
          <div className="relative">
            <h1 className="text-5xl font-bold">About Xreteh</h1>
            <h1
              className="absolute text-8xl lg:text-9xl font-bold lg:left-24 text-gray-200"
              style={{ marginTop: "-1em", zIndex: -1 }}
            >
              About
            </h1>
            <div
              className="absolute hidden lg:flex  top-0 right-0"
              style={{ marginTop: "-4em", zIndex: -1 }}
            >
              <img src={bgGraphics1} />
            </div>
          </div>
          <p>
            The best augmented reality/ AR application for architects. With our
            app, you can overlay digital designs onto physical spaces in
            real-time, allowing you to get a more accurate sense of how your
            project will look when it's finished. We make it easy for architects
            to experiment with different design ideas and get feedback from
            colleagues and clients in real-time.
            <br />
            Augmented Reality is changing the way architects design and
            visualize their projects. XRETEH offers the best AR app for
            architects, making the design process easier and more efficient.
          </p>
          <br />
          {steps.map((step, idx) => {
            return (
              <div key={idx} className="flex flex-row space-x-8 items-center">
                <div className="rounded-square-icon bg-gradient-to-r from-primary to-lightPrimary">
                  <img src={step.icon}></img>
                </div>
                <div className="flex flex-col">
                  <h3 className="text-left">{step.title}</h3>
                  <p>{step.description}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="w-full lg:w-1/2 flex flex-col justify-center z-10">
          <img src={rightMainImg}></img>
        </div>

        <div
          className="absolute hidden lg:flex top-0"
          style={{ left: "-6rem" }}
        >
          <img src={bgGraphics2} />
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
