import { request } from "../api.service";

export const createEndUserRequest = (data) => {
  return request("/end-users/create", "post", data);
};

export const getEndUsersRequest = () => {
  return request("admin/users/end-user", "get");
};

export const getActiveEndUsersRequest = () => {
  return request("admin/users/end-user?status=true", "get");
};

export const deleteEndUserRequest = (id) => {
  return request(`admin/users/end-user/remove/${id}`, "delete");
};

export const assignClientstoEndUserRequest = (data) => {
  return request("admin/users/end-user/assign-clients", "post", data);
};

export const assignEndUserToClientRequest = (data) => {
  return request(`end-users/assign-to-client`, "post", data);
};

export const updateEndUserStatusRequest = (id, data) => {
  return request(`admin/users/end-user/status/${id}`, "patch", data);
};
