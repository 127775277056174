import {
  FETCH_ANALYTICS_DATA_ERROR,
  FETCH_ANALYTICS_DATA_LOADING,
  FETCH_ANALYTICS_DATA_SUCCESS,
} from "./constants";

export const initialState = {
  analyticsData: [],
  isLoading: false,
  hasError: [],
};

const fetchAnalyticsError = (state, action) => {
  return {
    ...state,
    hasError: action.payload,
    isLoading: false,
  };
};

const fetchAnalyticsLoading = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchAnalyticsSuccess = (state, action) => {
  return {
    analyticsData: action.data,
    isLoading: false,
    hasError: false,
  };
};

const handlers = {
  [FETCH_ANALYTICS_DATA_ERROR]: fetchAnalyticsError,
  [FETCH_ANALYTICS_DATA_LOADING]: fetchAnalyticsLoading,
  [FETCH_ANALYTICS_DATA_SUCCESS]: fetchAnalyticsSuccess,
};

export default function analyticsData(state = initialState, action) {
  const handler = handlers[action.type];

  if (!handler) return state;
  return handler(state, action);
}
