import { request } from "../api.service";

export const getAllModelsCustomSizesRequest = () => {
  return request(`/model-custom-sizes`, "get");
};
export const createModelCustomSizeRequest = (data) => {
  return request(`/model-custom-sizes`, "post", data);
};

export const getModelCustomSizeRequest = async (id) => {
  return request(`/model-custom-sizes/${id}`, "get");
};

export const updateModelCustomSizeRequest = (id, data) => {
  return request(`/model-custom-sizes/${id}`, "put", data);
};

export const deleteModelCustomSizeRequest = (id) => {
  return request(`/model-custom-sizes/${id}`, "delete");
};
