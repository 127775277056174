import React from "react";
import CheckboxFull from "../primitives/CheckboxFull";
import Checkbox from "../primitives/Checkbox";
import FaceIcon from "@mui/icons-material/Face";
const ListLongCard = ({ id, name, subname, handleClick, image, checked }) => {
  return (
    <div
      className={`w-full flex justify-between items-center my-2 p-2 relative cursor-pointer ${
        checked && "bg-primary bg-opacity-20"
      }`}
      onClick={() => handleClick(id, checked)}
    >
      <div className="flex flex-row justify-start items-center">
        <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-between items-center rounded-full overflow-hidden">
          {image ? (
            <img src={image} alt={name} className="w-full object-cover h-10" />
          ) : (
            <FaceIcon className="text-white" />
          )}
        </div>

        <div className="text-sm font-medium text-gray-900 ml-4">
          {name}
          <div className="text-sm text-gray-500">{subname}</div>
        </div>
      </div>
      {checked ? (
        <div className="right-0">
          <CheckboxFull />
        </div>
      ) : (
        <div className="right-0">
          <Checkbox />
        </div>
      )}
    </div>
  );
};

export default ListLongCard;
