import React, { useEffect, useState } from "react";
import {
  createACustomField,
  getCustomFields,
} from "../../redux/modules/admin/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import FaceIcon from "@mui/icons-material/Face";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import MainButton from "../../Components/ui/buttons/MainButton";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import Chip from "@mui/material/Chip";
import { getCategoriesRequest } from "../../api/modules/categories";
import { useHttp } from "../../hooks/useHttp";

const CreateCustomField = (props) => {
  const dispatch = useDispatch();
  const { http } = useHttp();

  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [categories, setCategories] = useState([]);

  const [subCategories, setSubCategories] = React.useState([]);

  const [isFilter, setIsFilter] = useState(true);
  const [multiSelect, setMultiSelect] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    http(() => getCategoriesRequest()).then((res) => setCategories(res));
  }, []);

  const createCustomField = (data) => {
    const customField = {
      subCategoryId: data.subcategory,
      type: data.type,
      name: data.name,
      isMultiple: multiSelect,
      isFilter: isFilter,
      dropdownList: data.dropdownList,
    };
    dispatch(createACustomField(customField, callback));
  };

  const handleChange = (event) => {
    setMultiSelect(event.target.checked);
  };

  const onToggleChange = () => {
    setIsFilter(!isFilter);
  };

  const [dropdownList, setDropdownList] = useState(false);

  const callback = () => {
    dispatch(getCustomFields(navigate("/custom-fields")));
  };

  const showDropdownList = () => {
    if (dropdownList) {
      return (
        <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
          <div className="w-full px-2">
            <h3 className="text-lg flex font-medium leading-6 text-gray-900">
              Dropdown Options
            </h3>
            <Controller
              control={control}
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <div
                    className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                  >
                    <Autocomplete
                      disablePortal
                      multiple
                      className="w-full"
                      id="dropdown-box"
                      onChange={(e, val, reason) => {
                        let arr = [];
                        val.map((value) => {
                          if (value) {
                            arr.push(value);
                          } else {
                            arr = [];
                          }
                        });
                        field.onChange(arr);
                      }}
                      options={[]}
                      getOptionLabel={(option) => option}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            key={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            "& > img": {
                              mr: 2,
                              flexShrink: 0,
                            },
                          }}
                          {...props}
                          key={option}
                        >
                          <p> {option} </p>
                        </Box>
                      )}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Type Dropdown List"
                          variant="standard"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                  </div>
                );
              }}
              name="dropdownList"
              rules={{
                required: {
                  value: true,
                  message: "Dropdown Options is required",
                },
              }}
            />

            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.dropdownList && errors.dropdownList.message}
            </p>
          </div>
          <div className={"w-full flex justify-start"}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleChange} />}
                label="Multiselect"
              />
            </FormGroup>
          </div>
        </div>
      );
    }
  };

  const updateSubCategories = (val) => (e) => {
    if (val) {
      setSubCategories(val.subcategories);
    }
  };

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        backLink={"/custom-fields"}
        Profile={<FaceIcon className="text-white " />}
        buttonSecondary={true}
        title={`Create Custom Field`}
        toggleChecked={isFilter}
        onToggleChange={onToggleChange}
        toggleLabel={"Is Filter?"}
      />
      <div className="py-2 align-middle inline-block min-w-full w-full">
        <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex font-medium leading-6 text-gray-900">
                Category
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <div
                      className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                    >
                      <Autocomplete
                        disablePortal
                        className="w-full"
                        id="combo-box-demo"
                        onChange={(e, val) => {
                          if (val) {
                            onChange(val.id);
                          } else {
                            onChange(undefined);
                          }
                          updateSubCategories(val)(e);
                        }}
                        options={categories}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              "& > img": {
                                mr: 2,
                                flexShrink: 0,
                              },
                            }}
                            {...props}
                          >
                            <div className="flex items-center justify-start flex-row">
                              <p> {option.name} </p>
                            </div>
                          </Box>
                        )}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select a category"
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </div>
                  );
                }}
                name="category"
                rules={{
                  required: {
                    value: false,
                    message: "Category is required",
                  },
                }}
              />

              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors?.category?.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex font-medium leading-6 text-gray-900">
                Subcategory
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <div
                      className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                    >
                      <Autocomplete
                        disablePortal
                        className="w-full"
                        id="combo-box-demo"
                        onChange={(e, val) => {
                          if (val) {
                            onChange(val._id);
                          } else {
                            onChange(undefined);
                          }
                        }}
                        options={subCategories || []}
                        getOptionLabel={(option) => option.name}
                        noOptionsText={
                          subCategories
                            ? "No options"
                            : "Select a category first"
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              "& > img": {
                                mr: 2,
                                flexShrink: 0,
                              },
                            }}
                            {...props}
                          >
                            <div className="flex items-center justify-start flex-row">
                              <p> {option.name} </p>
                            </div>
                          </Box>
                        )}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select a subcategory"
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </div>
                  );
                }}
                name="subcategory"
                rules={{
                  required: {
                    value: true,
                    message: "Subcategory is required",
                  },
                }}
              />

              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.subcategory && errors.subcategory.message}
              </p>
            </div>
          </div>
          <div className="w-full flex items-center mt-2 justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex font-medium leading-6 text-gray-900">
                Name
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="name-phone"
                  control={control}
                  fieldName="name"
                  placeholder={"Name of the Custom Field"}
                  type="text"
                  rules={{
                    required: {
                      value: true,
                      message: "Field is required",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.name && errors.name.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex font-medium leading-6 text-gray-900">
                Type
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <div
                      className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                    >
                      <Autocomplete
                        disablePortal
                        className="w-full"
                        onChange={(e, val) => {
                          if (val) {
                            if (val === "Dropdown List") {
                              setDropdownList(true);
                            } else {
                              setDropdownList(false);
                            }
                            onChange(val);
                          } else {
                            onChange(undefined);
                          }
                        }}
                        options={["Number", "String", "Dropdown List"]}
                        getOptionLabel={(option) => option}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              "& > img": {
                                mr: 2,
                                flexShrink: 0,
                              },
                            }}
                            {...props}
                          >
                            <div className="flex items-center justify-start flex-row">
                              <p> {option} </p>
                            </div>
                          </Box>
                        )}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select a type"
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </div>
                  );
                }}
                name="type"
                rules={{
                  required: {
                    value: true,
                    message: "Type is required",
                  },
                }}
              />

              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.type && errors.type.message}
              </p>
            </div>
          </div>
          {showDropdownList()}
          <div className="w-full p-2">
            <MainButton
              action={handleSubmit(createCustomField)}
              title={"Create"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCustomField;
