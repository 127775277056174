import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterListTwoToneIcon from "@mui/icons-material/FilterListTwoTone";
import { useDebounce } from "../../hooks/useDebounce";
import SidebarFiltersCustomFields from "../forms/SidebarFiltersCustomFields";

const SearchField = ({ action, showFilterButton, width, id }) => {
  const [showFilters, setShowFilters] = React.useState(false);
  const [filters, setFilters] = useState([]);
  const [searchValue, setSearchValue] = useState();

  useDebounce(
    () => searchValue !== undefined && action(searchValue, filters),
    [searchValue],
    300
  );

  const onSubmit = (data) => {
    setFilters(data);
    action(searchValue, data);
  };
  return (
    <div>
      <div className="flex flex-col justify-center">
        <div className="flex flex-row justify-between	 items-center space-between p-4">
          <div
            className={`border-2 rounded-full w-full ${
              width || "sm:w-2/6"
            } p-2 mb-4 flex`}
          >
            <SearchIcon />

            <input
              id={`${id}-text`}
              placeholder="Type here to search..."
              type={"text"}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className={"focus:outline-none w-full ml-2"}
            />
          </div>
          {showFilterButton && (
            <div
              onClick={() => setShowFilters(!showFilters)}
              className="border-2 rounded-full p-2 mb-4 flex cursor-pointer"
            >
              <FilterListTwoToneIcon />
              Filters
            </div>
          )}
        </div>

        {showFilters && (
          <div className="shadowBox flex flex-wrap  flex-row justify-center p-4 w-full">
            <div className="w-full items-center justify-center flex-col ">
              <SidebarFiltersCustomFields
                onSubmit={onSubmit}
                searchValue={searchValue}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchField;
