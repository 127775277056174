import React from "react";
import SidebarDashboard from "../../Components/ui/nav/SidebarDashboard";
import Footer from "../../Components/ui/nav/Footer";
import Version from "../../Components/ui/primitives/Version";
import Statistics from "../../Components/ui/pageElementes/Statistics";

const DashboardPage = () => {
  return (
    <div className="p-2">
      <SidebarDashboard />
      <div className="lg:ml-64">
        <h2 className="font-bold text-left text-black p-5">Widgets</h2>
        <div className="flex flex-col xl:flex-row w-full md:w-1/2 ">
          <Statistics />
        </div>
        <Footer />
      </div>
      <Version />
    </div>
  );
};

export default DashboardPage;
