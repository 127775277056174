import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import DashboardsNavbar from "./DashboardsNavbar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import GroupIcon from "@mui/icons-material/Group";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import CategoryIcon from "@mui/icons-material/Category";
import StorefrontIcon from "@mui/icons-material/Storefront";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { useDispatch, useSelector } from "react-redux";
import { signout } from "../../../redux/modules/auth/actions";
import clearAllStoreData from "../../../redux/resetAction";
import logo from "../../../assets/images/Logo.png";
import MainButton from "../buttons/MainButton";
import AddIcon from "@mui/icons-material/Add";
import { AccountTreeTwoTone, DashboardOutlined } from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { isAdminOrSupport } from "../../../helpers/isAdminOrSupport";

const SidebarDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state.auth);
  const role = state.user.role;

  const [showSidebar, setShowSidebar] = useState("-left-64");
  return (
    <>
      <DashboardsNavbar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        useMarketLogo={!["Partner", "Architect"].includes(role)}
      />
      <div
        style={{ zIndex: "100" }}
        className={`min-h-screen fixed top-0 bottom-0 lg:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-20  px-6 transition-all duration-300`}
      >
        <div className="flex-col flex items-stretch min-h-screen flex-nowrap px-0 ">
          <NavLink
            to="/marketplace"
            className=" text-center w-full inline-block"
          >
            <div className="logo-container">
              <img src={logo} />
            </div>
          </NavLink>

          <p className="text-fade my-4">
            {/* TODO: Replace by constants/enum */}
            {role === "Admin" && "Admin"}
            {role === "userA" && "Client"}
            {role === "Partner" && "Partner"}
            {role === "Support" && "Support"}
            {!role && "End User"}
          </p>
          <div className="flex flex-col flex-1 h-full">
            <ul className="flex-col min-w-full flex list-none flex-1 h-full relative py-4">
              {isAdminOrSupport(role) && (
                <li className="rounded-lg mb-2">
                  <NavLink
                    to={"/dashboard"}
                    className={(navData) =>
                      navData.isActive
                        ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                        : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                    }
                  >
                    <DashboardOutlined />
                    <span className="ml-2">Dashboard</span>
                  </NavLink>
                </li>
              )}
              {role && !(role === "Partner") && (
                <li className="rounded-lg mb-2">
                  <NavLink
                    to={role === "userA" ? "/my-models" : "/models"}
                    className={(navData) =>
                      navData.isActive
                        ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                        : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                    }
                  >
                    <HomeOutlinedIcon />
                    <span className="ml-2">Models</span>
                  </NavLink>
                </li>
              )}
              {!role && (
                <>
                  <li className="rounded-lg mb-2">
                    <NavLink
                      to={"/my-favorites"}
                      className={(navData) =>
                        navData.isActive
                          ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                          : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                      }
                    >
                      <FavoriteIcon />
                      <span className="ml-2">Favorite Models</span>
                    </NavLink>
                  </li>
                  <li className="rounded-lg mb-2">
                    <NavLink
                      to={"/my-assigned-models"}
                      className={(navData) =>
                        navData.isActive
                          ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                          : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                      }
                    >
                      <HomeOutlinedIcon />
                      <span className="ml-2">Assigned Models</span>
                    </NavLink>
                  </li>
                </>
              )}

              {role === "Admin" && (
                <li className="rounded-lg mb-2">
                  <NavLink
                    to="/support"
                    className={(navData) =>
                      navData.isActive
                        ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                        : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                    }
                  >
                    <SupportAgentIcon />
                    <span className="ml-2">Support Users</span>
                  </NavLink>
                </li>
              )}

              {isAdminOrSupport(role) && (
                <li className="rounded-lg mb-2">
                  <NavLink
                    to="/partners"
                    className={(navData) =>
                      navData.isActive
                        ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                        : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                    }
                  >
                    <SupervisedUserCircleIcon />
                    <span className="ml-2">Partners</span>
                  </NavLink>
                </li>
              )}

              {isAdminOrSupport(role) && (
                <li className="rounded-lg mb-2">
                  <NavLink
                    to="/clients"
                    className={(navData) =>
                      navData.isActive
                        ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                        : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                    }
                  >
                    <GroupIcon />
                    <span className="ml-2">Clients</span>
                  </NavLink>
                </li>
              )}

              {role === "Partner" && (
                <li className="rounded-lg mb-2">
                  <NavLink
                    to="/users"
                    className={(navData) =>
                      navData.isActive
                        ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                        : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                    }
                  >
                    <GroupIcon />
                    <span className="ml-2">My Clients</span>
                  </NavLink>
                </li>
              )}
              {isAdminOrSupport(role) && (
                <li className="rounded-lg mb-2">
                  <NavLink
                    to="/end-users"
                    className={(navData) =>
                      navData.isActive
                        ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                        : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                    }
                  >
                    <GroupIcon />
                    <span className="ml-2">End Users</span>
                  </NavLink>
                </li>
              )}
              {isAdminOrSupport(role) && (
                <li className="rounded-lg mb-2">
                  <NavLink
                    to="/categories"
                    className={(navData) =>
                      navData.isActive
                        ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                        : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                    }
                  >
                    <CategoryIcon />
                    <span className="ml-2">Categories</span>
                  </NavLink>
                </li>
              )}
              {["Admin"].includes(role) && (
                <>
                  <li className="rounded-lg mb-2">
                    <NavLink
                      to="/subscription-plans"
                      className={(navData) =>
                        navData.isActive
                          ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                          : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                      }
                    >
                      <AccountTreeTwoTone />
                      <span className="ml-2">Subscription Plans</span>
                    </NavLink>
                  </li>
                  <li className="rounded-lg mb-2">
                    <NavLink
                      to="/partner-tiers"
                      className={(navData) =>
                        navData.isActive
                          ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                          : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                      }
                    >
                      <EqualizerIcon />
                      <span className="ml-2">Partner Tiers</span>
                    </NavLink>
                  </li>
                </>
              )}
              {isAdminOrSupport(role) && (
                <li className="rounded-lg mb-2">
                  <NavLink
                    to="/custom-fields"
                    className={(navData) =>
                      navData.isActive
                        ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                        : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                    }
                  >
                    <FilterAltIcon />
                    <span className="ml-2">Custom Fields</span>
                  </NavLink>
                </li>
              )}

              {role === "userA" && (
                <li className="rounded-lg mb-2">
                  <NavLink
                    to="/my-clients"
                    className={(navData) =>
                      navData.isActive
                        ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                        : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                    }
                  >
                    <GroupIcon />
                    <span className="ml-2">My Clients</span>
                  </NavLink>
                </li>
              )}

              {role === "userA" && (
                <li className="rounded-lg mb-2">
                  <NavLink
                    to="/modified-models"
                    className={(navData) =>
                      navData.isActive
                        ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                        : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                    }
                  >
                    <ChangeCircleIcon />
                    <span className="ml-2">Modified Models</span>
                  </NavLink>
                </li>
              )}

              <li className="rounded-lg mb-2 text-gray-700">
                <NavLink
                  to="/my-account"
                  className={(navData) =>
                    navData.isActive
                      ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                      : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                  }
                >
                  <ManageAccountsIcon />
                  <span className="ml-2">My account</span>
                </NavLink>
              </li>
              <li className="rounded-lg mb-2 text-gray-700">
                <NavLink
                  to="/marketplace"
                  className={(navData) =>
                    navData.isActive
                      ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                      : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                  }
                >
                  <StorefrontIcon />
                  <span className="ml-2">Marketplace</span>
                </NavLink>
              </li>

              {role === "userA" && (
                <li className="rounded-lg mb-2 ">
                  <MainButton
                    Icon={<AddIcon className="text-xs" />}
                    link="/models/new"
                    title="Upload new model"
                    padding="px-4 py-3"
                  />
                </li>
              )}

              <li className="rounded-lg mb-2 text-gray-700 mt-auto">
                <button
                  className="flex items-center text-sm text-red-500 font-light px-4 py-2 rounded-lg"
                  onClick={() => {
                    dispatch(signout());
                    dispatch(clearAllStoreData());
                    navigate("/login");
                  }}
                >
                  <LogoutIcon />
                  <span className="ml-2">Logout</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarDashboard;
