import React from "react";
import { useDropzone } from "react-dropzone";
import ImageIcon from "@mui/icons-material/Image";
import MainButton from "../buttons/MainButton";
import { uploadFinalModelRequest } from "../../../api/modules/models";
import { useHttp } from "../../../hooks/useHttp";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isAdminOrSupport } from "../../../helpers/isAdminOrSupport";

const ModelFormFileUpload = ({
  singleModel,
  onUpload,
  onFinalModelUploadCallback,
}) => {
  const { id } = useParams();
  const [filesModel, setFilesModel] = React.useState([]);
  const { http, backendErrors, isLoading } = useHttp();
  const state = useSelector((state) => state.auth);
  const currentUser = state.user;

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".obj,.fbx,.OBJ,.FBX,.glb,.GLB,.gltf,.GLTF",
    onDrop: (acceptedFiles) => {
      const uploadedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFilesModel(uploadedFiles);
      onUpload(uploadedFiles);
    },
  });

  const onUploadFinalModel = () => {
    const formData = new FormData();
    formData.set("arFile", filesModel[0]);
    http(() => uploadFinalModelRequest(id, formData)).then(() =>
      onFinalModelUploadCallback()
    );
  };

  return (
    <div className="w-full">
      <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
        Model 3D<span className="text-xs text-red-500">*</span>
      </h3>
      <span className="text-xs text-blue-400">Mandatory field</span>
      <div className="cursor-pointer w-full flex items-center justify-center flex-col sm:flex-row ">
        <div
          className="bg-white dashed-border flex flex-col items-center justify-center rounded-xl w-full my-2 p-12"
          style={{ padding: "15px" }}
          {...getRootProps()}
        >
          <div className="uploadModelForm-image">
            <ImageIcon fontSize="large" color="disabled" />
          </div>
          <p className="text-center px-2 py-1">
            Drag & Drop files here or Browse Files
          </p>

          <input {...getInputProps()} id="model-3d-object" />

          {filesModel.length ? (
            filesModel.map((file, i) => {
              return (
                <div className="text-primary font-bold" key={file.path}>
                  {file.path}
                </div>
              );
            })
          ) : (
            <div className="text-primary font-bold" style={{ color: "black" }}>
              {singleModel?.arFile?.substring(
                singleModel?.arFile?.lastIndexOf("/") + 1
              )}
            </div>
          )}

          <div className="uploadModelForm-subTitle">
            {" "}
            Max size: 2GB | File type: OBJ, FBX, GLB, GLTF{" "}
          </div>

          {!singleModel?.arFile && !filesModel?.length && (
            <p className="text-xs text-red-500 mt-2">
              A model file is required
            </p>
          )}
        </div>
      </div>
      {id && isAdminOrSupport(currentUser.role) && (
        <div className="w-full flex items-center	 justify-center flex-col">
          {!filesModel.length ? (
            <p style={{ fontSize: "12px" }}>
              Upload a new file before pressing the button
            </p>
          ) : null}

          <MainButton
            title="Upload final model"
            type="button"
            width="w-full sm:w-3/6"
            isLoading={isLoading}
            disabled={isLoading || !filesModel?.length}
            action={() => onUploadFinalModel()}
          />
        </div>
      )}
      <br />
      {backendErrors?.map((e) => (
        <li className="text-xs text-red-500 " key={e.code}>
          {e.message}
          <br />
        </li>
      ))}
      <br />
    </div>
  );
};

export default ModelFormFileUpload;
