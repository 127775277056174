import React from "react";
import ImageIcon from "@mui/icons-material/Image";
import { useDropzone } from "react-dropzone";

const ModelFormCoverImageUpload = ({ singleModel, onUpload }) => {
  const [filesCoverImageZon, setFilesCoverImageZone] = React.useState([]);

  const coverImageZone = useDropzone({
    accept: "image/*",
    multiple: true,
    maxFiles: 4,
    onDrop: (acceptedFiles) => {
      const uploadedImages = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
      setFilesCoverImageZone(uploadedImages);
      onUpload(uploadedImages);
    },
  });

  return (
    <div className="w-full">
      <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
        Model Cover
        <span className="text-xs text-red-500">*</span>
      </h3>
      <span className="text-xs text-blue-400">Mandatory field</span>
      <div className="cursor-pointer w-full flex items-center justify-center flex-col sm:flex-row ">
        <div
          className="bg-white dashed-border flex flex-col items-center jusrify-center rounded-xl w-full my-2"
          style={{ padding: "15px" }}
          {...coverImageZone.getRootProps()}
        >
          <p className="text-center px-2 py-1">
            Drag & Drop files here or Browse Files
          </p>
          <div className="uploadModelForm-subTitle">
            {" "}
            Max size: 5MB | File type: JPG, JPEG, PNG{" "}
          </div>
          <input {...coverImageZone.getInputProps()} id="model-coverImage" />

          <br />
          <div className="flex flex-col sm:flex-row justify-center  items-center">
            {filesCoverImageZon && filesCoverImageZon.length > 0 ? (
              filesCoverImageZon.map((image, i) => {
                return (
                  <div className="w-full" key={i}>
                    <img
                      style={{
                        maxWidth: "500px",
                        maxHeight: "500px",
                      }}
                      src={image.preview}
                      alt="..."
                      className="w-full h-full rounded-xl object-cover"
                    />
                  </div>
                );
              })
            ) : singleModel?.coverImages &&
              singleModel.coverImages.length > 0 ? (
              singleModel.coverImages.map((image, i) => {
                return (
                  <div className="w-full" key={i}>
                    <img
                      src={`${process.env.REACT_APP_API}${image.usePath}/${image.filename}`}
                      alt="..."
                      className="w-full h-full rounded-xl object-cover"
                    />
                  </div>
                );
              })
            ) : singleModel?.coverImagePath ? (
              <>
                <div className="w-full h-72 p-12 max-w-2xl">
                  <img
                    src={`${process.env.REACT_APP_API}/models3D/content/image/${singleModel._id}/${singleModel.updatedAt}`}
                    alt={singleModel.name}
                    className="w-full h-full object-cover rounded-xl"
                  />
                </div>
              </>
            ) : (
              <div className="w-full h-full  flex flex-wrap justify-center py-1">
                <div className="w-28 h-28 rounded-full overflow-hidden  bg-primary flex justify-center items-center ">
                  <ImageIcon fontSize="large" className="text-white" />
                </div>
              </div>
            )}
          </div>

          {filesCoverImageZon.map((file, i) => {
            return (
              <div className="text-primary font-bold" key={file.path}>
                {file.path}
              </div>
            );
          })}

          {!singleModel?.coverImagePath && !filesCoverImageZon?.length && (
            <p
              className="text-xs text-red-500 mt-2"
              style={{ minHeight: "1rem" }}
            >
              A cover image is required
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModelFormCoverImageUpload;
