import React, { useState } from "react";
import {
  errorClear,
  assignModelToUsers,
  assignModelToEndUsers,
} from "../../../redux/modules/models/actions";
import { useDispatch } from "react-redux";
import "../../../index.css";
import SearchField from "../pageElementes/SearchField";
import Pagination from "./Pagination";

import ListElement from "./ListElement";
import AssignUsersToModelModal from "../modals/AssignUsersToModelModal";
import { getAdminModelsListRequest } from "../../../api/modules/models";
import { useHttp } from "../../../hooks/useHttp";

const ModelsListAdmin = () => {
  const [page, setPage] = useState(1);
  const [models, setModels] = useState([]);
  const dispatch = useDispatch();
  const { http } = useHttp({ withToastMessage: false });

  const [showModal, setShowModal] = useState(false);
  const [selectedModel, setSelectedModel] = useState();

  const [filters, setFilters] = useState({});
  const [searchValue, setSearchValue] = useState({});

  React.useEffect(() => {
    dispatch(errorClear());
    fetchModels({});
  }, []);

  const fetchModels = async ({ searchInput, filters, page }) => {
    const models = await http(() =>
      getAdminModelsListRequest(
        {
          sortBy: "createdAt",
          sortValue: "desc",
          page: page || 0,
          ...(searchInput ? { search: searchInput } : {}),
        },
        filters
      )
    );
    setModels(models);
  };

  const search = (searchInput, filters) => {
    setPage(1);
    setFilters(filters);
    setSearchValue(searchInput);
    fetchModels({ searchInput, filters });
  };

  const handlePage = async (page) => {
    setPage(page);
    fetchModels({ searchInput: searchValue, page: page - 1, filters });
  };

  const onModalSubmit = ({
    modelId,
    checkedEndUsersIds,
    checkedClientsIds,
  }) => {
    dispatch(
      assignModelToEndUsers({
        modelId: modelId,
        endUserIds: checkedEndUsersIds,
      })
    );
    dispatch(
      assignModelToUsers({ modelId: modelId, userIds: checkedClientsIds })
    );

    setShowModal(false);
  };

  return (
    <>
      <SearchField action={search} showFilterButton={true} />

      <div className="w-full flex flex-wrap justify-center lg:justify-start items-center">
        {(models?.models || []).map((item, i) => {
          return (
            <ListElement
              key={i}
              model={item}
              index={i}
              showModal={showModal}
              setShowModal={setShowModal}
              setSelectedModel={setSelectedModel}
            />
          );
        })}
      </div>
      <Pagination
        pageSize={models?.pageSize}
        totalPages={models?.totalPages}
        total={models?.total}
        page={page}
        setPage={handlePage}
      />

      {showModal && (
        <AssignUsersToModelModal
          model={selectedModel}
          onSubmit={onModalSubmit}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};
export default ModelsListAdmin;
