import { useEffect, useState, useCallback } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "../pageElementes/Modal";
import { useHttp } from "../../../hooks/useHttp";
import {
  assignModelsToUserRequest,
  getModelsRequest,
} from "../../../api/modules/models";
import ListLongCard from "../cards/ListLongCard";

const AssignModelsToUserModal = ({
  user,
  onSubmit,
  isClient,
  visibility,
  setVisibility,
}) => {
  const { http } = useHttp();
  const [models, setModels] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredModels, setFilteredModels] = useState([]);

  const [checkedModels, setCheckedModels] = useState([]);

  const sortUsersByCheckedStatus = useCallback(
    (model) => {
      const checkedTemp = (model || []).filter((item) =>
        user?.models3D.includes(item.id)
      );

      return model?.sort((a, b) => {
        const isCheckedA = !!checkedTemp.find((item) => item.id === a?.id);
        const isCheckedB = !!checkedTemp.find((item) => item.id === b?.id);
        return isCheckedA === isCheckedB ? 0 : isCheckedA ? -1 : 1;
      });
    },
    [user]
  );

  useEffect(() => {
    http(() => getModelsRequest()).then((res) => {
      const sortedModels = sortUsersByCheckedStatus(res);
      setModels(sortedModels);
      setFilteredModels(sortedModels);
    });
  }, [sortUsersByCheckedStatus]);

  useEffect(() => {
    if (!models || !user || Object.keys(user).length === 0) return;

    const initialCheckedModels = models
      .filter((model) => user.models3D?.includes(model.id))
      .map((model) => model.id);

    setCheckedModels(initialCheckedModels);
  }, [user, models]);

  useEffect(() => {
    const _filteredModels = models.filter((model) =>
      model.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredModels(_filteredModels);
  }, [searchValue]);

  const handleClickAssignments = (model) => {
    const modelId = model.id;
    const index = checkedModels.indexOf(modelId);
    if (index > -1) {
      setCheckedModels((prev) => prev.filter((e) => e !== modelId));
    } else {
      setCheckedModels([...checkedModels, modelId]);
    }
  };

  const handleSubmit = async () => {
    await assignModelsToUserRequest({
      modelIds: checkedModels,
      ...(isClient ? { clientUserId: user.id } : { endUserId: user.id }),
    });

    onSubmit();
  };

  return (
    <Modal
      title={`Assign models`}
      visibility={visibility}
      setVisibility={setVisibility}
      action={() => handleSubmit()}
    >
      <div
        className={`border-2 rounded-full w-full p-2 mb-4 flex`}
        style={{ minWidth: "400px" }}
      >
        <SearchIcon />
        <input
          placeholder="Type here to search..."
          type={"text"}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          className={"focus:outline-none w-full ml-2"}
        />
      </div>

      {filteredModels?.map((model, i) => {
        const checked = checkedModels.includes(model.id);
        return (
          <ListLongCard
            id={model.id}
            name={model.name}
            handleClick={() => handleClickAssignments(model)}
            key={i}
            image={`${process.env.REACT_APP_API}/models3D/content/image/${model.id}/${model.updatedAt}`}
            checked={checked}
          />
        );
      })}
    </Modal>
  );
};
export default AssignModelsToUserModal;
