import React from "react";

import DashboardsNavbar from "../../ui/nav/DashboardsNavbar";
import Footer from "../../ui/nav/Footer";

const LandingPageLayout = ({ children, isLandingPage = true, normalLogo }) => {
  return (
    <div className="w-full overflow-x-hidden">
      <DashboardsNavbar
        showSidebar={false}
        isMarketPlace={true}
        isLandingPage={isLandingPage}
        normalLogo={normalLogo}
      />
      {children}
      <Footer />
    </div>
  );
};

export default LandingPageLayout;
