import React, { useEffect, useState } from "react";
import Modal from "../../../Components/ui/pageElementes/Modal";
import { useHttp } from "../../../hooks/useHttp";
import { getSubscriptionPlansRequest } from "../../../api/modules/subscriptionPlans";
import LoadingIcon from "../../../Components/ui/icons/LoadingIcon";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "../StripeCheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import ConfirmModal from "../../ui/modals/ConfirmModal";
import moment from "moment";

const SUBSCRIPTION_INTERVAL = {
  monthly: {
    key: "monthly",
    label: "Monthly",
  },
  yearly: {
    key: "yearly",
    label: "Yearly",
  },
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionPlansModal = ({
  onClose,
  title,
  description,
  onSubscriptionCreate,
  onSubscriptionCancel,
  onSubscriptionUpdate,
  isLoading,
  selectedClient,
  returnPath,
  hideTrialOptions,
}) => {
  const { http } = useHttp();

  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [interval] = useState(SUBSCRIPTION_INTERVAL.yearly.key);
  const [showPaymentForm, setShowPaymentForm] = useState();
  const [latestInvoice, setLatestInvoice] = useState();
  const [showPaymentOrderModal, setShowPaymentOrderModal] = useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState();
  const state = useSelector((state) => state.auth);
  const currentUserRole = state.user.role;

  const isSubscriptionActive = ["active", "trialing"].includes(
    selectedClient?.stripeSubscription?.status
  );

  const fetchSubscriptionPlans = () => {
    http(() => getSubscriptionPlansRequest()).then((res) => {
      setSubscriptionPlans(res);
    });
  };

  const handlePaymentCallback = (latestInvoice) => {
    setLatestInvoice(latestInvoice);
    setShowPaymentForm(true);
  };

  useEffect(() => {
    fetchSubscriptionPlans();
  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: latestInvoice?.payment_intent?.client_secret,
  };

  return (
    <Modal visibility={true} setVisibility={() => onClose()} width={1000}>
      <div className="w-full flex flex-col justify-start items-center flex-1 w-full">
        {showCancelSubscriptionModal && (
          <ConfirmModal
            buttonText="Cancel Subscription"
            visibility={showCancelSubscriptionModal}
            setVisibility={setShowCancelSubscriptionModal}
            submitAction={() => onSubscriptionCancel()}
            title="Are you sure you want to cancel this subscription?"
            description={`This action is irreversible.`}
          />
        )}

        {showPaymentOrderModal ? (
          <section className="bg-white dark:bg-gray-900">
            <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
              <div className="mx-auto max-w-screen-md text-center">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  Bank Transfer Details
                </h2>
                <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
                  The subscription will become active immediately. <br />
                  If the payment will not be received in{" "}
                  <b>{currentUserRole === "Partner" ? 15 : 5}</b> days, the
                  subscription will be changed to inactive.
                </p>
              </div>

              <br />

              <div className="space-y-8 sm:gap-6 xl:gap-10 lg:space-y-0">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Bank
                      </th>
                      <th scope="col" className="py-3 px-6">
                        IBAN
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Company
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <td scope="row" className="py-4 px-6">
                        <p>
                          Bank name: <b>UNICREDIT BANK SA</b>
                        </p>
                        <p>
                          SWIFT code: <b>BACXROBUXXX</b>
                        </p>
                        <p>
                          Address: <b>EXPOZITIEI BLVD 1F, SECTOR 1</b>
                        </p>
                        <p>
                          City: <b>BUCHAREST</b>
                        </p>
                        <p>
                          Country: <b>Romania</b>
                        </p>
                      </td>
                      <td className="py-4 px-6">
                        <p>RO90BACX0000001864497001 - EUR</p>
                        <p>RO63BACX0000001864497002 - USD</p>
                      </td>
                      <td className="py-4 px-6">
                        <p>
                          Company Name: <b>XRETEH SRL</b>
                        </p>
                        <p>
                          Fiscal Code: <b>RO41234106</b>
                        </p>
                        <p>
                          Registry No: <b>J24/1228/2019</b>
                        </p>
                        <p>
                          County: <b>Maramures</b>
                        </p>
                        <p>
                          City/District: <b>Loc. Baia Mare</b>
                        </p>
                        <p>
                          Address: <b>Str. Gheorghe Sincai 33 C</b>
                        </p>
                      </td>
                      <td className="py-4 px-6">
                        {selectedSubscriptionPlan
                          ? subscriptionPlans?.find(
                              (item) => item.id === selectedSubscriptionPlan?.id
                            ).yearly.price + ` $`
                          : `N/A`}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <br />
                <a
                  href="#"
                  onClick={async () => {
                    if (isSubscriptionActive) {
                      await onSubscriptionUpdate(
                        { ...selectedSubscriptionPlan, isBankTransfer: true },
                        interval
                      );
                    } else {
                      await onSubscriptionCreate(
                        { ...selectedSubscriptionPlan, isBankTransfer: true },
                        interval
                      );
                    }
                    if (!isLoading) {
                      onClose();
                    }
                  }}
                  style={{
                    background: "#139ce0",
                    color: "white",
                  }}
                  className="mt-3 flex justify-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900"
                >
                  {isLoading && <LoadingIcon />}
                  Continue
                </a>
              </div>
            </div>
          </section>
        ) : showPaymentForm ? (
          <section className="bg-white dark:bg-gray-900">
            <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
              <div className="mx-auto max-w-screen-md text-center">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  Make the payment (
                  {parseFloat(latestInvoice?.total / 100).toFixed(2)}{" "}
                  {latestInvoice?.currency})
                </h2>
              </div>

              <div className="space-y-8 sm:gap-6 xl:gap-10 lg:space-y-0">
                <Elements stripe={stripePromise} options={options}>
                  <StripeCheckoutForm returnPath={returnPath} />
                </Elements>
              </div>
            </div>
          </section>
        ) : (
          <section className="bg-white dark:bg-gray-900">
            <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
              <div className="mx-auto max-w-screen-md text-center">
                <h2 className="mb-4 text-4xl tracking-tight text-gray-900 dark:text-white">
                  Choose a subscription for your <b>XRETEH</b> account
                </h2>
              </div>

              {/* TODO: Client requested that monthly prices should be hidden for now. */}
              {/* <div className="text-center mb-10"> */}
              {/*  <div className="flex justify-center"> */}
              {/*    <div className="form-check form-switch flex items-center justify-center"> */}
              {/*      <span */}
              {/*        style={{ fontSize: "25px" }} */}
              {/*        className="form-check-label inline-block text-gray-600 " */}
              {/*        htmlFor="flexSwitchCheckDefault" */}
              {/*      > */}
              {/*        {interval === SUBSCRIPTION_INTERVAL.monthly.key ? ( */}
              {/*          <b>{SUBSCRIPTION_INTERVAL.monthly.label}</b> */}
              {/*        ) : ( */}
              {/*          SUBSCRIPTION_INTERVAL.monthly.label */}
              {/*        )}{" "} */}
              {/*        &nbsp; */}
              {/*      </span> */}
              {/*      <input */}
              {/*        className="form-check-input appearance-none w-12 h-6 rounded-full float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" */}
              {/*        type="checkbox" */}
              {/*        role="switch" */}
              {/*        onChange={() => */}
              {/*          setInterval( */}
              {/*            interval === SUBSCRIPTION_INTERVAL.yearly.key */}
              {/*              ? SUBSCRIPTION_INTERVAL.monthly.key */}
              {/*              : SUBSCRIPTION_INTERVAL.yearly.key */}
              {/*          ) */}
              {/*        } */}
              {/*        checked={interval === SUBSCRIPTION_INTERVAL.yearly.key} */}
              {/*        id="flexSwitchCheckDefault" */}
              {/*      /> */}
              {/*      <span */}
              {/*        style={{ fontSize: "25px" }} */}
              {/*        className="form-check-label inline-block text-gray-600 " */}
              {/*        htmlFor="flexSwitchCheckDefault" */}
              {/*      > */}
              {/*        &nbsp;{" "} */}
              {/*        {interval === SUBSCRIPTION_INTERVAL.yearly.key ? ( */}
              {/*          <b>{SUBSCRIPTION_INTERVAL.yearly.label}</b> */}
              {/*        ) : ( */}
              {/*          SUBSCRIPTION_INTERVAL.yearly.label */}
              {/*        )} */}
              {/*      </span> */}
              {/*    </div> */}
              {/*  </div> */}
              {/* </div> */}

              <div className="space-y-8 mb-10 lg:grid lg:grid-cols-4 sm:gap-6 xl:gap-10 lg:space-y-0">
                {(subscriptionPlans || [])
                  .filter((subscriptionPlan) => {
                    const isNotTheCurrentSubscriptionPlan =
                      selectedClient?.subscription?.subscriptionPlan?._id !==
                      subscriptionPlan.id;
                    const clientHasAlreadyStripeCustomerId =
                      selectedClient?.subscription?.stripeCustomerId;

                    return isNotTheCurrentSubscriptionPlan &&
                      clientHasAlreadyStripeCustomerId
                      ? !subscriptionPlan.isTrial
                      : true;
                  })
                  .map((subscriptionPlan) => {
                    const clientSubscriptionPlan =
                      selectedClient?.subscription?.subscriptionPlan;

                    const clientSubscriptionPlanId =
                      clientSubscriptionPlan?.id || clientSubscriptionPlan?._id;

                    const isCurrent =
                      isSubscriptionActive &&
                      subscriptionPlan?.id === clientSubscriptionPlanId;

                    return (
                      <div
                        key={subscriptionPlan.id}
                        style={
                          isCurrent
                            ? { border: "3px solid rgb(19, 156, 224)" }
                            : {}
                        }
                        className="justify-between flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
                      >
                        <h3 className="mb-4 text-2xl font-semibold">
                          {subscriptionPlan.name}
                        </h3>
                        <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                          {subscriptionPlan.description}
                        </p>
                        <div
                          className={`flex justify-center items-baseline my-8 ${
                            subscriptionPlan.isTrial && "invisible"
                          }`}
                        >
                          <span className="mr-2 text-3xl font-extrabold">
                            ${subscriptionPlan[interval].price}
                            {interval === SUBSCRIPTION_INTERVAL.yearly.key && (
                              <p className="mt-4 text-xs text-hex-32454E leading-normal  text-left">
                                {subscriptionPlan.monthly.price * 12 >
                                  subscriptionPlan.yearly.price && (
                                  <>
                                    You save 15% ($
                                    {(
                                      subscriptionPlan.monthly.price * 12 -
                                      subscriptionPlan.yearly.price
                                    ).toFixed(2)}
                                    )
                                  </>
                                )}
                              </p>
                            )}
                          </span>
                          <span className="text-gray-500 dark:text-gray-400">
                            /{SUBSCRIPTION_INTERVAL[interval].label}
                          </span>
                          <br />
                        </div>
                        <ul role="list" className="mb-8 space-y-4 text-left">
                          <li className="flex items-center space-x-3">
                            <svg
                              className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span>
                              Disk Quota: <b>{subscriptionPlan.diskQuota} MB</b>
                            </span>
                          </li>
                        </ul>

                        {isCurrent && (
                          <button
                            type="button"
                            className="px-8 py-3 rounded-lg cursor-default text-white bg-gray-300 rounded focus:outline-none"
                            disabled
                            // TODO: uncomment when want to test cancel
                            // onClick={() => setShowCancelSubscriptionModal(true)}
                          >
                            Ends at <br />
                            <b>
                              {moment(
                                selectedClient.stripeSubscription
                                  ?.current_period_end * 1000
                              ).format("ll")}
                            </b>
                          </button>
                        )}

                        {!isCurrent && (
                          <div
                            style={{
                              height: "92px",
                              justifyContent: "center",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <a
                              href="#"
                              onClick={() => {
                                setSelectedSubscriptionPlan(subscriptionPlan);
                                if (isCurrent) {
                                  setShowCancelSubscriptionModal(true);
                                } else if (isSubscriptionActive) {
                                  onSubscriptionUpdate(
                                    subscriptionPlan,
                                    interval,
                                    handlePaymentCallback
                                  );
                                } else {
                                  onSubscriptionCreate(
                                    subscriptionPlan,
                                    interval,
                                    handlePaymentCallback
                                  );
                                }
                              }}
                              style={{
                                background: !isCurrent ? "#139ce0" : "red",
                                color: "white",
                                width: "100%",
                              }}
                              className="flex justify-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900"
                            >
                              {selectedSubscriptionPlan?.id ===
                                subscriptionPlan.id &&
                                isLoading && <LoadingIcon />}

                              {subscriptionPlan.isTrial
                                ? "Get Started"
                                : "Pay Online"}
                            </a>

                            {!subscriptionPlan.isTrial && (
                              <a
                                href="#"
                                onClick={() => {
                                  setSelectedSubscriptionPlan(subscriptionPlan);
                                  setShowPaymentOrderModal(true);
                                }}
                                style={{
                                  background: "#139ce0",
                                  color: "white",
                                  width: "100%",
                                }}
                                className="mt-3 flex justify-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900"
                              >
                                Bank Transfer
                              </a>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>
        )}
      </div>
    </Modal>
  );
};
export default SubscriptionPlansModal;
