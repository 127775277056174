import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

const persistConfig = {
  key: "_uax",
  storage,
};
const bindMiddleware = (middleware) => {
  if (!process.env.PRODUCTION) {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const configureStore = () => {
  const enhancer = compose(bindMiddleware([thunk]));
  const store = createStore(persistedReducer, enhancer);
  const persistor = persistStore(store);
  return { store, persistor };
};
// export default function configureStore(initialState) {
//

//     return createStore(
//         rootReducer,
//         initialState,
//         enhancer
//     );
// }
export default configureStore;
