import React from "react";

const CopyRight = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="w-full flex justify-center ">
      <p className="mb-4 text-xs">
        Copyright {currentYear} by XRETEH SRL. All Rights Reserved.
      </p>
    </div>
  );
};

export default CopyRight;
