import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { countries } from "../../../assets/countries";

const CountriesAutocomplete = ({
  onChange,
  defaultValue,
  disabled,
  extraClasses,
}) => {
  return (
    <Autocomplete
      name={"country"}
      options={countries}
      value={countries.find((country) => country.code === defaultValue) || null}
      disabled={disabled}
      onChange={(e, value) => onChange(value)}
      className={`flex flex-1 appearance-none w-1/2 py-3 ml-5 leading-tight focus:outline-none ${extraClasses}`}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box {...props} key={props.id}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
            alt=""
          />
          &nbsp; {option.label}
        </Box>
      )}
      renderInput={(params) => {
        return (
          <TextField placeholder="Country" {...params} variant="standard" />
        );
      }}
    />
  );
};
export default CountriesAutocomplete;
