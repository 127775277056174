import { request } from "../api.service";

export const getCategoriesRequest = () => {
  return request("admin/categories", "get");
};

export const getCategoryRequest = (id) => {
  return request(`admin/categories/${id}`, "get");
};

export const createCategoryRequest = (data) => {
  return request(`admin/categories`, "post", data);
};

export const updateCategoryRequest = (id, data) => {
  return request(`admin/categories/${id}`, "put", data);
};

export const deleteCategoryRequest = (id) => {
  return request(`admin/categories/${id}`, "delete");
};
