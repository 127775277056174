import React from "react";

import ModelCard from "../cards/ModelCard";

const ListElement = (props) => {
  if (props.market) {
    return (
      <ModelCard
        showAction={props.showAction}
        isFavorite={props.isFavorite}
        model={props.model}
        link={`/marketplace/${props.model.id}`}
        simpleAction={() =>
          props.showAction
            ? props.addToFavorite(props.isFavorite, props.model.id)
            : props.action(props.model.id)
        }
        aUser={props.user?.role}
      />
    );
  }
  return (
    <div key={props.index}>
      <ModelCard
        model={props.model}
        link={
          props.extraLink
            ? `${props.extraLink}/${props.model.id}`
            : `/models/${props.model.id}`
        }
        modifiable={true}
        modalAction={() => {
          props.setSelectedModel(props.model);

          if (props.setShowModal) {
            props.setShowModal(true);
          }
        }}
      />
    </div>
  );
};

export default ListElement;
