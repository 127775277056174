import React from "react";
import MainButton from "../buttons/MainButton";

const Empty = ({ buttonTitle, link, info }) => {
  return (
    <div className="flex justify-center items-center p-10 flex-col w-full">
      <div className="mb-4">No data</div>
      <p className="mb-4 text-primary font-bold">{info}</p>
      {link && <MainButton title={buttonTitle} link={link} width="w-full" />}
    </div>
  );
};

export default Empty;
