import {
  AUTH_SIGN_IN,
  AUTH_LOADING,
  AUTH_ERROR,
  AUTH_SIGN_OUT,
  AUTH_SIGN_UP,
  USER_DATA,
  USER_DATA_LOADING,
  USER_DATA_ERROR,
  AUTH_FORGOT_PASSWORD,
  AUTH_RESET_PASSWORD,
  AUTH_PROFILE_DETAILS,
  AUTH_ERROR_CLEAR,
  AUTH_CHANGE_PASSWORD,
  END_USER_SIGN_OUT,
} from "./constants";
import axios from "axios";
import Cookies from "js-cookie";
import clearAllStoreData from "../../resetAction";
export function authError(data) {
  return {
    type: AUTH_ERROR,
    payload: data,
  };
}

export function authLoading() {
  return {
    type: AUTH_LOADING,
  };
}
export function userLoading() {
  return {
    type: USER_DATA_LOADING,
  };
}
export function userError() {
  return {
    type: USER_DATA_ERROR,
  };
}

export function signinSuccess(data) {
  return {
    type: AUTH_SIGN_IN,
    payload: data,
  };
}
export function signupSuccess(data) {
  return {
    type: AUTH_SIGN_UP,
    payload: data,
  };
}
export function signoutSuccess() {
  return {
    type: AUTH_SIGN_OUT,
  };
}
export function signoutEndUserSuccess() {
  return {
    type: END_USER_SIGN_OUT,
  };
}
export function updateUserDataSuccess(data) {
  return {
    type: USER_DATA,
    payload: data,
  };
}
export function forgotPasswordSuccess(data) {
  return {
    type: AUTH_FORGOT_PASSWORD,
  };
}
export function resetPasswordSuccess(data) {
  return {
    type: AUTH_RESET_PASSWORD,
  };
}

export function profileDetailsSuccess(data) {
  return {
    type: AUTH_PROFILE_DETAILS,
    payload: data,
  };
}
export function passwordChangeSuccess() {
  return {
    type: AUTH_CHANGE_PASSWORD,
  };
}
export function errorClear() {
  return {
    type: AUTH_ERROR_CLEAR,
  };
}
export const setLocalStorage = (key, value) => {
  if (process.browser) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};
export const getCookie = (key) => {
  if (process.browser) {
    return Cookies.get(key);
  }
};

export const setCookie = (key, value) => {
  if (process.browser) {
    Cookies.set(key, value, {
      expires: 30,
    });
  }
};

export const removeCookie = (key) => {
  if (process.browser) {
    Cookies.remove(key, {
      expires: 30,
    });
  }
};
// get cookie

export const signin = (email, password, callback) => async (dispatch) => {
  dispatch(authLoading());
  axios
    .post(
      `${process.env.REACT_APP_API}/a-users/signin`,
      {
        email: email,
        password: password,
      },
      { withCredentials: true }
    )
    .then((response) => {
      setCookie("uac", response.data.token);
      dispatch(signinSuccess(response.data.user));
      if (callback) callback(response.data.user);
      return response.data;
    })
    .catch((err) => {
      dispatch(authError(err?.response?.data?.errors));
    });
};
export const signout = (callback) => async (dispatch) => {
  dispatch(authLoading());
  dispatch(updateUserDataSuccess(null));

  axios
    .post(`${process.env.REACT_APP_API}/a-users/signout`, {
      withCredentials: true,
    })
    .then((response) => {
      removeCookie("uac");
      dispatch(signoutSuccess(response.data));
      if (callback) {
        callback();
      }
      return response.data;
    })
    .catch((err) => {
      dispatch(authError(err));
    });
};
export const signoutEndUser = (callback) => async (dispatch) => {
  dispatch(authLoading());
  removeCookie("uac");
  dispatch(signoutEndUserSuccess());
  if (callback) callback();
};

export const signup = (data, callback, onError) => async (dispatch) => {
  dispatch(authLoading());
  axios
    .post(`${process.env.REACT_APP_API}/a-users/signup`, data, {
      withCredentials: true,
    })
    .then((response) => {
      setCookie("uac", response.data.token);
      dispatch(signupSuccess(response.data.user));
      if (callback) callback(response.data);
      return response.data;
    })
    .catch((err) => {
      dispatch(authError(err?.response?.data?.errors));
      onError();
    });
};
export const forgotPassword = (email, callback) => async (dispatch) => {
  dispatch(authLoading());
  axios
    .post(
      `${process.env.REACT_APP_API}/a-users/forgot-password`,
      {
        email: email,
      },
      { withCredentials: true }
    )
    .then((response) => {
      dispatch(forgotPasswordSuccess());
      if (callback) callback();
      return response.data;
    })
    .catch((err) => {
      dispatch(authError(err?.response?.data?.errors));
    });
};
export const resetPassword =
  (password, token, callback) => async (dispatch) => {
    dispatch(authLoading());

    axios
      .post(
        `${process.env.REACT_APP_API}/a-users/reset-password`,
        {
          password: password,
          token: token,
        },
        { withCredentials: true }
      )
      .then((response) => {
        dispatch(resetPasswordSuccess());
        if (callback) callback();
        return response.data;
      })
      .catch((err) => {
        dispatch(authError(err?.response?.data?.errors));
      });
  };

export const profileDatilsUpdate =
  (id, formData, callback) => async (dispatch) => {
    dispatch(authLoading());
    const token = getCookie("uac");

    axios
      .put(`${process.env.REACT_APP_API}/a-users/details/${id}`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(profileDetailsSuccess(response.data));
        if (callback) callback();
      })

      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        if (err.response && err.response.data)
          dispatch(authError(err?.response?.data?.errors));
      });
  };
export const passwordChange = (id, password, callback) => async (dispatch) => {
  dispatch(authLoading());
  const token = getCookie("uac");

  axios
    .put(
      `${process.env.REACT_APP_API}/a-users/password/${id}`,
      { password: password },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      dispatch(passwordChangeSuccess(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(authError(err?.response?.data?.errors));
      }
    });
};
export const clearError = () => async (dispatch) => {
  dispatch(errorClear());
};
