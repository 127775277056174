import { request } from "../api.service";

export const getPartnerTiersRequest = () => {
  return request(`/admin/partner-tiers`, "get");
};

export const createPartnerTierRequest = (data) => {
  return request(`/admin/partner-tier`, "post", data);
};

export const getPartnerTierRequest = async (id) => {
  return request(`/admin/partner-tier/${id}`, "get");
};

export const updatePartnerTierRequest = (id, data) => {
  return request(`/admin/partner-tier/${id}`, "put", data);
};

export const deletePartnerTierRequest = (id) => {
  return request(`/admin/partner-tier/${id}`, "delete");
};
