export const ADMIN_FETCH_USERS = "ADMIN_FETCH_USERS";
export const ADMIN_FETCH_USERS_SEARCH = "ADMIN_FETCH_USERS_SEARCH";
export const ADMIN_LOADING = "ADMIN_LOADING";
export const ADMIN_ERROR = "ADMIN_ERROR";
export const ADMIN_SUCCESS = "ADMIN_SUCCESS";
export const ADMIN_FETCH_MODELS = "ADMIN_FETCH_MODELS";
export const ADMIN_FETCH_SUPPORT = "ADMIN_FETCH_SUPPORT";
export const ADMIN_FETCH_END_USERS = "ADMIN_FETCH_END_USERS";
export const ADMIN_FETCH_SINGLE_MODEL = "ADMIN_FETCH_SINGLE_MODEL";
export const ADMIN_CREATE_ARCHITECT = "ADMIN_CREATE_ARCHITECT";
export const ADMIN_CREATE_SUPPORT = "ADMIN_CREATE_SUPPORT";
export const ADMIN_CREATE_CUSTOM_FIELDS = "ADMIN_CREATE_CUSTOM_FIELDS";
export const ADMIN_DELETE_CUSTOM_FIELDS = "ADMIN_DELETE_CUSTOM_FIELDS";
export const ADMIN_FETCH_CUSTOM_FIELDS = "ADMIN_FETCH_CUSTOM_FIELDS";
export const ADMIN_UPDATE_CUSTOM_FIELDS = "ADMIN_UPDATE_CUSTOM_FIELDS";
export const ADMIN_SELECT_CATEGORY = "ADMIN_SELECT_CATEGORY";
export const ADMIN_SELECT_CUSTOM_FIELD = "ADMIN_SELECT_CUSTOM_FIELD";
export const ADMIN_FETCH_FILTERS = "ADMIN_FETCH_FILTERS";
export const ADMIN_UPDATE_CATEGORIES = "ADMIN_UPDATE_CATEGORIES";
export const ADMIN_CREATE_CATEGORIES = "ADMIN_CREATE_CATEGORIES";
export const ADMIN_FETCH_CATEGORIES = "ADMIN_FETCH_CATEGORIES";
export const ADMIN_DELETE_CATEGORIES = "ADMIN_DELETE_CATEGORIES";
export const ADMIN_ERROR_CLEAR = "ADMIN_ERROR_CLEAR";
export const ADMIN_FETCH_SINGLE_USER = "ADMIN_FETCH_SINGLE_USER";
export const ADMIN_CHANGE_USER_SUBSCRIPTION = "ADMIN_CHANGE_USER_SUBSCRIPTION";
export const ADMIN_FETCH_SINGLE_END_USER = "ADMIN_FETCH_SINGLE_END_USER";
export const ADMIN_FETCH_LATEST_USERS = "ADMIN_FETCH_LATEST_USERS";
export const DUPLICATE_MODEL = "DUPLICATE_MODEL";
export const FETCH_PARTNERS = "FETCH_PARTNERS";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";

// export const CLIENT_CREATE_SUCCESS = "CLIENT_CREATE_SUCCESS";
