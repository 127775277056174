import React from "react";
import { Controller } from "react-hook-form";

const ControlledInput = ({
  type,
  control,
  fieldName,
  defaultValue,
  rules,
  placeholder,
  disabled,
  width,
  min,
  id,
}) => {
  return (
    <Controller
      control={control}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => {
        return (
          <input
            id={id}
            min={min}
            disabled={disabled}
            type={type}
            onChange={onChange} // send value to hook form
            onBlur={onBlur} // notify when input is touched/blur
            value={value} // input value
            name={fieldName} // send down the input name
            ref={ref} // send input ref, so we can focus on input when error appear
            placeholder={placeholder}
            className={` flex flex-1 appearance-none w-1/2 py-3 leading-tight focus:outline-none ${width}`}
          />
        );
      }}
      name={fieldName}
      rules={rules}
      defaultValue={defaultValue || ""}
    />
  );
};
export { ControlledInput };
