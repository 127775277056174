import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Scroll from "react-scroll";
import MenuIcon from "@mui/icons-material/Menu";
import MainButton from "../buttons/MainButton";
import FaceIcon from "@mui/icons-material/Face";
import { useSelector } from "react-redux";
import logo from "../../../assets/images/Logo.png";
import logoMarket from "../../../assets/images/logo-market.png";
// import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
// import QuizIcon from "@mui/icons-material/Quiz";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import StoreIcon from "@mui/icons-material/Store";
import { isAdminOrSupport } from "../../../helpers/isAdminOrSupport";
const ScrollingLink = ({ children, isLocal, to, classes }) => {
  if (isLocal) {
    return (
      <Scroll.Link
        to={to.split("#")[1]}
        smooth={true}
        duration={500}
        className={classes + " cursor-pointer"}
      >
        {children}
      </Scroll.Link>
    );
  } else {
    return (
      <NavLink to={to} className={classes}>
        {children}
      </NavLink>
    );
  }
};

const DashboardsNavbar = ({
  showSidebar,
  setShowSidebar,
  isMarketPlace,
  isLandingPage = false,
  marketUser,
  normalLogo,
}) => {
  const { user } = useSelector((state) => state.userData);
  const [showSidebarMarket, setShowSidebarMarket] = useState("-left-64");
  const administratorHome = () => {
    if (user && isAdminOrSupport(user.role)) {
      return (
        <Link to="/models" className="mr-4 text-primary font-bold">
          Home
        </Link>
      );
    } else if (user && user.role === "userA") {
      return (
        <Link to="/my-models" className="mr-4 text-primary font-bold">
          Home
        </Link>
      );
    } else if (user && user.role === "Partner") {
      return (
        <Link to="/users" className="mr-4 text-primary font-bold">
          Home
        </Link>
      );
    }
  };

  const SideBarMarket = () => {
    return (
      <div
        style={{ zIndex: "101" }}
        className={`lg:hidden h-screen fixed top-0 lg:left-0 ${showSidebarMarket} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-20 py-4 px-6 transition-all duration-300`}
      >
        <div className="flex flex-col items-stretch min-h-full flex-nowrap px-0 relative">
          <div className="flex flex-col flex-1">
            <ul className="flex-col min-w-full flex list-none flex-1">
              <NavLink to="/" className=" text-center w-full inline-block">
                <div className="logo-container">
                  <img src={logo} />
                </div>
              </NavLink>

              <li className="rounded-lg mb-2">
                <NavLink
                  to="/marketplace"
                  className={
                    "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                  }
                >
                  <StoreIcon />
                  <span className="ml-2">Marketplace</span>
                </NavLink>
              </li>

              {/* <li className="rounded-lg mb-2">
                <NavLink
                  to="https://xreteh.com/#about"
                  isLocal={isLandingPage}
                  classes={(navData) =>
                    navData.isActive
                      ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                      : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                  }
                >
                  <StorefrontIcon />
                  <span className="ml-2">Marketplace</span>
                </NavLink>
              </li> */}
              <li className="rounded-lg mb-2">
                <ScrollingLink
                  to="/#about"
                  isLocal={isLandingPage}
                  classes={
                    "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                  }
                >
                  <InfoIcon />
                  <span className="ml-2">About</span>
                </ScrollingLink>
              </li>
              <li className="rounded-lg mb-2">
                <ScrollingLink
                  to="/#howitworks"
                  isLocal={isLandingPage}
                  classes={
                    "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                  }
                >
                  <HelpCenterIcon />
                  <span className="ml-2">How it works</span>
                </ScrollingLink>
              </li>
              {/* <li className="rounded-lg mb-2">
                <NavLink
                  to="/"
                  className={ "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "}
                >
                  <QuizIcon />
                  <span className="ml-2">FAQ</span>
                </NavLink>
              </li> */}
              <li className="rounded-lg mb-2">
                <ScrollingLink
                  to="/#contact"
                  isLocal={isLandingPage}
                  classes={
                    "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                  }
                >
                  <ContactPageIcon />
                  <span className="ml-2">Contact</span>
                </ScrollingLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!isMarketPlace ? (
        <nav className=" w-full py-6 px-3">
          <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
            <div className="lg:hidden">
              <div
                className="text-primary  cursor-pointer"
                onClick={() => setShowSidebar("left-0")}
              >
                <MenuIcon />
              </div>
              <div
                className={`fixed top-0 lg:hidden ${
                  showSidebar === "left-0"
                    ? "left-64 w-full h-full"
                    : "-left-64 bg-white"
                } z-50 transition-all duration-300 bg-gray-200 opacity-20 cursor-pointer`}
                onClick={() => setShowSidebar("-left-64")}
              ></div>
            </div>

            <div className="flex justify-end items-center w-full">
              <div className="flex items-center">
                <div className="-mr-4 ml-6 flex flex-row justify-center items-center">
                  {administratorHome()}
                  <Link to="/my-account">
                    <div className="w-12 h-12 rounded-full overflow-hidden  bg-primary flex justify-center items-center ">
                      {user?.profilePicture && user?.profilePicture.usePath ? (
                        <img
                          src={`${process.env.REACT_APP_API}${user.profilePicture.usePath}/${user.profilePicture.filename}`}
                          alt="..."
                          className="w-full object-cover h-12"
                        />
                      ) : (
                        <FaceIcon fontSize="large" className="text-white" />
                      )}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      ) : (
        <>
          {SideBarMarket()}

          <div
            style={{ zIndex: "100" }}
            className="border-b-2 bottom-shadow h-20 flex justify-center w-full flex-row px-4 relative bg-white"
          >
            <div className="w-full flex items-center justify-between lg:pr-8 lg:pl-10 ">
              <div className="lg:hidden ">
                <div
                  className="text-primary  cursor-pointer"
                  onClick={() => setShowSidebarMarket("left-0")}
                >
                  <MenuIcon />
                </div>
                <div
                  className={`fixed top-0 lg:hidden ${
                    showSidebarMarket === "left-0"
                      ? "left-64 w-full h-full"
                      : "-left-64 bg-white"
                  } z-50 transition-all duration-300 bg-gray-200 opacity-20 cursor-pointer`}
                  onClick={() => setShowSidebarMarket("-left-64")}
                ></div>
              </div>
              <Link to={`/`}>
                <img
                  src={isLandingPage || normalLogo ? logo : logoMarket}
                  className={"w-32 lg:mx-12"}
                />
              </Link>
              <div className="flex items-center flex-wrap flex-row justify-end lg:w-1/2">
                <div className="hidden lg:flex items-center flex-row justify-evenly ">
                  <NavLink to="/marketplace" className={"mx-4"}>
                    <span>Marketplace</span>
                  </NavLink>
                  <ScrollingLink
                    to="/#about"
                    isLocal={isLandingPage}
                    classes={"mx-4"}
                  >
                    <span>About</span>
                  </ScrollingLink>
                  <ScrollingLink
                    to="/#howitworks"
                    isLocal={isLandingPage}
                    classes={"mx-4"}
                  >
                    <span>How it works</span>
                  </ScrollingLink>
                  <ScrollingLink
                    to="/#contact"
                    isLocal={isLandingPage}
                    classes={"mx-4"}
                  >
                    <span>Contact</span>
                  </ScrollingLink>

                  {/* <div className="mx-4"><a href="https://xreteh.com/#about">About</a></div>
                  <div className="mx-4"><a href="https://xreteh.com/#howitworks">How it works</a></div> */}
                  {/* <div className="mx-4">FAQ</div> */}
                  {/* <div><a href="https://xreteh.com/#contact">Contact</a></div> */}
                </div>
                {!user || Object.keys(user).length === 0 ? (
                  <div className="mx-4">
                    {/* <Link to="/signin">Sign In</Link> */}
                    <MainButton className="" title="Sign in" link={"/signin"} />
                  </div>
                ) : (
                  <>
                    <div className="flex justify-end items-center lg:-mr-4 lg:ml-6">
                      <div className="flex items-center">
                        <div className=" flex flex-row justify-center items-center">
                          {administratorHome()}
                          <Link to="/my-account">
                            <div className="w-12 h-12 overflow-hidden rounded-full bg-primary flex justify-center items-center">
                              {user.profilePicture &&
                              user.profilePicture.usePath ? (
                                <img
                                  src={`${process.env.REACT_APP_API}${user.profilePicture.usePath}/${user.profilePicture.filename}`}
                                  alt="..."
                                  className="w-full object-cover h-12"
                                />
                              ) : (
                                <FaceIcon
                                  fontSize="large"
                                  className="text-white"
                                />
                              )}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default DashboardsNavbar;
