import React from "react";
import MainButton from "../buttons/MainButton";
import Modal from "../pageElementes/Modal";
import LoadingIcon from "../icons/LoadingIcon";

const UserAssignmentModal = ({
  setVisibility,
  action,
  visibility,
  title,
  description,
  isLoading,
}) => {
  const submit = () => {
    action();
    hideModal();
  };

  const hideModal = () => {
    localStorage.removeItem("userAssignmentToken");
    setVisibility(false);
    window.location.href = "/my-account";
  };

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <Modal visibility={visibility} setVisibility={hideModal}>
      <div className="w-full flex flex-col justify-start items-center flex-1">
        <div className="w-full text-center flex flex-col justify-center items-center">
          <h3>{title}</h3>
          <br />
          <p>{description}</p>
        </div>
      </div>
      <div className="w-full flex-col sm:flex-row flex justify-center items-center mt-4">
        <div className="w-full sm:w-1/2 p-2">
          <MainButton
            action={() => {
              hideModal();
            }}
            alert={true}
            title={"Cancel"}
            width="w-full"
          />
        </div>
        <div className="w-full sm:w-1/2 p-2">
          <MainButton
            action={() => {
              submit();
            }}
            title={"Confirm"}
            width="w-full"
          />
        </div>
      </div>
    </Modal>
  );
};

export default UserAssignmentModal;
