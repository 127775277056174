import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import GroupIcon from "@mui/icons-material/Group";
import SquareButton from "../../Components/ui/buttons/SquareButton";
import ConfirmModal from "../../Components/ui/modals/ConfirmModal";
import { useHttp } from "../../hooks/useHttp";
import {
  ADMIN_CLIENTS_TABLE_COLUMNS,
  getSubscriptionColumn,
} from "./adminClients.constants";
import {
  deleteClientRequest,
  getClientsRequest,
} from "../../api/modules/clients";
import AssignModelsToUserModal from "../../Components/ui/modals/AssignModelsToUserModal";
import UserActionsModal from "../../Components/ui/modals/UserActionsModal";
import SubscriptionPlansModal from "../../Components/stripe/SubscriptionPlansModal";
import {
  assignSubscriptionRequest,
  cancelSubscriptionRequest,
  confirmSubscriptionBankTransferRequest,
  updateSubscriptionRequest,
} from "../../api/modules/subscriptions";
import { updateUserStatusRequest } from "../../api/modules/users";

const AdminClientsList = ({ data }) => {
  const navigate = useNavigate();
  const { http, isLoading } = useHttp();
  const [removeModal, setRemoveModal] = useState(false);
  const [showModelAssignmentModal, setShowModelAssignmentModal] =
    useState(false);
  const [showUserActionsModal, setShowUserActionsModal] = useState(false);
  const [showSubscriptionPlansModal, setShowSubscriptionPlansModal] =
    useState(false);
  const [confirmBankTransferModal, setConfirmBankTransferModal] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [clients, setClients] = useState();

  const getClients = () => {
    http(() => getClientsRequest()).then((res) => setClients(res));
  };

  useEffect(() => {
    getClients();
  }, [showUserActionsModal]);

  const handleActivateUser = () => {
    http(() => {
      return updateUserStatusRequest(selectedRow?.id, { status: true });
    });
  };

  const removeSubmit = async () => {
    await http(() => {
      return deleteClientRequest(selectedRow?.id);
    });

    getClients();
  };

  // TODO: move these subscription handlers to a global place and reuse them
  const onSubscriptionCreate = async (
    subscriptionPlan,
    interval,
    initPaymentCallback
  ) => {
    const stripePriceID = subscriptionPlan[interval].stripePriceID;
    const subscriptionPlanId = subscriptionPlan.id;

    const res = await http(() =>
      assignSubscriptionRequest({
        clientId: selectedRow.id,
        stripePriceID,
        subscriptionPlanId,
        isBankTransfer: subscriptionPlan.isBankTransfer,
      })
    );
    if (res?.latest_invoice?.payment_intent) {
      initPaymentCallback(res?.latest_invoice);
    } else {
      await getClients();
      setShowSubscriptionPlansModal(false);
    }
  };

  const onSubscriptionUpdate = async (
    subscriptionPlan,
    interval,
    initPaymentCallback
  ) => {
    const stripePriceID = subscriptionPlan[interval].stripePriceID;
    const subscriptionPlanId = subscriptionPlan.id;

    const res = await http(() =>
      updateSubscriptionRequest({
        clientId: selectedRow.id,
        stripePriceID,
        subscriptionPlanId,
        isBankTransfer: subscriptionPlan.isBankTransfer,
      })
    );
    if (res?.latest_invoice?.payment_intent) {
      initPaymentCallback(res?.latest_invoice);
    } else {
      await getClients();
      setShowSubscriptionPlansModal(false);
    }
  };

  const onSubscriptionCancel = async () => {
    try {
      await http(() => cancelSubscriptionRequest({ clientId: selectedRow.id }));
      await getClients();
      setShowSubscriptionPlansModal(false);
    } catch (e) {
      // TODO: handle error
    }
  };

  const onConfirmBankAccount = async () => {
    if (!selectedRow) {
      return;
    }
    await http(() =>
      confirmSubscriptionBankTransferRequest({
        clientId: selectedRow.id,
      })
    );
    await getClients();
  };

  const columns = [
    ...ADMIN_CLIENTS_TABLE_COLUMNS,
    getSubscriptionColumn({
      isLoading,
      onConfirmBankTransfer: (params) => {
        setSelectedRow(params.row);
        setConfirmBankTransferModal(true);
      },
    }),
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <div
          key={params.id}
          style={{ flexDirection: "column", display: "flex", gap: "10px" }}
        >
          {params.row.status ? (
            <>
              <SquareButton
                padding={"px-2 py-1"}
                extraClasses={`text-xs ${!params.row.status && "invisible"}`}
                action={() => {
                  setSelectedRow(params.row);
                  setShowModelAssignmentModal(true);
                }}
                title="Assign Models"
                fill={false}
              />
              <SquareButton
                padding={"px-2 py-1"}
                extraClasses={`text-xs ${!params.row.status && "invisible"}`}
                action={() => {
                  setSelectedRow(params.row);
                  setShowSubscriptionPlansModal(true);
                }}
                title="Manage Subscription"
                fill={false}
              />
            </>
          ) : (
            <SquareButton
              padding={"px-7 py-1"}
              extraClasses={`text-xs`}
              action={() => {
                setSelectedRow(params.row);
                setShowUserActionsModal(true);
              }}
              title="Activate User"
              fill={false}
            />
          )}
          <SquareButton
            action={() => {
              navigate(`/clients/${params.id}`);
            }}
            extraClasses={"text-xs"}
            padding={"px-2 py-1"}
            title="Edit"
            fill={true}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <div className="w-full h-full flex flex-col justify-around">
        <PageHeader
          Icon={GroupIcon}
          action={() => navigate("/clients/new")}
          buttonTitle="Add Client User"
        />
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadowBox overflow-hidden ">
                <div style={{ width: "100%" }}>
                  <DataGrid
                    loading={isLoading}
                    style={{ border: "0", padding: "10px 20px" }}
                    rows={clients || []}
                    columns={columns}
                    checkboxSelection={false}
                    pageSize={10}
                    autoHeight={true}
                    rowHeight={150}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {showModelAssignmentModal && (
          <AssignModelsToUserModal
            isClient={true}
            visibility={showModelAssignmentModal}
            setVisibility={setShowModelAssignmentModal}
            user={(clients || []).find(
              (client) => client.id === selectedRow.id
            )}
            onSubmit={async () => {
              await getClients();
              setShowModelAssignmentModal(false);
            }}
          />
        )}
        {showUserActionsModal && (
          <UserActionsModal
            buttonText="Activate"
            onClose={() => setShowUserActionsModal(false)}
            submitAction={async () => {
              await handleActivateUser();
              await getClients();
              setShowUserActionsModal(false);
            }}
            title="Are you sure you want to activate this user?"
          />
        )}

        {showSubscriptionPlansModal && (
          <SubscriptionPlansModal
            buttonText="Submit"
            onClose={() => setShowSubscriptionPlansModal(false)}
            selectedClient={selectedRow}
            onSubscriptionCreate={onSubscriptionCreate}
            onSubscriptionUpdate={onSubscriptionUpdate}
            onSubscriptionCancel={onSubscriptionCancel}
            isLoading={isLoading}
            returnPath={"/clients"}
          />
        )}

        {removeModal && (
          <ConfirmModal
            buttonText="Remove Client"
            visibility={removeModal}
            setVisibility={setRemoveModal}
            submitAction={removeSubmit}
            title="Are you sure you want to remove this client?"
            description={`This action is irreversible.`}
          />
        )}

        {confirmBankTransferModal && (
          <ConfirmModal
            buttonText="Confirm"
            visibility={confirmBankTransferModal}
            setVisibility={setConfirmBankTransferModal}
            submitAction={onConfirmBankAccount}
            title="Are you sure you want to confirm this bank Transfer?"
            description={`This action is irreversible.`}
            isGreenConfirmation={true}
          />
        )}
      </div>
    </div>
  );
};

export default AdminClientsList;
