import React, { useEffect, useState } from "react";
import ModelForm from "../../Components/ui/forms/ModelForm";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import {
  downloadModelRequest,
  getSingleModelRequest,
} from "../../api/modules/models";
import { useHttp } from "../../hooks/useHttp";

const FormModelPage = () => {
  const { id } = useParams();
  const { http, isLoading } = useHttp();
  const [singleModel, setSingleModel] = useState();
  const { user } = useSelector((state) => state.auth);

  const handleDownloadModel = async () => {
    const resData = await http(() => downloadModelRequest(id));

    const url = window.URL.createObjectURL(new Blob([resData]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${id}-${singleModel?.name}.zip`); // or any other extension
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    http(() => getSingleModelRequest(id))
      .then((res) => {
        setSingleModel(res);
      })
      .catch((res) => {
        if (res.status === 404 || res.status === 500) {
          window.location.href = "/not-found";
        }
      });
  }, [id]);

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        iconColor={singleModel?.arReady && "bg-green"}
        subtitle={singleModel?.arReady && "The model is AR ready"}
        title={singleModel?.name || "New Model"}
        Icon={ViewInArIcon}
        buttonTitle={id ? "Download Model" : undefined}
        action={() => handleDownloadModel(id)}
        backLink={user.role === "userA" ? "/my-models" : "/models"}
        actionIsLoading={isLoading}
      />
      <ModelForm singleModel={singleModel} />
    </div>
  );
};

export default FormModelPage;
