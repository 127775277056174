import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

export function BasicMenu({ anchorEl, setAnchorEl, handleSort }) {
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (sortBy, sortValue) => () => {
    handleSort(sortBy, sortValue);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Sort By
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {" "}
        <MenuItem onClick={handleClose("createdAt", "desc")}>
          Newest first
        </MenuItem>{" "}
        <MenuItem onClick={handleClose("createdAt", "asc")}>
          Oldest first
        </MenuItem>
        <MenuItem onClick={handleClose("name", "asc")}>A - Z</MenuItem>
        <MenuItem onClick={handleClose("name", "desc")}>Z - A</MenuItem>
      </Menu>
    </>
  );
}
