import moment from "moment";
import FaceIcon from "@mui/icons-material/Face";
import SquareButton from "../../Components/ui/buttons/SquareButton";
import React from "react";

export const ADMIN_CLIENTS_TABLE_COLUMNS = [
  {
    field: "profilePicture",
    renderCell: (params) => (
      <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full overflow-hidden">
        {params.row.profilePicture ? (
          <img
            src={`${process.env.REACT_APP_API}${params.row.profilePicture.usePath}/${params.row.profilePicture.filename}`}
            alt={params.row.name}
            className="object-cover w-full h-full"
          />
        ) : (
          <FaceIcon className="text-white " />
        )}
      </div>
    ),
    headerName: "",
    flex: 1,
    maxWidth: 50,
  },
  {
    field: "name",
    valueGetter: (params) =>
      `${params.row.name} ${params.row.phone} ${params.row.email}`,
    renderCell: (params) => {
      return (
        <div>
          <p>
            <b>{params.row.name}</b>
          </p>
          <p>{params.row.phone}</p>
          <p>{params.row.email}</p>
        </div>
      );
    },
    headerName: "Name, Phone, Email",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "createdAt",
    flex: 1,
    valueGetter: (params) => {
      return moment(params.row.createdAt).format("ll");
    },
    headerName: "Join Date",
  },
  {
    field: "relatedClients",
    flex: 1,
    valueGetter: (params) => {
      return params.row.connectedEndUsers.length;
    },
    renderHeader: () => (
      <span style={{ lineHeight: 1 }}>
        Related <br /> End Users
      </span>
    ),
  },
  {
    field: "isActive",
    flex: 1,
    renderCell: (params) => {
      return (
        <span
          className={`${
            params.row.status ? "bg-blue-100" : "bg-red-300"
          } text-blue-800 text-xs font-semibold mr-2 mb-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800`}
        >
          {params.row?.status ? "Yes" : "No"}
        </span>
      );
    },
    headerName: "Is Active",
  },
];

export const getSubscriptionColumn = ({ isLoading, onConfirmBankTransfer }) => {
  return {
    field: "subscription",
    headerName: "Subscription",
    minWidth: 250,
    renderCell: (params) => {
      if (!params.row.subscription?.subscriptionPlan) {
        return "N/A";
      }

      const subscriptionPlanName = (
        <>
          Plan: <b>{params.row.subscription?.subscriptionPlan?.name}</b>
        </>
      );

      const assignedBy = params.row.subscription?.assignedByUser && (
        <>
          Assigned by: <b>{params.row.subscription?.assignedByUser?.name}</b>
        </>
      );

      const isBankTransfer = params.row.subscription?.isBankTransfer && (
        <>
          Payment Method: <b>Bank Transfer</b>
        </>
      );

      const trialStarted = params.row.stripeSubscription?.trial_start && (
        <>
          Started at:{" "}
          <b>
            {moment(params.row.stripeSubscription?.trial_start * 1000).format(
              "ll"
            )}
          </b>
        </>
      );

      const trialEnds = params.row.stripeSubscription?.trial_end && (
        <>
          Payment Deadline:{" "}
          <b>
            {moment(params.row.stripeSubscription?.trial_end * 1000).format(
              "ll"
            )}
          </b>
        </>
      );

      const needsBankConfirmation =
        !params.row.subscription?.bankTransferConfirmedAt &&
        params.row.subscription?.isBankTransfer;

      const confirmBankTransferAction = (
        <>
          {params.row.subscription?.bankTransferConfirmedAt && (
            <>
              Confirmed at{" "}
              <b>
                {moment(
                  params.row.subscription?.bankTransferConfirmedAt
                ).format("ll HH:mm")}
              </b>
            </>
          )}

          {needsBankConfirmation && (
            <SquareButton
              action={() => onConfirmBankTransfer(params)}
              extraClasses={"text-xs ml-0 mr-0 mt-2 mb-2 text-white"}
              padding={"px-2 py-1"}
              title="Confirm Bank Transfer"
              disabled={isLoading}
              isLoading={isLoading}
              style={{ backgroundColor: "#2f855a", color: "white" }}
            />
          )}
        </>
      );

      return ["active", "trialing"].includes(
        params.row.stripeSubscription?.status
      ) ? (
        <div>
          <p>{subscriptionPlanName}</p>
          <p>{assignedBy}</p>
          <p>{isBankTransfer}</p>
          {needsBankConfirmation && (
            <>
              <p>{trialStarted}</p>
              <p>{trialEnds}</p>
            </>
          )}
          <p>{confirmBankTransferAction}</p>
        </div>
      ) : (
        "N/A"
      );
    },
  };
};
