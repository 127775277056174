import {
  CLIENTS_ERROR,
  CLIENTS_LOADING,
  CLIENTS_SUCCESS,
  CLIENT_CREATE_SUCCESS,
  FETCH_CLIENTS,
  CLIENTS_ERROR_CLEAR,
  CLIENTS_SINGLE_USER,
  FETCH_CLIENTS_SEARCH,
  USER_DATA_BREAK_CONNECTION,
  FETCH_LATEST_CLIENTS,
} from "./constants";
import axios from "axios";
import cookie from "js-cookie";
import clearAllStoreData from "../../resetAction";
export const getCookie = (key) => {
  if (process.browser) {
    return cookie.get(key);
  }
};
export function clientsError(data) {
  return {
    type: CLIENTS_ERROR,
    payload: data,
  };
}

export function clientsLoading() {
  return {
    type: CLIENTS_LOADING,
  };
}

export function clientsSuccess(data) {
  return {
    type: CLIENTS_SUCCESS,
    payload: data,
  };
}
export function fetchClientsSuccess(data) {
  return {
    type: FETCH_CLIENTS,
    payload: data,
  };
}
export function fetchLatestClientsSuccess(data) {
  return {
    type: FETCH_LATEST_CLIENTS,
    payload: data,
  };
}
export function fetchClientsSearchSuccess(data) {
  return {
    type: FETCH_CLIENTS_SEARCH,
    payload: data,
  };
}
export function clientCreated(data) {
  return {
    type: CLIENT_CREATE_SUCCESS,
    payload: data,
  };
}
export function errorClear() {
  return {
    type: CLIENTS_ERROR_CLEAR,
  };
}
export function singleClientSuccess(data) {
  return {
    type: CLIENTS_SINGLE_USER,
    payload: data,
  };
}
export function breakConnectionSuccess(data) {
  return {
    type: USER_DATA_BREAK_CONNECTION,
    payload: data,
  };
}
export function fetchClients(page, limit) {
  return (dispatch) => {
    dispatch(clientsLoading());
    const token = getCookie("uac");

    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;
    axios
      .get(`${process.env.REACT_APP_API}/a-users/connected-users${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(fetchClientsSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        if (err.response && err.response.data)
          dispatch(clientsError(err.response.data.errors));
      });
  };
}
export function fetchLatestClients(page, limit) {
  return (dispatch) => {
    dispatch(clientsLoading());
    const token = getCookie("uac");

    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;
    axios
      .get(`${process.env.REACT_APP_API}/a-users/connected-users${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(fetchLatestClientsSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        if (err.response && err.response.data)
          dispatch(clientsError(err.response.data.errors));
      });
  };
}
export function searchClients(search, page, limit) {
  return (dispatch) => {
    dispatch(clientsLoading());
    const token = getCookie("uac");

    axios
      .get(
        `${
          process.env.REACT_APP_API
        }/a-users/connected-users/search?page=${page}${
          limit ? "&limit=" + limit : ""
        }&search=${search}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(fetchClientsSearchSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        if (err.response && err.response.data)
          dispatch(clientsError(err.response.data.errors));
      });
  };
}
export function fetchSingleClient(id, callback) {
  return (dispatch) => {
    dispatch(clientsLoading());
    const token = getCookie("uac");

    axios
      .get(`${process.env.REACT_APP_API}/a-users/single-client/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(singleClientSuccess(response.data.user));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        if (err.response && err.response.data)
          dispatch(clientsError(err.response.data.errors));
      });
  };
}
export function addClient(data, callback) {
  return (dispatch) => {
    dispatch(clientsLoading());
    const token = getCookie("uac");
    axios
      .post(`${process.env.REACT_APP_API}/end-users/create`, data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(clientCreated(response.data));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        if (err.response && err.response.data)
          dispatch(clientsError(err.response.data.errors));
      });
  };
}
export function updateClient(id, data, callback) {
  return (dispatch) => {
    dispatch(clientsLoading());
    const token = getCookie("uac");
    axios
      .put(`${process.env.REACT_APP_API}/a-users/update-client/${id}`, data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(singleClientSuccess(response.data));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        if (err.response && err.response.data)
          dispatch(clientsError(err.response.data.errors));
      });
  };
}
export const breakConnection = (data, callback) => async (dispatch) => {
  dispatch(clientsLoading());
  const token = getCookie("uac");

  axios
    .put(`${process.env.REACT_APP_API}/a-users/break-connection`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(breakConnectionSuccess(response.data));
      if (callback) callback();
    })

    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      }
      dispatch(clientsError(err.response.data.errors));
    });
};
export const clearError = () => async (dispatch) => {
  dispatch(errorClear());
};
