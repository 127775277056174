import React, { useCallback, useEffect, useState } from "react";
import { MenuItem, Select, Slider } from "@mui/material";
import MainButton from "../buttons/MainButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getCategoriesRequest } from "../../../api/modules/categories";
import { getFiltersBySubcategoryId } from "../../../api/modules/customFields";
import { useHttp } from "../../../hooks/useHttp";

const SidebarFiltersCustomFields = ({ onSubmit, searchValue }) => {
  const { http, isLoading } = useHttp();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [customFields, setCustomFields] = useState([]);
  const [categories, setCategories] = React.useState([]);
  const [subcategories, setSubcategories] = React.useState([]);

  const formik = useFormik({
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      category: Yup.string().required(),
    }),
    initialValues: {
      category: "*",
    },
    onSubmit: async (data) => {
      const customFields = prepareCustomFields(data);

      const _data = { customFields: customFields };
      for (const [key, value] of Object.entries(data)) {
        // cleanup data object
        if (!key.startsWith("customFields_") && value !== "*") {
          _data[key] = value;
        }
      }

      setIsSubmitting(true);
      await onSubmit(_data);
      setIsSubmitting(false);
    },
  });

  useEffect(() => {
    http(() => getCategoriesRequest()).then((res) => setCategories(res));
  }, []);

  const getCustomFieldsBySubCategoryId = async (subCategoryId) => {
    return http(() => getFiltersBySubcategoryId(subCategoryId, true));
  };

  const getInitialValuesForNumbers = (customFields) => {
    const _initialValues = {};

    customFields?.map((customField) => {
      if (customField.type !== "Number") {
        return;
      }

      const numberMaxValue = Math.max(...customField.values);
      _initialValues[`customFields_${customField._id}`] = [0, numberMaxValue];
    });

    return _initialValues;
  };

  const onCategoryChange = (e) => {
    const catId = e.target.value;
    const catObject = (categories || []).find((cat) => cat.id === catId);

    setSubcategories(catObject?.subcategories || []);
    formik.setFieldValue("subcategory", "*");
    setCustomFields([]);
  };

  const onSubcategoryChange = async (e) => {
    const subCatId = e.target.value;
    setCustomFields([]);

    if (!subCatId || subCatId === "*") {
      return;
    }

    const res = await getCustomFieldsBySubCategoryId(subCatId);
    setCustomFields(res?.filters);

    const numbersInitialValues = getInitialValuesForNumbers(res.filters);

    for (const [key, value] of Object.entries(numbersInitialValues)) {
      formik.setFieldValue(key, value);
      formik.setFieldTouched(key, true);
    }
  };

  const prepareCustomFields = (formFields) => {
    const _customFields = [];
    Object.keys(formFields).map((field) => {
      if (!field.startsWith("customFields_")) {
        return;
      }

      const customFieldId = field.replace("customFields_", "");

      const customField = customFields.find(
        (customField) => customField._id === customFieldId
      );

      if (!customField) {
        return;
      }

      _customFields.push({
        _id: customFieldId,
        value: formFields[field],
        type: customField.type,
      });
    });

    return _customFields;
  };

  const onClearFilters = useCallback(() => {
    formik.setFieldValue("category", "*");
    formik.setFieldValue("subcategory", "*");

    setSubcategories([]);
    setCustomFields([]);
    onSubmit({});
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="w-full px-2">
        <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
          Category
        </h3>
        <div
          className={`w-full flex justify-center items-center px-7 border border-fade rounded-4xl my-4`}
        >
          <Select
            name={"category"}
            style={{ width: "100%" }}
            value={formik.values?.category}
            label="Category"
            onChange={(e) => {
              formik.handleChange(e);
              onCategoryChange(e);
            }}
          >
            <MenuItem value={"*"}>ALL</MenuItem>
            {categories?.map((cat) => (
              <MenuItem value={cat.id} key={cat.id}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      {!!subcategories?.length && (
        <div className="w-full px-2">
          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
            Subcategory
          </h3>
          <div
            className={`w-full flex justify-center items-center px-7 border border-fade rounded-4xl my-4`}
          >
            <Select
              name={"subcategory"}
              style={{ width: "100%" }}
              placeholder={"Select a subcategory"}
              value={formik.values?.subcategory}
              label="Subcategory"
              inputProps={{ "aria-label": "Without label" }}
              onChange={async (e) => {
                formik.handleChange(e);
                onSubcategoryChange(e);
              }}
            >
              <MenuItem value={"*"}>ALL</MenuItem>
              {subcategories.map((subcat) => (
                <MenuItem value={subcat._id} key={subcat._id}>
                  {subcat.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      )}

      {(customFields || []).map((customField, index) => {
        const selectOptions =
          customField.type === "Dropdown List"
            ? customField.dropdownList
            : customField.values;

        const rangeSliderValues = formik.values?.[
          `customFields_${customField._id}`
        ] || [0, Math.max(...customField.values)];

        return (
          <div
            key={index}
            className="w-full flex items-center justify-center flex-col sm:flex-row "
          >
            <div className="w-full px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                {customField.name}
                <span className="text-xs text-red-500">*</span>
              </h3>

              {/* TODO: Move hard-coded string to a constant */}
              {["Dropdown List", "String"].includes(customField.type) && (
                <div
                  className={`w-full flex justify-center items-center px-7 border border-fade rounded-4xl my-4`}
                >
                  <Select
                    multiple={customField.isMultiple}
                    name={`customFields_${customField._id}`}
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                  >
                    {selectOptions?.map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}

              {/* TODO: Move hard-coded string to a constant */}
              {customField.type === "Number" && (
                <div className="py-2">
                  <div className="flex ml-2 mr-2 ">
                    <Slider
                      name={`customFields_${customField._id}`}
                      size="small"
                      value={rangeSliderValues}
                      min={0}
                      max={Math.max(...customField.values)}
                      onChange={formik.handleChange}
                      valueLabelDisplay="auto"
                    />
                  </div>
                  <div className="flex">
                    {rangeSliderValues.map((value) => (
                      <div
                        key={value}
                        className="border-2 rounded-lg w-full p-2 mx-2 text-center cursor-normal bg-gray-100"
                      >
                        {value}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}

      <div
        className="flex justify-center w-full flex justify-end text-primary text-sm  cursor-pointer"
        onClick={() => onClearFilters()}
      >
        Clear filters
      </div>

      <br />

      <div className="flex justify-center">
        <MainButton
          title="Apply"
          type={"submit"}
          isLoading={isLoading || isSubmitting}
          disabled={isLoading || isSubmitting || !formik.isValid}
          width="w-full"
        />
      </div>
    </form>
  );
};

export default SidebarFiltersCustomFields;
