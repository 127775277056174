import React, { useMemo } from "react";
import leftBgImage from "../../assets/images/landing-page/hiw-bg-1.png";
import leftMainImg from "../../assets/images/landing-page/hiw-big.png";
import leftSmallImg1 from "../../assets/images/landing-page/hiw-small-1.png";
import leftSmallImg2 from "../../assets/images/landing-page/hiw-small-2.png";
import icon1 from "../../assets/images/landing-page/icon1.png";
import icon2 from "../../assets/images/landing-page/icon2.png";
import icon3 from "../../assets/images/landing-page/icon3.png";
import bgGraphics from "../../assets/images/landing-page/small-graphics-4.png";

const HowItWorksSection = () => {
  const steps = useMemo(() => {
    return [
      {
        title: "Create New Experiences",
        description:
          "Create a more immersive experience for your users and make sure that your projects are of the best quality.",
        icon: icon1,
      },
      {
        title: "Explore",
        description:
          "Build anything in a fraction of the time with fewer resources. A more efficient way to design a project of all sizes.",
        icon: icon2,
      },
      {
        title: "Discover New Opportunities",
        description:
          "With our easy to use platform, businesses of all sizes can discover new opportunities.",
        icon: icon3,
      },
    ];
  });

  return (
    <div id="howitworks">
      <div className="flex w-full flex-col lg:flex-row lg:h-screen">
        <div className="w-full lg:w-1/2 flex flex-col justify-center lg:ml-24 my-24 lg:my-0 space-y-5 z-20">
          <div
            className="relative w-full justify-center"
            style={{ paddingTop: "100%" }}
          >
            <img className="absolute top-0" src={leftBgImage} />
            <img
              className="absolute top-0 bottom-0 left-0 right-0 ml-auto mr-auto mb-auto mt-auto"
              src={leftMainImg}
            />
            <img
              className="absolute top-0 w-1/5 w-2/5 right-10"
              src={leftSmallImg1}
            />
            <img
              className="absolute bottom-1/4 w-2/5"
              style={{ left: "-15%" }}
              src={leftSmallImg2}
            />
          </div>
        </div>
        <div className="relative w-full lg:w-1/2 px-8 lg:px-0 flex flex-col justify-center z-10 text-left lg:pr-48">
          <div className="relative">
            <h1 className="text-5xl font-bold">How it works?</h1>
            <h1
              className="absolute text-8xl font-bold lg:left-24 text-gray-200"
              style={{ marginTop: "-1em", zIndex: -1 }}
            >
              How
            </h1>
          </div>
          <p>
            The perfect AR design tool for architects! We offer easy-to-use
            tools to create stunning AR designs that can be easily shared with
            your clients.
          </p>
          <br />
          {steps.map((step, idx) => {
            return (
              <div
                key={idx}
                className="relative flex flex-row space-x-8 shadow-card items-center fit-content"
              >
                <div style={{ minWidth: "75px" }}>
                  <img src={step.icon}></img>
                </div>
                <div className="flex flex-col">
                  <h3 className="text-left">{step.title}</h3>
                  <p>{step.description}</p>
                </div>
                <p
                  className="absolute text-8xl font-bold right-4 text-gray-200 mr-3 top-0 bottom-0"
                  style={{ zIndex: -1 }}
                >
                  0{idx + 1}
                </p>
              </div>
            );
          })}
          <div className="absolute hidden lg:flex top-0 right-0">
            <img src={bgGraphics} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksSection;
