import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/Logo.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { useDispatch, useSelector } from "react-redux";
import { signin, clearError } from "../../redux/modules/auth/actions";
import { fetchUserData } from "../../redux/modules/user/actions";

import Page from "../../Components/auth/Page";
import Container from "../../Components/auth/Container";
import MainButton from "../../Components/ui/buttons/MainButton";

// import GoogleLogin from "react-google-login";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user, error } = useSelector((state) => state.auth);
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [showErrors, setShowErrors] = useState(false);
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     if (user.role === "Admin") {
  //       navigate("/models");
  //     } else if (user.role === "Partner") {
  //       navigate("/users");
  //     } else if (user.role === "userA") {
  //       navigate("/my-models");
  //     }
  //   }
  // }, []);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  useEffect(() => {
    if (isAuthenticated) {
      if (user.role === "Admin") {
        navigate("/models");
      } else if (user.role === "Support") {
        navigate("/models");
      } else if (user.role === "userA") {
        navigate("/my-models");
      } else if (user.role === "Partner") {
        navigate("/users");
      }
    }
    dispatch(clearError());
  }, []);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const signinCallback = (user) => {
    dispatch(fetchUserData());
    if (user.role === "Admin") {
      navigate("/models");
    } else if (user.role === "Support") {
      navigate("/models");
    } else if (user.role === "userA") {
      navigate("/my-models");
    } else if (user.role === "Partner") {
      navigate("/users");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(signin(values.email.trim(), values.password, signinCallback));
  };
  useEffect(() => {
    if (error && error.length > 0) {
      setShowErrors(true);
    }
  }, [error]);
  const errorDisplay = () => {
    if (error.length > 0) {
      return error.map((error, i) => {
        return (
          <p
            key={i}
            className="text-xs text-red-500 "
            style={{ minHeight: "1rem" }}
          >
            {error.message}
          </p>
        );
      });
    }
  };
  return (
    <Page>
      <Container className="flex items-center">
        <Link to="/">
          <img src={logo} style={{ margin: "0 auto" }} className="logo h-32" />
        </Link>
        <div className="z-10 w-full bg-white sm:mt-5 p-2 sm:p-5 box-shadow-default rounded-lg flex justify-center items-center flex-col">
          <div className="py-7">
            <h2 className="font-bold text-center text-black py-2.5">
              Sign in as Client/Partner
            </h2>
            <p className="font-medium text-center text-fade leading-7 tracking-wide">
              Sign in to your account to continue
            </p>
          </div>
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="form flex flex-col justify-between items-center w-full"
          >
            <div className="w-full sm:w-5/6 flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4">
              <MailOutlineIcon />
              <input
                id="login-email"
                placeholder="Email"
                onChange={handleChange("email")}
                className="flex flex-1 appearance-none  w-1/2 py-3 ml-5 leading-tight focus:outline-none"
              />
            </div>
            <div className="w-full sm:w-5/6 flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4">
              <LockOutlinedIcon />
              <input
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                placeholder="Password"
                onChange={handleChange("password")}
                className="flex flex-1 appearance-none  w-1/2 py-3 ml-5 leading-tight focus:outline-none"
              />
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            </div>
            <div className="w-full flex justify-evenly my-2">
              <Link
                className="text-primary font-medium text-center sm:text-right"
                to="/signup"
              >
                Don&apos;t have an account?
              </Link>
              <Link
                className="text-primary font-medium text-center sm:text-right"
                to="/forgot-password"
              >
                Forgot password?
              </Link>
            </div>

            {showErrors && error.length > 0 && errorDisplay()}
            <div
              style={{ marginTop: "30px" }}
              className="flex justify-center items-center flex-col h-12 w-full my-4"
            >
              <MainButton
                title="Enter the virtual world"
                type="submit"
                width="w-full mt-4 sm:w-5/6"
              />

              {/* <GoogleLogin
                clientId="597874487104-no4ej16ueobqae0o5nrirll50rmg9bhl.apps.googleusercontent.com" // de pus undeva mai ok
                buttonText="Login with Google"
                className="mt-2 mb-4 rounded-l-full"
                onSuccess={(response) => {
                  alert(
                    "Autentificare google cu success, de fauct interactiunea cu backend"
                  );
                }}
                onFailure={(res) => {
                  setShowErrors(true);
                }}
              /> */}
            </div>
          </form>
        </div>
        <div className="w-full flex justify-evenly my-2">
          <Link
            className="text-primary font-medium text-center sm:text-right"
            to="/signin"
          >
            Login as end user
          </Link>
        </div>
      </Container>
    </Page>
  );
};

export default LoginPage;
