import React from "react";
export const ToggleSwitch = ({ checked, onChange, label }) => {
  return (
    <div className="flex justify-center">
      <div className="form-check form-switch">
        <input
          className="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          checked={checked}
          onChange={onChange}
        />
        <label
          className="form-check-label inline-block text-blue-800"
          htmlFor="flexSwitchCheckDefault"
        >
          {label || `User is ${checked ? "Active" : "Inactive"}`}
        </label>
      </div>
    </div>
  );
};
