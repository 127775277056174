export const USER_DATA = "USER_DATA";
export const USER_DATA_LOADING = "USER_DATA_LOADING";
export const USER_DATA_ERROR = "USER_DATA_ERROR";
export const USER_DATA_ERROR_CLEAR = "USER_DATA_ERROR_CLEAR";
export const USER_DATA_PROFILE_PICTURE = "USER_DATA_PROFILE_PICTURE";

export const SIGN_UP_END_USER = "SIGN_UP_END_USER";
export const SIGN_UP_END_USER_ERROR = "SIGN_UP_END_USER_ERROR";
export const SIGN_UP_END_USER_LOADING = "SIGN_UP_END_USER_LOADING";
export const RESET_SIGIN_UPDATA = "RESET_SIGIN_UPDATA";
export const ADD_MODEL_TO_FAVORITE_SUCCESS = "ADD_MODEL_TO_FAVORITE_SUCCESS";
export const REMOVE_MODEL_TO_FAVORITE_SUCCESS =
  "REMOVE_MODEL_TO_FAVORITE_SUCCESS";
export const NOT_AUTH_USER_FAVORITE = "NOT_AUTH_USER_FAVORITE";
