export const CLIENT_MODIFIED_MODELS_TABLE_COLUMNS = [
  {
    field: "endUserName",
    valueGetter: (params) => `${params.row.endUser.name}`,
    headerName: "End User Name",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "endUserEmail",
    valueGetter: (params) => `${params.row.endUser.email}`,
    headerName: "Email",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "endUserPhone",
    valueGetter: (params) => `${params.row.endUser.phone}`,
    headerName: "Phone",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "name",
    valueGetter: (params) => `${params.row.model.name}`,
    headerName: "Model Name",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "size",
    valueGetter: (params) => `${params.row.width} x ${params.row.height}`,
    headerName: "Size(Width x Height)",
    flex: 1,
    minWidth: 150,
  },
];
