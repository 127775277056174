import React, { useEffect } from "react";
import LandingPageLayout from "../../Components/layouts/LandingPageLayout";

const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full">
      <LandingPageLayout isLandingPage={false} normalLogo={true}>
        <div className="text-left px-8 lg:px-24 my-24">
          <section className="text-gray-500">
            <h1 className="font-bold text-black">DISCLAIMER</h1>
            <h3 className="text-left font-bold text-sm">
              Last updated March 21, 2022
            </h3>
          </section>
          <section className="my-12 text-gray-500">
            <h2 className="text-left text-black text-2xl">
              WEBSITE DISCLAIMER
            </h2>
            <br />
            <p>
              The information provided by XRETEH SRL ("we," "us", or "our") on{" "}
              <a className="decorated-link" href="https://xreteh.com">
                https://xreteh.com
              </a>{" "}
              (the "Site") and our mobile application is for general
              informational purposes only. All information on the Site and our
              mobile application is provided in good faith, however we make no
              representation or warranty of any kind, express or implied,
              regarding the accuracy, adequacy, validity, reliability,
              availability or completeness of any information on the Site or our
              mobile application . UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY
              LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
              RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR
              RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE
              APPLICATION . YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND
              YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE
              APPLICATION IS SOLELY AT YOUR OWN RISK.
            </p>
          </section>
          <section className="my-12 text-gray-500">
            <h2 className="text-left text-black text-2xl">
              TESTIMONIALS DISCLAIMER
            </h2>
            <br />
            <p>
              The Site may contain testimonials by users of our products and/or
              services. These testimonials reflect the real-life experiences and
              opinions of such users. However, the experiences are personal to
              those particular users, and may not necessarily be representative
              of all users of our products and/or services. We do not claim, and
              you should not assume, that all users will have the same
              experiences. YOUR INDIVIDUAL RESULTS MAY VARY.
            </p>
            <br />
            <p>
              The testimonials on the Site are submitted in various forms such
              as text, audio and/or video, and are reviewed by us before being
              posted. They appear on the Site verbatim as given by the users,
              except for the correction of grammar or typing errors. Some
              testimonials may have been shortened for the sake of brevity where
              the full testimonial contained extraneous information not relevant
              to the general public.
            </p>
            <br />
            <p>
              The views and opinions contained in the testimonials belong solely
              to the individual user and do not reflect our views and opinions.
              We are not affiliated with users who provide testimonials, and
              users are not paid or otherwise compensated for their
              testimonials.
            </p>
          </section>
        </div>
      </LandingPageLayout>
    </div>
  );
};

export default Disclaimer;
