import React, { useState, useEffect } from "react";
import FaceIcon from "@mui/icons-material/Face";
import ModelsList from "../../Components/ui/pageElementes/ModelsList";
import {
  fetchSingleUser,
  singleUserUpdate,
  addArchitectUser,
} from "../../redux/modules/partners/actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import { useForm, Controller } from "react-hook-form";
import Empty from "../../Components/ui/pageElementes/Empty";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import CountriesAutocomplete from "../../Components/ui/forms/CountriesAutocomplete";
import SuccessModal from "../../Components/ui/modals/SuccessModal";
import { useModalContext } from "../../contexts/SuccessModalContext";

const PartnerSingleUser = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEdit = id !== undefined;
  const { singleUser, error } = useSelector((state) => state.partnersData);
  const { setOpenState } = useModalContext();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isActive, setIsActive] = useState(singleUser?.user?.status);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
    isEdit && dispatch(fetchSingleUser(id));
  }, []);

  const onUpdateCallback = () => {
    dispatch(fetchSingleUser(id));
    setOpenState(true);
    navigate("/users");
  };

  const onToggleChange = () => {
    setIsActive(!isActive);
  };

  const onCreateCallback = () => {
    setOpenState(true);
    navigate("/users");
  };

  const submit = (data) => {
    dispatch(
      singleUserUpdate(id, { ...data, status: isActive }, onUpdateCallback)
    );
  };
  const onCreateSubmit = (data) => {
    dispatch(addArchitectUser(data, onCreateCallback));
  };

  useEffect(() => {
    isEdit ? reset(singleUser.user) : reset();
  }, [singleUser]);

  const backendErrors = () => {
    return error?.map((e) => (
      <li className="text-xs text-red-500 " key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };

  return (
    <div className="w-full h-full flex flex-col justify-around">
      {isEdit && (
        <PageHeader
          backLink={"/users"}
          Profile={
            singleUser.user?.profilePicture ? (
              <img
                src={`${process.env.REACT_APP_API}/content/${singleUser.user.id}/${singleUser.user.updatedAt}`}
                alt={singleUser.user.name}
                className="w-full object-cover"
              />
            ) : (
              <FaceIcon className="text-white " />
            )
          }
          buttonSecondary={true}
          toggleChecked={isActive}
          onToggleChange={onToggleChange}
          title={
            singleUser.user?.name
              ? singleUser.user?.name
              : singleUser.user?.email
          }
        />
      )}
      <div className="py-2 align-middle inline-block min-w-full w-full">
        <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Email
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="client-email"
                  control={control}
                  fieldName="email"
                  disabled={isEdit && true}
                  type="email"
                  defaultValue={isEdit ? singleUser?.user?.email : ""}
                  placeholder={"Email"}
                  rules={{
                    pattern: {
                      value:
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                      message: "Please use a valid email address",
                    },
                    required: {
                      value: true,
                      message: "Please add the users email",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              ></p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Name
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="client-name"
                  control={control}
                  fieldName="name"
                  type="text"
                  disabled={isEdit && true}
                  defaultValue={isEdit ? singleUser?.user?.name : ""}
                  placeholder={"Name"}
                  rules={{
                    minLength: {
                      value: 4,
                      message: "The min length of the name is 4",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.name && errors.name.message}
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Phone
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                id="client-phone"
                control={control}
                fieldName="phone"
                type="text"
                disabled={isEdit && true}
                defaultValue={isEdit ? singleUser?.user?.phone : ""}
                placeholder={"+CC XXX XXX XXX"}
                rules={{
                  pattern: {
                    value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                    message: "Please use a valid phone number",
                  },
                  required: {
                    value: true,
                    message: "Please add the users phone number",
                  },
                }}
              />
            </div>

            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.phone && errors.phone.message}
            </p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex items-start font-medium leading-6 text-gray-900">
              Country
            </h3>
            <div
              className={`w-full flex justify-center  items-center  px-7  border border-fade rounded-4xl my-4`}
            >
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <CountriesAutocomplete
                      onChange={(country) => {
                        onChange(country?.code);
                      }}
                      disabled={isEdit && true}
                      defaultValue={value}
                    />
                  );
                }}
                name="country"
                rules={{
                  required: {
                    value: true,
                    message: "Country is required",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.country && errors.country.message}
            </p>
          </div>
          <div className="w-full">
            <h2 className="text-gray-900 text-xl leading-tight font-medium mb-2">
              Company
            </h2>
            <hr className="pt-5 mt-5" />
          </div>
          <div className="w-full sm:w-1/3 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Name
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                id="client-company-name"
                control={control}
                fieldName="company.name"
                type="text"
                disabled={isEdit && true}
                defaultValue={isEdit ? singleUser?.user?.company?.name : ""}
                placeholder={"Company"}
                rules={{
                  required: {
                    value: true,
                    message: "Field is required",
                  },
                  minLength: {
                    value: 3,
                    message: "The min length of the company name is 3",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors?.company?.name?.message}
            </p>
          </div>
          <div className="w-full sm:w-1/3 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Address
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                id="client-company-address"
                control={control}
                fieldName="company.address"
                type="text"
                disabled={isEdit && true}
                defaultValue={isEdit ? singleUser?.user?.company?.name : ""}
                placeholder={"Company address"}
                rules={{
                  required: {
                    value: true,
                    message: "Field is required",
                  },
                  minLength: {
                    value: 3,
                    message: "The min length of the company address is 3",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors?.company?.address?.message}
            </p>
          </div>
          <div className="w-full sm:w-1/3 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Representative
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                id="representative"
                control={control}
                fieldName="company.representative"
                type="text"
                disabled={isEdit && true}
                defaultValue={
                  isEdit ? singleUser?.user?.company?.representative : ""
                }
                placeholder={"Name"}
                rules={{
                  required: {
                    value: true,
                    message: "Field is required",
                  },
                  minLength: {
                    value: 3,
                    message: "The min length of the representative name is 3",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors?.company?.representative?.message}
            </p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Registration number
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                id="client-company-registration-number"
                control={control}
                fieldName="company.registrationNumber"
                type="text"
                disabled={isEdit && true}
                defaultValue={
                  isEdit ? singleUser?.user?.company?.registrationNumber : ""
                }
                placeholder={"Registration number"}
                rules={{
                  minLength: {
                    value: 3,
                    message:
                      "The min length of the company registration number is 3",
                  },
                  maxLength: {
                    value: 30,
                    message:
                      "The max length of the company registration number is 30",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors?.company?.registrationNumber?.message}
            </p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Taxation number
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                id="client-company-taxation-number"
                control={control}
                fieldName="company.taxationNumber"
                type="text"
                disabled={isEdit && true}
                defaultValue={
                  isEdit ? singleUser?.user?.company?.taxationNumber : ""
                }
                placeholder={"Taxation number"}
                rules={{
                  minLength: {
                    value: 3,
                    message:
                      "The min length of the company taxation number is 3",
                  },
                  maxLength: {
                    value: 30,
                    message:
                      "The max length of the company taxation number is 30",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors?.company?.taxationNumber?.message}
            </p>
          </div>
          <hr className=" w-full pt-5 mt-5" />
          <div className="w-full p-2">
            <MainButton
              action={handleSubmit(isEdit ? submit : onCreateSubmit)}
              title={"Save"}
            />
          </div>{" "}
          <ul className="w-full flex flex-col justify-center items-center">
            {backendErrors()}
          </ul>
        </form>
      </div>

      <div className="py-2 align-middle inline-block min-w-full">
        <div className="shadowBox overflow-hidden bg-white  px-2 sm:px-6 lg:px-8">
          <div className="flex justify-center text-center p-2 font-bold text-xl">
            {" "}
            Models
          </div>
          {singleUser && singleUser.models && singleUser.models.length ? (
            <ModelsList userId={id} modalShow={() => {}} />
          ) : (
            <Empty info="This user has no 3D models" />
          )}
        </div>
        <SuccessModal
          visibility={showSuccessModal}
          setVisibility={setShowSuccessModal}
          title={"The update operation succeeded"}
        />
      </div>
    </div>
  );
};

export default PartnerSingleUser;
