import React, { useEffect, useState } from "react";
import ModelsListMarket from "../../Components/ui/pageElementes/ModelsListMarket";
import { useSelector, useDispatch } from "react-redux";
import { fetchMarketModels } from "../../redux/modules/models/actions";

import SearchField from "../../Components/ui/pageElementes/SearchField";
import FilterListTwoToneIcon from "@mui/icons-material/FilterListTwoTone";
import MarketPlaceLayout from "../../Components/layouts/MarketplaceLayout";
import { on, trigger } from "../../helpers/events";
import { BasicMenu } from "../../Components/ui/BasicMenu/BasicMenu";

const MarketPage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userData);
  const { models } = useSelector((state) => state.modelData);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortValue, setSortValue] = useState("desc");
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSidebar, setShowSidebar] = useState("-left-64");
  const [filters, setFilters] = useState([]);

  React.useEffect(() => {
    on("sidebarFiltersChanged", (event) => {
      setFilters(event.detail);
    });
  }, []);

  useEffect(() => {
    dispatch(
      fetchMarketModels({
        page: 0,
        sortBy: sortBy,
        sortValue: sortValue,
      })
    );
  }, []);

  useEffect(() => {
    if (searchValue !== undefined) {
      trigger("searchValueChanged", searchValue);
    }
  }, [searchValue]);

  const search = (input) => {
    setPage(1);
    setSearchValue(input);
    dispatch(
      fetchMarketModels({
        sortBy: sortBy,
        sortValue: sortValue,
        search: input,
        filters: filters,
        page: 0,
      })
    );
  };

  const handlePage = (page) => {
    setPage(page);
    dispatch(
      fetchMarketModels({
        ...(searchValue ? { search: searchValue } : {}),
        page: page - 1,
        sortBy: sortBy,
        sortValue: sortValue,
      })
    );
  };
  const handleSort = (by, value) => {
    setSortBy(by);
    setSortValue(value);

    dispatch(
      fetchMarketModels({
        ...(searchValue ? { search: searchValue } : {}),
        page: page - 1,
        sortBy: by,
        sortValue: value,
      })
    );
  };

  return (
    <MarketPlaceLayout user={user} showSidebar={showSidebar}>
      <div
        className={`fixed top-0 lg:hidden ${
          showSidebar === "left-0"
            ? "left-64 w-full h-full"
            : "-left-64 bg-white"
        } z-50 transition-all duration-300 bg-gray-200 opacity-20 cursor-pointer`}
        onClick={() => setShowSidebar("-left-64")}
      />
      <div className="w-full flex justify-between items-center flex-col sm:flex-row">
        <div className="w-3/4">
          <SearchField
            showFilterButton={false}
            action={search}
            width="sm:w-3/6"
          />
        </div>
        <div className="w-full sm:w-1/4 mx-4 flex justify-center  sm:justify-end  items-center">
          <div
            onClick={() => setShowSidebar("left-0")}
            className={`${
              showSidebar === "left-0" && "bg-primary text-white"
            } border-2 w-full rounded-full p-2 mx-4 mb-4 flex justify-center cursor-pointer lg:hidden`}
          >
            <FilterListTwoToneIcon />
            Filters
          </div>
          <div className=" rounded-full w-full p-2 mb-4 flex justify-center lg:justify-end cursor-pointer">
            {" "}
            <BasicMenu
              handleSort={handleSort}
              setAnchorEl={setAnchorEl}
              anchorEl={anchorEl}
            />
          </div>
        </div>
      </div>
      <ModelsListMarket
        user={user}
        fetchModels={true}
        isMarket={true}
        page={page}
        handlePage={handlePage}
        handleSort={handleSort}
        models={models}
      />
    </MarketPlaceLayout>
  );
};

export default MarketPage;
