import {
  ADMIN_FETCH_USERS,
  ADMIN_ERROR,
  ADMIN_SUCCESS,
  ADMIN_LOADING,
  ADMIN_FETCH_MODELS,
  ADMIN_FETCH_SINGLE_MODEL,
  ADMIN_CREATE_ARCHITECT,
  ADMIN_FETCH_END_USERS,
  ADMIN_ERROR_CLEAR,
  ADMIN_FETCH_SINGLE_USER,
  ADMIN_FETCH_SINGLE_END_USER,
  ADMIN_FETCH_USERS_SEARCH,
  ADMIN_FETCH_LATEST_USERS,
  DUPLICATE_MODEL,
  FETCH_PARTNERS,
  ADMIN_FETCH_SUPPORT,
  ADMIN_CREATE_CUSTOM_FIELDS,
  ADMIN_DELETE_CUSTOM_FIELDS,
  ADMIN_FETCH_CUSTOM_FIELDS,
  ADMIN_UPDATE_CUSTOM_FIELDS,
  ADMIN_FETCH_CATEGORIES,
  ADMIN_UPDATE_CATEGORIES,
  ADMIN_DELETE_CATEGORIES,
  ADMIN_CREATE_CATEGORIES,
  ADMIN_SELECT_CATEGORY,
  ADMIN_SELECT_CUSTOM_FIELD,
  ADMIN_FETCH_FILTERS,
} from "./constants";
import axios from "axios";
import cookie from "js-cookie";
import clearAllStoreData from "../../resetAction";
export const getCookie = (key) => {
  if (process.browser) {
    return cookie.get(key);
  }
};
export function error(data) {
  return {
    type: ADMIN_ERROR,
    payload: data,
  };
}

export function loading() {
  return {
    type: ADMIN_LOADING,
  };
}

export function success(data) {
  return {
    type: ADMIN_SUCCESS,
    payload: data,
  };
}
export function fetchUsersSuccess(data) {
  return {
    type: ADMIN_FETCH_USERS,
    payload: data,
  };
}
export function fetchLatestUsersSuccess(data) {
  return {
    type: ADMIN_FETCH_LATEST_USERS,
    payload: data,
  };
}
export function fetchUsersSearchSuccess(data) {
  return {
    type: ADMIN_FETCH_USERS_SEARCH,
    payload: data,
  };
}
export function fetchModelsSuccess(data) {
  return {
    type: ADMIN_FETCH_MODELS,
    payload: data,
  };
}
export function fetchSingleModelsSuccess(data) {
  return {
    type: ADMIN_FETCH_SINGLE_MODEL,
    payload: data,
  };
}
export function createdArchitectUser(data) {
  return {
    type: ADMIN_CREATE_ARCHITECT,
    payload: data,
  };
}

export function fetchFilters(data) {
  return {
    type: ADMIN_FETCH_FILTERS,
    payload: data,
  };
}

export function fetchCategories(data) {
  return {
    type: ADMIN_FETCH_CATEGORIES,
    payload: data,
  };
}
export function updateCategories(data) {
  return {
    type: ADMIN_UPDATE_CATEGORIES,
    payload: data,
  };
}

export function deleteCategories(data) {
  return {
    type: ADMIN_DELETE_CATEGORIES,
    payload: data,
  };
}

export function createCategory(data) {
  return {
    type: ADMIN_CREATE_CATEGORIES,
    payload: data,
  };
}

export function selectCategory(data) {
  return {
    type: ADMIN_SELECT_CATEGORY,
    payload: data,
  };
}

export function selectCustomField(data) {
  return {
    type: ADMIN_SELECT_CUSTOM_FIELD,
    payload: data,
  };
}

export function fetchCustomFields(data) {
  return {
    type: ADMIN_FETCH_CUSTOM_FIELDS,
    payload: data,
  };
}

export function updateCustomFields(data) {
  return {
    type: ADMIN_UPDATE_CUSTOM_FIELDS,
    payload: data,
  };
}

export function createCustomFields(data) {
  return {
    type: ADMIN_CREATE_CUSTOM_FIELDS,
    payload: data,
  };
}

export function deleteCustomFields(data) {
  return {
    type: ADMIN_DELETE_CUSTOM_FIELDS,
    payload: data,
  };
}

export function fetchEndUsersSuccess(data) {
  return {
    type: ADMIN_FETCH_END_USERS,
    payload: data,
  };
}
export function fetchSupportSuccess(data) {
  return {
    type: ADMIN_FETCH_SUPPORT,
    payload: data,
  };
}
export function fetchSingleUserSuccess(data) {
  return {
    type: ADMIN_FETCH_SINGLE_USER,
    payload: data,
  };
}
export function fetchSingleEndUserSuccess(data) {
  return {
    type: ADMIN_FETCH_SINGLE_END_USER,
    payload: data,
  };
}
export function duplicateModelSuccess(data) {
  return {
    type: DUPLICATE_MODEL,
    payload: data,
  };
}
export function fetchPartnersSuccess(data) {
  return {
    type: FETCH_PARTNERS,
    payload: data,
  };
}
export function errorClear() {
  return {
    type: ADMIN_ERROR_CLEAR,
  };
}
export function fetchUsers(page, limit) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;

    axios
      .get(`${process.env.REACT_APP_API}/admin/users/a-user${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return dispatch(fetchUsersSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err?.response?.data?.errors));
        }
      });
  };
}
export function fetchSupport(page, limit) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;

    axios
      .get(`${process.env.REACT_APP_API}/admin/users/support${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return dispatch(fetchSupportSuccess(response.data));
      })
      .catch((err) => {
        console.log("test");

        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err?.response?.data?.errors));
        }
      });
  };
}
export function fetchLatestUsers(page, limit) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;

    axios
      .get(`${process.env.REACT_APP_API}/admin/users/a-user${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return dispatch(fetchLatestUsersSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err?.response?.data?.errors));
        }
      });
  };
}

export function fetchEndUsers(page, limit) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;

    axios
      .get(`${process.env.REACT_APP_API}/admin/users/end-user${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return dispatch(fetchEndUsersSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err?.response?.data?.errors));
        }
      });
  };
}
export function searchUsers(search, page, limit) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    axios
      .get(
        `${process.env.REACT_APP_API}/admin/users/a-user/search?page=${page}${
          limit ? "&limit=" + limit : ""
        }&search=${search}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        return dispatch(fetchUsersSearchSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err?.response?.data?.errors));
        }
      });
  };
}
export function searchEndUsers(search, page, limit) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    axios
      .get(
        `${process.env.REACT_APP_API}/admin/users/end-user/search?page=${page}${
          limit ? "&limit=" + limit : ""
        }&search=${search}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        return dispatch(fetchEndUsersSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err?.response?.data?.errors));
        }
      });
  };
}

export const fetchSingleModel = (id) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .get(`${process.env.REACT_APP_API}/admin/model/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(fetchSingleModelsSuccess(response.data));
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err?.response?.data?.errors));
      }
    });
};
export const addArchitectUser = (data, callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");

  axios
    .post(`${process.env.REACT_APP_API}/admin/users/a-user/create`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(createdArchitectUser(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err?.response?.data?.errors));
      }
    });
};

export const getCustomFields = (callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .get(`${process.env.REACT_APP_API}/admin/custom-fields`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(fetchCustomFields(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err?.response?.data?.errors));
      }
    });
};

export const createACustomField = (data, callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .post(`${process.env.REACT_APP_API}/admin/custom-fields`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(createCustomFields(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err?.response?.data?.errors));
      }
    });
};

export const updateACustomField = (id, data, callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .put(`${process.env.REACT_APP_API}/admin/custom-fields/${id}`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(updateCustomFields(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err?.response?.data?.errors));
      }
    });
};

export const deleteACustomField =
  (categoryId, subcategoryId, customFieldId, callback) => async (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    axios
      .delete(
        `${process.env.REACT_APP_API}/admin/custom-fields?categoryId=${categoryId}&subcategoryId=${subcategoryId}&customFieldId=${customFieldId}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(deleteCustomFields(response.data));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err?.response?.data?.errors));
        }
      });
  };

export const fetchSingleUser = (id, callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .get(`${process.env.REACT_APP_API}/admin/users/a-user/single/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(fetchSingleUserSuccess(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err?.response?.data?.errors));
      }
    });
};

export const fetchSingleEndUser = (id) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .get(`${process.env.REACT_APP_API}/admin/users/end-user/single/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(fetchSingleEndUserSuccess(response.data));
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err?.response?.data?.errors));
      }
    });
};
export const singleUserUpdate =
  (id, formData, callback) => async (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");

    axios
      .put(`${process.env.REACT_APP_API}/a-users/details/${id}`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(success("User updated"));
        if (callback) callback();
      })

      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        dispatch(error(err?.response?.data?.errors));
      });
  };
export const updateSupportUser =
  (id, formData, callback) => async (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");

    axios
      .put(
        `${process.env.REACT_APP_API}/admin/users/support/details/${id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(success("User updated"));
        if (callback) callback();
      })

      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        dispatch(error(err?.response?.data?.errors));
      });
  };
export const singleEndUserUpdate =
  (id, formData, callback) => async (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");

    axios
      .put(
        `${process.env.REACT_APP_API}/admin/users/end-user/update/${id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(success("User updated"));
        if (callback) callback();
      })

      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        dispatch(error(err?.response?.data?.errors));
      });
  };
export const removeEndUser = (id, callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .delete(`${process.env.REACT_APP_API}/admin/users/end-user/remove/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(success(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err?.response?.data?.errors));
      }
    });
};
export const removeAUser = (id, callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .delete(`${process.env.REACT_APP_API}/admin/users/a-user/remove/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(success(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err?.response?.data?.errors));
      }
    });
};
export const removeSupport = (id, callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .delete(`${process.env.REACT_APP_API}/admin/users/support/remove/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(success(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err?.response?.data?.errors));
      }
    });
};
export const addPartner = (data, callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");

  axios
    .post(
      `${process.env.REACT_APP_API}/admin/users/partner-user/create`,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      dispatch(success(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        if (err.response && err.response.data)
          dispatch(error(err?.response?.data?.errors));
      }
    });
};
export function assignAUserToPartner(data, callback) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");

    axios
      .post(
        `${process.env.REACT_APP_API}/admin/users/partner-user/assign-aUser`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(success(response.data));

        if (callback) callback(response.data.id);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err?.response?.data?.errors));
        }
      });
  };
}
export const clearError = () => async (dispatch) => {
  dispatch(errorClear());
};

export const addSupportUser = (data, callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .post(`${process.env.REACT_APP_API}/admin/users/support/create`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(success(response.data));
      if (callback) callback(response.data.id);
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err?.response?.data?.errors));
      }
    });
};
