import React from "react";
const CheckBoxFull = () => {
  return (
    <div className="p-4">
      <div className="flex items-center">
        <div className="bg-primary rounded-md border-primary flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-primary">
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4.52173C0 2.31259 1.79086 0.521729 4 0.521729H16C18.2091 0.521729 20 2.31259 20 4.52173V16.5217C20 18.7309 18.2091 20.5217 16 20.5217H4C1.79086 20.5217 0 18.7309 0 16.5217V4.52173Z"
              fill="#45BDF4"
            />
            <mask
              id="mask0_487_26229"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="5"
              width="10"
              height="11"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.6585 7.80076C13.7805 7.9228 13.7805 8.12066 13.6585 8.2427L9.28347 12.6177C9.22487 12.6763 9.14538 12.7092 9.0625 12.7092C8.97962 12.7092 8.90013 12.6763 8.84153 12.6177L6.65403 10.4302C6.53199 10.3082 6.53199 10.1103 6.65403 9.98826C6.77607 9.86622 6.97393 9.86622 7.09597 9.98826L9.0625 11.9548L13.2165 7.80076C13.3386 7.67872 13.5364 7.67872 13.6585 7.80076Z"
                fill="black"
              />
            </mask>
            <g mask="url(#mask0_487_26229)">
              <rect x="5" y="5.52173" width="10" height="10" fill="white" />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CheckBoxFull;
