import React from "react";
import logo from "../../assets/images/Logo.png";

import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import Page from "../../Components/auth/Page";
import Container from "../../Components/auth/Container";

import EndUserSigninForm from "../../Components/ui/forms/EndUserSigninForm";

const SingInEndUserPage = () => {
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.auth);

  React.useEffect(() => {
    const assignmentToken = localStorage.getItem("userAssignmentToken");
    if (!assignmentToken) {
      return;
    }
    if (isAuthenticated && assignmentToken) {
      navigate(`/user-assignment/${assignmentToken}`);
    }
  }, [isAuthenticated]);

  return (
    <Page>
      <Container className="flex items-center">
        <Link to="/">
          <img src={logo} style={{ margin: "0 auto" }} className="logo h-32" />
        </Link>
        <div className="z-10 w-full bg-white sm:mt-5 p-2 sm:p-5 box-shadow-default rounded-lg flex justify-center items-center flex-col">
          <EndUserSigninForm callback={() => navigate("/marketplace")} />
        </div>
        <div className="w-full flex justify-evenly my-2">
          <Link
            className="text-primary font-medium text-center sm:text-right"
            to="/signup-end"
          >
            Don&apos;t have an account?
          </Link>
          <Link
            className="text-primary font-medium text-center sm:text-right"
            to="/login"
          >
            Login as client/partner
          </Link>
        </div>
      </Container>
    </Page>
  );
};

export default SingInEndUserPage;
