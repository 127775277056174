import {
  UPLOAD_MODEL_HAS_ERROR,
  UPLOAD_MODEL_LOADING,
  UPLOAD_MODEL_SUCCESS,
  FETCH_MODELS_LOADING,
  FETCH_MODELS_SUCCESS,
  FETCH_MODELS_HAS_ERROR,
  ASSIGN_MODEL,
  MODELS_ERROR_CLEAR,
  UPDATE_MODEL_SUCCESS,
  UPDATE_MODEL_COVER_SUCCESS,
  FETCH_SINGLE_MODEL_SUCCESS,
  DELETE_MODEL_SUCCESS,
  ADD_MODEL_TO_FAVORITE_ERROR,
  ADD_MODEL_TO_FAVORITE_LOADING,
  FETCH_END_USER_FAVORITES,
  FETCH_END_USER_MODELS,
} from "./constants";
import axios from "axios";
import cookie from "js-cookie";
import clearAllStoreData from "../../resetAction";

export function modelLoadingError(data) {
  return {
    type: UPLOAD_MODEL_HAS_ERROR,
    payload: data,
  };
}

export function modelLoading() {
  return {
    type: UPLOAD_MODEL_LOADING,
  };
}

export function modelLoadingSuccess() {
  return {
    type: UPLOAD_MODEL_SUCCESS,
  };
}
export function modelUpdateSuccess() {
  return {
    type: UPDATE_MODEL_SUCCESS,
  };
}
export function coverImageUpdateSuccess() {
  return {
    type: UPDATE_MODEL_COVER_SUCCESS,
  };
}
export function deleteModelSuccess() {
  return {
    type: DELETE_MODEL_SUCCESS,
  };
}
export function errorClear() {
  return {
    type: MODELS_ERROR_CLEAR,
  };
}

export function addModelToFavoriteLoading() {
  return {
    type: ADD_MODEL_TO_FAVORITE_LOADING,
  };
}

export function addModelToFavoriteError() {
  return {
    type: ADD_MODEL_TO_FAVORITE_ERROR,
  };
}
export function getEndUserFavorites(data) {
  return {
    type: FETCH_END_USER_FAVORITES,
    payload: data,
  };
}

export function fetchModelsError(data) {
  return {
    type: FETCH_MODELS_HAS_ERROR,
    payload: data,
  };
}

export function fetchModelsLoading() {
  return {
    type: FETCH_MODELS_LOADING,
  };
}
export function fetchModelsSuccess(data) {
  return {
    type: FETCH_MODELS_SUCCESS,
    payload: data,
  };
}
export function fetchModelsEndUserSuccess(data) {
  return {
    type: FETCH_END_USER_MODELS,
    payload: data,
  };
}

export function fetchSingleModelSuccess(data) {
  return {
    type: FETCH_SINGLE_MODEL_SUCCESS,
    payload: data,
  };
}

export function assignModelSuccess(data) {
  return {
    type: ASSIGN_MODEL,
    payload: data,
  };
}

export const getCookie = (key) => {
  if (process.browser) {
    return cookie.get(key);
  }
};

export function fetchModelsEndUser(queryParams) {
  const filters = queryParams.filters;
  delete queryParams.filters;

  const query = new URLSearchParams(queryParams);

  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");

    axios
      .post(
        `${process.env.REACT_APP_API}/models3D/end-user/search?${query}`,
        filters,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(fetchModelsEndUserSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function searchModels(queryParams) {
  const filters = queryParams.filters;
  delete queryParams.filters;

  const query = new URLSearchParams(queryParams);

  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");

    axios
      .post(
        `${process.env.REACT_APP_API}/models3D/a-user/search?${query}`,
        filters,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(fetchModelsSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function fetchSingleModel(id) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");
    axios
      .get(`${process.env.REACT_APP_API}/models3D/single/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(fetchSingleModelSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function fetchSingleModelMarketPlace(id) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    axios
      .get(`${process.env.REACT_APP_API}/models3D/marketplace/${id}`, {})
      .then((response) => {
        dispatch(fetchSingleModelSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function assignModelToEndUsers(data, callback) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");
    axios
      .post(`${process.env.REACT_APP_API}/end-users/assign-model`, data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(assignModelSuccess(response.data));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function assignModelToUsers(data, callback) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");
    axios
      .post(`${process.env.REACT_APP_API}/a-users/assign-model`, data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(assignModelSuccess(response.data));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function assignModelsToSingleUser(data, callback) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");
    axios
      .post(`${process.env.REACT_APP_API}/admin/assign-models-to-user`, data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(assignModelSuccess(response.data));

        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}

export function fetchMarketModels(queryParams) {
  const filters = queryParams.filters;
  delete queryParams.filters;

  const query = new URLSearchParams(queryParams);

  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");
    axios
      .post(
        `${process.env.REACT_APP_API}/models3D/marketplace?${query}`,
        filters,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(fetchModelsSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function fetchFavorites(queryParams) {
  const filters = queryParams.filters;
  delete queryParams.filters;

  const query = new URLSearchParams(queryParams);

  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");

    axios
      .post(
        `${process.env.REACT_APP_API}/end-users/favorites?${query}`,
        filters,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getEndUserFavorites(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}

export const clearError = () => async (dispatch) => {
  dispatch(errorClear());
};
