import React, { useState, useEffect } from "react";
import FaceIcon from "@mui/icons-material/Face";
import {
  fetchSingleUser,
  fetchUsers,
  removeSupport,
  updateSupportUser,
  addSupportUser,
  fetchSupport,
} from "../../redux/modules/admin/actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import { Controller, useForm } from "react-hook-form";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConfirmModal from "../../Components/ui/modals/ConfirmModal";
import SuccessModal from "../../Components/ui/modals/SuccessModal";
import CountriesAutocomplete from "../../Components/ui/forms/CountriesAutocomplete";
import { useModalContext } from "../../contexts/SuccessModalContext";
const ViewSupportUserDetails = (props) => {
  const { id } = useParams();
  const isEdit = id !== undefined;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { singleUser, error } = useSelector((state) => state.adminData);
  const [removeModal, setRemoveModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { setOpenState } = useModalContext();
  const [isActive, setIsActive] = useState(true);
  const [values, setValues] = useState({
    name: "",
    phone: "",
    country: "",
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    isEdit && dispatch(fetchSingleUser(id));
  }, []);

  useEffect(() => {
    setValues({
      name: singleUser?.user?.name,
      phone: singleUser?.user?.phone,
      country: singleUser?.user?.country,
    });
    setIsActive(singleUser?.user?.status);

    isEdit ? reset(singleUser?.user) : reset();
  }, [singleUser]);
  const submit = (data) => {
    dispatch(updateSupportUser(id, { ...data, status: isActive }));
    reset(singleUser.user);
    navigate("/support");
  };
  const addSupportCallback = () => {
    dispatch(fetchSupport());
    setOpenState(true);
    navigate("/support");
  };
  const onCreateSubmit = (data) => {
    dispatch(addSupportUser(data, addSupportCallback));
  };

  const callbackRemove = () => {
    dispatch(fetchUsers());
    navigate("/support");
  };
  const removeSubmit = () => {
    dispatch(removeSupport(id, callbackRemove));
  };
  const onToggleChange = () => {
    setIsActive(!isActive);
  };

  function validatePhone(phone) {
    return !singleUser?.users?.some((user) => user.phone === phone);
  }

  function validateEmail(email) {
    return !singleUser?.users?.some((user) => user.email === email);
  }
  const backendErrors = () => {
    return error?.map((e) => (
      <li className="text-xs text-red-500 " key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };
  return (
    <div className="w-full h-full flex flex-col justify-around">
      {isEdit && (
        <PageHeader
          backLink={"/support"}
          Profile={
            singleUser?.user?.profilePicture ? (
              <img
                src={`${process.env.REACT_APP_API}${singleUser.user?.profilePicture.usePath}/${singleUser.user?.profilePicture.filename}`}
                alt={singleUser.user.name}
                className="w-full object-cover h-full"
              />
            ) : (
              <FaceIcon className="text-white " />
            )
          }
          buttonIcon={<DeleteForeverIcon />}
          buttonTitle={"Remove this support"}
          action={() => setRemoveModal(true)}
          buttonSecondary={true}
          title={
            singleUser?.user?.name
              ? singleUser?.user?.name
              : singleUser?.user?.email
          }
          toggleChecked={isActive}
          onToggleChange={(e) => onToggleChange(e)}
        />
      )}
      <div className="py-2 align-middle inline-block min-w-full w-full">
        <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Email
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="support-email"
                  control={control}
                  fieldName="email"
                  disabled={isEdit && true}
                  type="email"
                  defaultValue={isEdit ? singleUser?.user?.email : ""}
                  placeholder={"Email"}
                  rules={{
                    pattern: {
                      value:
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                      message: "Please use a valid email address",
                    },
                    required: {
                      value: true,
                      message: "Please add the users email",
                    },
                    validate: {
                      checkEmail: (v) =>
                        validateEmail(v) || "Email already used",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors?.email?.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Name
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="support-name"
                  control={control}
                  fieldName="name"
                  type="text"
                  defaultValue={isEdit ? singleUser?.user?.name : ""}
                  placeholder={"Name"}
                  rules={{
                    minLength: {
                      value: 4,
                      message: "The min length of the name is 4",
                    },
                    required: {
                      value: true,
                      message: "Name is required",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.name && errors.name.message}
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Country
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-1 px-7  border border-fade rounded-4xl my-4`}
            >
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <CountriesAutocomplete
                      onChange={(country) => {
                        onChange(country?.code);
                        setValues({ ...values, country: country?.code });
                      }}
                      defaultValue={value}
                    />
                  );
                }}
                name="country"
                rules={{
                  required: {
                    value: true,
                    message: "Country is required",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.country && errors.country.message}
            </p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Phone
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                id="support-phone"
                control={control}
                fieldName="phone"
                type="text"
                defaultValue={isEdit ? singleUser?.user?.phone : ""}
                placeholder={"+CC XXX XXX XXX"}
                rules={{
                  pattern: {
                    value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                    message: "Please use a valid phone number",
                  },
                  required: {
                    value: true,
                    message: "Please add the users phone number",
                  },
                  validate: {
                    checkPhone: (v) =>
                      validatePhone(v) || "Phone number already used",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.phone && errors.phone.message}
            </p>
          </div>
          <div className="w-full p-2">
            <MainButton
              action={handleSubmit(isEdit ? submit : onCreateSubmit)}
              title={isEdit ? "Save" : "Add support"}
            />
          </div>{" "}
          <ul className="w-full flex flex-col justify-center items-center">
            {backendErrors()}
          </ul>
        </form>
      </div>
      <ConfirmModal
        visibility={removeModal}
        setVisibility={setRemoveModal}
        submitAction={removeSubmit}
        title="Are you sure you want to remove this user?"
        description={`This action is irreversible.`}
        buttonText="Remove support"
      />
      <SuccessModal
        visibility={showSuccessModal}
        setVisibility={setShowSuccessModal}
        title={"The update operation succeeded"}
      />
    </div>
  );
};

export default ViewSupportUserDetails;
