import moment from "moment";
import FaceIcon from "@mui/icons-material/Face";

export const ADMIN_ASSIGN_END_USERS_TABLE_COLUMNS = [
  {
    field: "profilePicture",
    renderCell: (params) => (
      <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full overflow-hidden">
        {params.row.profilePicture ? (
          <img
            src={`${process.env.REACT_APP_API}${params.row.profilePicture.usePath}/${params.row.profilePicture.filename}`}
            alt={params.row.name}
            className="object-cover w-full h-full"
          />
        ) : (
          <FaceIcon className="text-white " />
        )}
      </div>
    ),
    headerName: "",
    maxWidth: 50,
  },
  {
    field: "name",
    valueGetter: (params) =>
      `${params.row.name} ${params.row.phone} ${params.row.email}`,
    renderCell: (params) => {
      return (
        <div>
          <p>
            <b>{params.row.name}</b>
          </p>
          <p>{params.row.phone}</p>
          <p>{params.row.email}</p>
        </div>
      );
    },
    headerName: "Name, Phone, Email",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "createdAt",
    valueGetter: (params) => {
      return moment(params.row.createdAt).format("ll");
    },
    headerName: "Join Date",
    flex: 1,
  },
  {
    field: "assignedModels",
    valueGetter: (params) => {
      return params.row.models3D.length;
    },
    headerName: "Assigned Models",
    minWidth: 100,
    flex: 1,
  },
];
