import { request } from "../api.service";

export const getModelsRequest = () => {
  return request("/admin/models/all", "get");
};

export const assignModelsToUserRequest = (data) => {
  return request("/admin/assign-models-to-user", "post", data);
};

export const getSingleModelRequest = (id) => {
  return request(`/models3D/single/${id}`, "get");
};

export const updateModelRequest = (id, data) => {
  return request(`/models3D/${id}`, "put", data);
};

export const uploadFinalModelRequest = (id, data) => {
  return request(`/admin/model/ar-model/${id}`, "put", data);
};

export const createModelRequest = (data) => {
  return request(`/models3D`, "post", data);
};

export const deleteModelRequest = (id) => {
  return request(`/models3D/${id}`, "delete");
};

export const updateModelCoverImageRequest = (id, data) => {
  return request(`/models3D/cover-image/${id}`, "put", data);
};

export const duplicateModelRequest = (id, data) => {
  return request(`/admin/models/duplicate/${id}`, "post", data);
};

export const getMarketModelsRequest = (queryParams, data) => {
  const query = new URLSearchParams(queryParams);
  return request(`/models3D/marketplace?${query}`, "post", data);
};

export const getAdminModelsListRequest = (queryParams, data) => {
  const query = new URLSearchParams(queryParams);
  return request(`admin/models/search?${query}`, "post", data);
};

export const downloadModelRequest = (id) => {
  return request(`/admin/model/download/${id}`, "get", null, null, {
    responseType: "blob",
  });
};
