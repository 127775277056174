import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BackButton from "../buttons/BackButton";
import MainButton from "../buttons/MainButton";
import SecondaryButton from "../buttons/SecondaryButton";
import { ToggleSwitch } from "./ToggleSwitch";
import SubscriptionPlansModal from "../../stripe/SubscriptionPlansModal";
import axios from "axios";
import { MISSING_ACTIVE_SUBSCRIPTION } from "../../../constants/globals";
import { assignSubscriptionRequest } from "../../../api/modules/subscriptions";
import { fetchUserData } from "../../../redux/modules/user/actions";
import { useHttp } from "../../../hooks/useHttp";

const PageHeader = ({
  Icon,
  Profile,
  link,
  buttonTitle,
  action,
  title,
  buttonSecondary,
  buttonIcon,
  iconColor,
  subtitle,
  toggleChecked,
  onToggleChange,
  onIsMandatoryToggleChange,
  isMandatoryToggleChecked,
  isMandatoryToggleLabel,
  backLink,
  actionIsLoading,
  toggleLabel,
  children,
}) => {
  const location = useLocation().pathname;
  const state = useSelector((state) => state.auth);
  const currentUserRole = state.user.role;
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const { isLoading, http } = useHttp();
  const dispatch = useDispatch();

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async (error) => {
      const errRes = error?.response;

      const isMissingActiveSubscription =
        errRes?.status === 403 &&
        errRes?.data.code === MISSING_ACTIVE_SUBSCRIPTION;

      if (isMissingActiveSubscription) {
        setShowSubscriptionModal(true);
      }
      return Promise.reject(error);
    }
  );

  // TODO: move these subscription handlers to a global place and reuse them
  const onSubscriptionCreate = async (
    subscriptionPlan,
    interval,
    initPaymentCallback
  ) => {
    const stripePriceID = subscriptionPlan[interval].stripePriceID;
    const subscriptionPlanId = subscriptionPlan.id;

    const res = await http(() =>
      assignSubscriptionRequest({ subscriptionPlanId, stripePriceID })
    );

    if (res?.latest_invoice?.payment_intent) {
      initPaymentCallback(res?.latest_invoice);
    } else {
      setShowSubscriptionModal(false);
      dispatch(fetchUserData());
    }

    // TODO: handle backend errors
  };

  return (
    <>
      {showSubscriptionModal && (
        <SubscriptionPlansModal
          onSubscriptionCreate={onSubscriptionCreate}
          title={"Choose subscription"}
          isLoading={isLoading}
          onClose={() => {
            setShowSubscriptionModal(false);
          }}
        />
      )}

      {backLink && <BackButton link={backLink} />}
      <div className="shadowBox my-4 flex px-6 items-center justify-between border-white border-2 w-full rounded-lg sm:p-5 ">
        <div
          className={
            "flex justify-center sm:justify-around items-center m-2 flex-col sm:flex-row"
          }
        >
          <span
            className={`text-lg sm:text-5xl ${iconColor || "bg-primary"} ${
              !Profile && "p-2 sm:p-5"
            } h-12 w-12 flex justify-center items-center text-white rounded-full overflow-hidden`}
          >
            {Profile || <Icon />}
          </span>

          <div className={"m-2 flex items-start flex-col"}>
            <div
              className={
                "text-textTitle text-xs sm:text-xl font-bold text-center"
              }
            >
              {title ||
                (location === "/"
                  ? "DASHBOARD"
                  : location
                      .toLocaleUpperCase()
                      .replace("/", "")
                      .replace("-", " "))}
            </div>
            <p className="text-xs text-gray-500">{subtitle}</p>
          </div>
        </div>

        {onToggleChange && (
          <ToggleSwitch
            label={toggleLabel}
            checked={toggleChecked}
            onChange={onToggleChange}
          />
        )}
        {onIsMandatoryToggleChange && (
          <ToggleSwitch
            label={isMandatoryToggleLabel}
            checked={isMandatoryToggleChecked}
            onChange={onIsMandatoryToggleChange}
          />
        )}

        {children}

        <div className={"profileHeader-menuButtonContainer"}>
          {buttonTitle &&
            (!buttonSecondary ? (
              <MainButton
                isLoading={actionIsLoading}
                link={link}
                action={action}
                title={buttonTitle}
                Icon={buttonIcon}
              />
            ) : (
              currentUserRole === "Admin" && (
                <SecondaryButton
                  link={link}
                  action={action}
                  title={buttonTitle}
                  Icon={buttonIcon}
                />
              )
            ))}
        </div>
      </div>
    </>
  );
};

export default PageHeader;
