import { combineReducers } from "redux";
import modelData, {
  initialState as modelState,
} from "./modules/models/reducer";
import clientsData, {
  initialState as clientsState,
} from "./modules/clients/reducer";
import auth, { initialState as authState } from "./modules/auth/reducer";
import analyticsData, {
  initialState as analyticsState,
} from "./modules/analytics/reducer";
import adminData, { initialState as adminState } from "./modules/admin/reducer";
import userData, { initialState as userState } from "./modules/user/reducer";
import partnersData, {
  initialState as partnersState,
} from "./modules/partners/reducer";
import loginEndUser, {
  initialState as loginEndUserState,
} from "./modules/endUserLogin/reducer";

const combinedReducers = combineReducers({
  auth,
  userData,
  modelData,
  clientsData,
  analyticsData,
  adminData,
  loginEndUser,
  partnersData,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case "CLEAR_ALL_REDUCERS_DATA":
      return {
        auth: { ...authState },
        adminData: { ...adminState },
        userData: { ...userState },
        modelData: { ...modelState },
        clientsData: { ...clientsState },
        analyticsData: { ...analyticsState },
        loginEndUser: { ...loginEndUserState },
        partnersData: { ...partnersState },
      };
    default:
      return combinedReducers(state, action);
  }
};

export default rootReducer;
