import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import { useForm } from "react-hook-form";

import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { useHttp } from "../../hooks/useHttp";
import {
  createModelCustomSizeRequest,
  getModelCustomSizeRequest,
  updateModelCustomSizeRequest,
} from "../../api/modules/modifiedModels";
import LoadingIcon from "../../Components/ui/icons/LoadingIcon";
import { useModalContext } from "../../contexts/SuccessModalContext";
const SingleModifiedModels = (props) => {
  const { id } = useParams();
  const { http, isLoading, backendErrors } = useHttp();
  const isEdit = id !== undefined;
  const [modifiedModel, setModifiedModel] = useState();
  const { setOpenState } = useModalContext();
  const navigate = useNavigate();

  const getModifiedModel = () => {
    http(() => getModelCustomSizeRequest(id)).then((res) => {
      return setModifiedModel(res);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    isEdit && getModifiedModel();
  }, []);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    isEdit ? reset(modifiedModel) : reset();
  }, [modifiedModel]);

  const formatData = (data) => {
    return {
      width: data.width,
      height: data.height,
    };
  };

  const submit = (data) => {
    http(async () => {
      await updateModelCustomSizeRequest(id, formatData(data)).then(
        setOpenState(true),
        navigate("/modified-models")
      );
    });
  };

  const onCreateSubmit = (data) => {
    http(() => createModelCustomSizeRequest(data));
  };

  const showBackendErrors = () => {
    return backendErrors?.map((e) => (
      <li className="text-xs text-red-500 " key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };
  if (isLoading) {
    return <LoadingIcon />;
  }
  return (
    <div className="w-full h-full flex flex-col justify-around">
      {isEdit && (
        <PageHeader
          Icon={ChangeCircleIcon}
          buttonSecondary={true}
          title={modifiedModel?.model.name}
          backLink="/modified-models"
        />
      )}
      <div className="w-full h-full flex flex-col justify-around">
        <div className="py-2 align-middle inline-block min-w-full w-full">
          <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
            <div className="w-full">
              <h2 className="text-gray-900 text-xl leading-tight font-medium mb-2">
                End User
              </h2>
              <hr className="pt-5 mt-5" />
            </div>
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/3 px-2">
                <h3 className="text-lg flex font-medium leading-6 text-gray-900">
                  Name
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    id="endUserName"
                    control={control}
                    fieldName="endUser.name"
                    disabled={true}
                    type="string"
                    placeholder={"Name"}
                  />
                </div>
              </div>
              <div className="w-full sm:w-1/3 px-2">
                <h3 className="text-lg flex font-medium leading-6 text-gray-900">
                  Email
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    id="endUserEmail"
                    control={control}
                    fieldName="endUser.email"
                    disabled={true}
                    type="string"
                    placeholder={"Email"}
                  />
                </div>
              </div>
              <div className="w-full sm:w-1/3 px-2">
                <h3 className="text-lg flex font-medium leading-6 text-gray-900">
                  Phone
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    id="endUserPhone"
                    control={control}
                    fieldName="endUser.phone"
                    disabled={true}
                    type="string"
                    placeholder={"Phone"}
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <h2 className="text-gray-900 text-xl leading-tight font-medium mb-2">
                Model
              </h2>
              <hr className="pt-5 mt-5" />
            </div>
            <div className="w-full sm:w-1/3 px-2">
              <h3 className="text-lg flex font-medium leading-6 text-gray-900">
                Name
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="modelName"
                  control={control}
                  fieldName="model.name"
                  disabled={true}
                  type="string"
                  placeholder={"Name"}
                />
              </div>
            </div>
            <div className="w-full sm:w-1/3 px-2">
              <h3 className="text-lg flex font-medium leading-6 text-gray-900">
                Width <span className=" pl-1   text-gray-400"> (in cm)</span>
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="width"
                  control={control}
                  fieldName="width"
                  type="number"
                  placeholder={"Width"}
                  min="0"
                  rules={{
                    required: {
                      value: true,
                      message: "Width is required",
                    },
                    min: { value: 0, message: "Min width is 0" },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.width && errors.width.message}
              </p>
            </div>
            <div className="w-full sm:w-1/3 px-2">
              <div className="flex flex-row">
                <h3 className="text-lg flex font-medium leading-6 text-gray-900">
                  Height <span className=" pl-1   text-gray-400"> (in cm)</span>
                </h3>
              </div>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="height"
                  control={control}
                  fieldName="height"
                  type="number"
                  placeholder={"Name"}
                  rules={{
                    required: {
                      value: true,
                      message: "Height is required",
                    },
                    min: { value: 0, message: "Min width is 0" },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.height && errors?.height.message}
              </p>
            </div>
            {}
            <div className="w-full p-2">
              <MainButton
                action={handleSubmit(isEdit ? submit : onCreateSubmit)}
                title={isEdit ? "Save" : "Modify Model"}
              />
            </div>{" "}
            <ul>{showBackendErrors()}</ul>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SingleModifiedModels;
