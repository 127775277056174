import {
  AUTH_SIGN_IN,
  AUTH_LOADING,
  AUTH_ERROR,
  AUTH_SIGN_OUT,
  AUTH_SIGN_UP,
  AUTH_FORGOT_PASSWORD,
  AUTH_RESET_PASSWORD,
  AUTH_PROFILE_PICTURE,
  AUTH_PROFILE_DETAILS,
  AUTH_CHANGE_PASSWORD,
  AUTH_ERROR_CLEAR,
  END_USER_SIGN_OUT,
} from "./constants";

export const initialState = {
  user: {},
  isAuthenticated: false,
  loading: false,
  error: [],
  success: "",
  forgotPassword: false,
};

const authError = (state, action) => {
  const newState = { ...state };

  newState.loading = false;
  newState.success = "";
  newState.error = action.payload;

  return newState;
};

const authLoading = (state, action) => {
  const newState = { ...state };

  newState.loading = true;
  newState.error = [];
  newState.success = "";
  return newState;
};

function signin(state, action) {
  const newState = { ...state };

  newState.user = action.payload;
  newState.loading = false;
  newState.isAuthenticated = true;
  newState.error = [];

  return newState;
}
function signup(state, action) {
  const newState = { ...state };

  newState.user = action.payload;
  newState.loading = false;
  newState.isAuthenticated = true;
  newState.error = [];
  return newState;
}
function forgotPassword(state, action) {
  const newState = { ...state };

  newState.forgotPassword = true;
  newState.error = [];
  return newState;
}
function resetPassword(state, action) {
  const newState = { ...state };

  newState.forgotPassword = false;
  newState.error = [];
  return newState;
}
function changePassword(state) {
  const newState = { ...state };

  newState.loding = false;
  newState.error = [];
  newState.success = "Your password has been changed";
  return newState;
}
function signout(state, action) {
  const newState = { ...state };

  newState.user = {};
  newState.loading = false;
  newState.isAuthenticated = false;
  newState.error = [];
  newState.success = "";
  return newState;
}

function profilePicture(state, action) {
  const newState = { ...state, action };
  newState.user = action.payload;
  newState.loading = false;
  newState.error = [];
  return newState;
}
function profileDetails(state, action) {
  const newState = { ...state, action };
  newState.user = action.payload;
  newState.loading = false;
  newState.error = [];
  return newState;
}

function errorClear(state) {
  const newState = { ...state };

  newState.error = [];
  return newState;
}
export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_SIGN_IN:
      return signin(state, action);
    case AUTH_SIGN_UP:
      return signup(state, action);
    case AUTH_LOADING:
      return authLoading(state, action);
    case AUTH_ERROR:
      return authError(state, action);

    case AUTH_SIGN_OUT:
      return signout(state);
    case END_USER_SIGN_OUT:
      return signout(state);
    case AUTH_FORGOT_PASSWORD:
      return forgotPassword(state, action);
    case AUTH_RESET_PASSWORD:
      return resetPassword(state, action);
    case AUTH_PROFILE_PICTURE:
      return profilePicture(state, action);
    case AUTH_PROFILE_DETAILS:
      return profileDetails(state, action);
    case AUTH_CHANGE_PASSWORD:
      return changePassword(state, action);
    case AUTH_ERROR_CLEAR:
      return errorClear(state, action);
    default:
      return state;
  }
}
