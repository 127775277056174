import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetSignUpData } from "../../redux/modules/user/actions";
import Page from "../../Components/auth/Page";
import Container from "../../Components/auth/Container";
import logo from "../../assets/images/Logo.png";
import GettingStartedForm from "../../Components/ui/forms/GettingStartedForm";
const SignUpEndUserPage = () => {
  const navigate = useNavigate();

  const { endUserSaved } = useSelector((state) => state.userData);
  const signupTitle = "Sign up as End User";

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (endUserSaved) {
      dispatch(resetSignUpData());
      if (urlParams.get("redirect-url")) {
        // mobile app signup redirect
        console.log("redirect-url", urlParams.get("redirect-url"));
        // window.location.href = urlParams.get("redirect-url");
        window.open(urlParams.get("redirect-url"), "_blank");
      } else {
        navigate("/signin");
      }
    }
  }, [endUserSaved]);

  const dispatch = useDispatch();

  return (
    <Page>
      <Container className="flex items-center">
        <Link to="/">
          <img src={logo} style={{ margin: "0 auto" }} className="logo h-32" />
        </Link>
        <div className="z-10 w-full bg-white sm:mt-5 p-2 sm:p-5 box-shadow-default rounded-lg flex justify-center items-center flex-col">
          <GettingStartedForm title={signupTitle} />
        </div>
        <div className="w-full flex justify-evenly my-2">
          <Link
            className="text-primary font-medium text-center sm:text-right"
            to="/signin"
          >
            Already have an account?
          </Link>
          <Link
            className="text-primary font-medium text-center sm:text-right"
            to="/login"
          >
            Login as client/partner
          </Link>
        </div>
      </Container>
    </Page>
  );
};

export default SignUpEndUserPage;
