import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchModelsEndUser } from "../../redux/modules/models/actions";
import SearchField from "../../Components/ui/pageElementes/SearchField";
import ModelsListMarket from "../../Components/ui/pageElementes/ModelsListMarket";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import { HomeOutlined } from "@mui/icons-material";
import { BasicMenu } from "../../Components/ui/BasicMenu/BasicMenu";

const MyModelsEndUser = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortValue, setSortValue] = useState("desc");
  const [anchorEl, setAnchorEl] = useState(null);
  const { models } = useSelector((state) => state.modelData);
  const { user } = useSelector((state) => state.userData);

  useEffect(() => {
    dispatch(
      fetchModelsEndUser({
        page: 0,
        sortBy: sortBy,
        sortValue: sortValue,
      })
    );
  }, []);

  const search = (input, filters) => {
    setPage(1);
    setSearchValue(input);

    dispatch(
      fetchModelsEndUser({
        sortBy: sortBy,
        sortValue: sortValue,
        ...(input ? { search: input } : {}),
        filters: filters,
        page: 0,
      })
    );
  };

  const handlePage = (page) => {
    setPage(page);
    dispatch(
      fetchModelsEndUser({
        ...(searchValue ? { search: searchValue } : {}),
        page: page - 1,
        sortBy: sortBy,
        sortValue: sortValue,
      })
    );
  };

  const handleSort = (by, value) => {
    setSortBy(by);
    setSortValue(value);

    dispatch(
      fetchModelsEndUser({
        ...(searchValue ? { search: searchValue } : {}),
        page: page - 1,
        sortBy: by,
        sortValue: value,
      })
    );
  };
  return (
    <div className="w-full flex justify-center  items-center flex-col">
      <PageHeader Icon={HomeOutlined} title="Assigned models" />
      <div className="w-4/5 flex items-center flex-col">
        <div className="w-full flex justify-between items-center">
          <div className="w-full">
            <SearchField
              showFilterButton={false}
              action={search}
              width="sm:w-3/6"
            />
          </div>
          <div className="w-1/4 mx-4 flex justify-end">
            <BasicMenu
              handleSort={handleSort}
              setAnchorEl={setAnchorEl}
              anchorEl={anchorEl}
            />
          </div>
        </div>
        {models?.models?.length > 0 ? (
          <ModelsListMarket
            user={user}
            fetchModels={true}
            isMarket={true}
            page={page}
            search={search}
            handlePage={handlePage}
            handleSort={handleSort}
            models={models}
          />
        ) : (
          <b className="py-20">No models assigned yet</b>
        )}
      </div>
    </div>
  );
};

export default MyModelsEndUser;
