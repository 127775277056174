import {
  USER_DATA,
  USER_DATA_LOADING,
  USER_DATA_ERROR,
  USER_DATA_ERROR_CLEAR,
  USER_DATA_PROFILE_PICTURE,
  SIGN_UP_END_USER,
  SIGN_UP_END_USER_ERROR,
  SIGN_UP_END_USER_LOADING,
  RESET_SIGIN_UPDATA,
  // REMOVE_MODEL_TO_FAVORITE_SUCCESS,
  ADD_MODEL_TO_FAVORITE_SUCCESS,
  NOT_AUTH_USER_FAVORITE,
} from "./constants";
import axios from "axios";
import Cookies from "js-cookie";
import clearAllStoreData from "../../resetAction";

export function resetSignUpData() {
  return {
    type: RESET_SIGIN_UPDATA,
  };
}

export function singUpEndUserSuccess(data) {
  return {
    type: SIGN_UP_END_USER,
    payload: data,
  };
}

export function singUpEndUserError(data) {
  return {
    type: SIGN_UP_END_USER_ERROR,
    payload: data,
  };
}
export function singUpEndUserLoading(data) {
  return {
    type: SIGN_UP_END_USER_LOADING,
    payload: data,
  };
}

export function profilePictureSuccess(data) {
  return {
    type: USER_DATA_PROFILE_PICTURE,
    payload: data,
  };
}

export function userLoading() {
  return {
    type: USER_DATA_LOADING,
  };
}
export function userError(data) {
  return {
    type: USER_DATA_ERROR,
    payload: data,
  };
}

export function updateUserDataSuccess(data) {
  return {
    type: USER_DATA,
    payload: data,
  };
}
export function errorClear() {
  return {
    type: USER_DATA_ERROR_CLEAR,
  };
}

export const getCookie = (key) => {
  if (process.browser) {
    return Cookies.get(key);
  }
};
export function addModelToFavoriteSuccess(data) {
  return {
    type: ADD_MODEL_TO_FAVORITE_SUCCESS,
    payload: data,
  };
}
export function notAuthFavoriteSuccess(data) {
  return {
    type: NOT_AUTH_USER_FAVORITE,
    payload: data,
  };
}
export function fetchUserData() {
  return (dispatch) => {
    dispatch(userLoading());
    const token = getCookie("uac");

    axios
      .get(`${process.env.REACT_APP_API}/a-users/user-data`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(updateUserDataSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        if (err.response && err.response.data)
          dispatch(userError(err.response.data.errors));
      });
  };
}
export function updateEndUserData() {
  return (dispatch) => {
    dispatch(userLoading());
    const token = getCookie("uac");

    axios
      .get(`${process.env.REACT_APP_API}/end-users/user-data`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(updateUserDataSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        if (err.response && err.response.data)
          dispatch(userError(err.response.data.errors));
      });
  };
}

export const profilePictureUpload =
  (id, formData, callback) => async (dispatch) => {
    dispatch(userLoading());
    const token = getCookie("uac");

    axios
      .put(
        `${process.env.REACT_APP_API}/a-users/profilePicture/${id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(profilePictureSuccess(response.data));
        if (callback) callback();
      })

      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        if (err.response && err.response.data)
          dispatch(userError(err.response.data.errors));
      });
  };
export const profilePictureUploadEndUser =
  (formData, callback) => async (dispatch) => {
    dispatch(userLoading());
    const token = getCookie("uac");

    axios
      .put(`${process.env.REACT_APP_API}/end-users/profilePicture`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(profilePictureSuccess(response.data));
        if (callback) callback();
      })

      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        if (err.response && err.response.data)
          dispatch(userError(err.response.data.errors));
      });
  };
export const profileDatilsEndUserUpdate =
  (formData, callback) => async (dispatch) => {
    dispatch(userLoading());
    const token = getCookie("uac");

    axios
      .put(`${process.env.REACT_APP_API}/end-users/details`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(updateUserDataSuccess(response.data));
        if (callback) callback();
      })

      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        if (err.response && err.response.data)
          dispatch(userError(err.response.data.errors));
      });
  };
export const clearError = () => async (dispatch) => {
  dispatch(errorClear());
};

export function singUpEndUser({ email, name, phone, country }, callback) {
  return (dispatch) => {
    dispatch(singUpEndUserLoading());

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/end-users/signup`,
      data: {
        email,
        name,
        phone,
        country,
      },
    })
      .then((response) => {
        dispatch(singUpEndUserSuccess(response.data));
        if (callback) {
          callback(response.data);
        }
      })
      .catch((e) => {
        dispatch(singUpEndUserError(e?.response?.data?.errors));
      });
  };
}
export function addModelToFavorite(id, callback) {
  console.log(id);
  return (dispatch) => {
    dispatch(userLoading());
    const token = getCookie("uac");
    axios
      .post(
        `${process.env.REACT_APP_API}/end-users/favorites/${id}`,
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(addModelToFavoriteSuccess(response.data));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(userError(err.response.data.errors));
        }
      });
  };
}
export function removeModelToFavorite(id, callback) {
  return (dispatch) => {
    dispatch(userLoading());
    const token = getCookie("uac");
    axios
      .patch(
        `${process.env.REACT_APP_API}/end-users/favorites/${id}`,
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(addModelToFavoriteSuccess(response.data));

        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(userError(err.response.data.errors));
        }
      });
  };
}
export const savaFavoriteNotAuth = (data) => async (dispatch) => {
  dispatch(notAuthFavoriteSuccess(data));
};
