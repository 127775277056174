import React from "react";
import { Navigate } from "react-router-dom";
import MyAccountEndUserPage from "../../pages/myAcountEndUserPage";
import { useSelector } from "react-redux";
import ProfilePage from "../../pages/profile";
import SidebarDashboard from "../ui/nav/SidebarDashboard";
import Footer from "../ui/nav/Footer";

export default function UserAssignmentHandling() {
  const state = useSelector((state) => state.auth);
  const userRole = state.user.role;

  if (userRole && userRole !== "userA") {
    window.location.href = "/my-account";
    return;
  }

  const pathId = window.location.pathname.split("/").reverse()[0];
  if (pathId.length) {
    window.localStorage.setItem("userAssignmentToken", pathId);
  }

  if (!state.isAuthenticated) {
    return <Navigate to="/signup-end" />;
  } else {
    return !userRole ? (
      <MyAccountEndUserPage />
    ) : (
      <div className="p-2">
        <SidebarDashboard />
        <div className="lg:ml-64 ">
          <div className="flex flex-col xl:flex-row w-auto">
            <ProfilePage />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
