import { request } from "../api.service";

export const getClientsRequest = () => {
  return request("admin/users/a-user", "get");
};

export const getActiveClientsRequest = () => {
  return request("admin/users/a-user?status=true", "get");
};

export const deleteClientRequest = (id) => {
  return request(`admin/users/a-user/remove/${id}`, "delete");
};

export const assignEndUsersToClientRequest = (data) => {
  return request("/admin/users/a-user/assign-endUsers", "post", data);
};

export const assignPartnerToClientRequest = (data) => {
  return request("admin/users/a-user/assign-partner", "post", data);
};
