import React from "react";
import SecondaryButton from "./SecondaryButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const BackButton = ({ link }) => {
  return (
    <div className="w-4/5 flex justify-start">
      <SecondaryButton
        title="Back"
        width={"w-full"}
        color="text-primary"
        Icon={<ArrowBackIcon />}
        link={link}
      />
    </div>
  );
};

export default BackButton;
