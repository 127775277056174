import axios from "axios";
import {
  END_USER_LOGIN_ERROR,
  END_USER_LOGIN_LOADING,
  END_USER_LOGIN_SUCCESS,
  RESET_STATE,
  VERIFY_CODE_SUCCESS,
} from "./constants";

import { setCookie } from "../auth/actions";

export function endUserLoginError(data) {
  return {
    type: END_USER_LOGIN_ERROR,
    payload: data,
  };
}

export function endUserLoginSuccess(data) {
  return {
    type: END_USER_LOGIN_SUCCESS,
    payload: data,
  };
}

export function verifyCodeSuccess(data) {
  return {
    type: VERIFY_CODE_SUCCESS,
    payload: data,
  };
}

export function endUserLoginLoading() {
  return {
    type: END_USER_LOGIN_LOADING,
  };
}

export function resetEndUserLoginState() {
  return {
    type: RESET_STATE,
  };
}

export function endUserLogin(phone, callback) {
  return (dispatch) => {
    dispatch(endUserLoginLoading());

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/end-users/signin`,
      data: {
        phone: phone,
      },
    })
      .then(() => {
        dispatch(endUserLoginSuccess(true));
      })
      .catch((e) => {
        dispatch(endUserLoginError(e.response?.data?.errors));
      });
  };
}

export function verifyCode(code, phone, callback) {
  return (dispatch) => {
    dispatch(endUserLoginLoading());

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/end-users/signin-verify`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        confirmationCode: code,
        phone: phone,
      },
    })
      .then((response) => {
        setCookie("uac", response.data.token);
        dispatch(verifyCodeSuccess(true));

        if (callback) callback(response.data.user);
        return response.data;
      })
      .catch((err) => {
        dispatch(endUserLoginError(err?.response?.data?.errors));
      });
  };
}
export const clearEndUserLogin = () => async (dispatch) => {
  dispatch(resetEndUserLoginState());
};
