import React from "react";
import CopyRight from "../ui/pageElementes/Copyright";
export default function Container({ style, children }) {
  return (
    <div
      className="w-full sm:w-510 px-4 flex justify-center flex-col"
      style={style}
    >
      {children}
      <br />
      <CopyRight />
    </div>
  );
}
