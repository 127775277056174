import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import GroupIcon from "@mui/icons-material/Group";
import SquareButton from "../../Components/ui/buttons/SquareButton";
import { useHttp } from "../../hooks/useHttp";
import { getConnectedClientsRequest } from "../../api/modules/authenticatedPartner";
import { PARTNER_USERS_TABLE_COLUMNS } from "./partnerUsers.constants";
import SubscriptionPlansModal from "../../Components/stripe/SubscriptionPlansModal";
import {
  assignSubscriptionRequest,
  cancelSubscriptionRequest,
  updateSubscriptionRequest,
} from "../../api/modules/subscriptions";
import { useModalContext } from "../../contexts/SuccessModalContext";

const PartnerUsersList = () => {
  const navigate = useNavigate();
  const { http, isLoading } = useHttp();
  const [clients, setClients] = useState();
  const { setOpenState } = useModalContext();
  const [selectedRow, setSelectedRow] = useState();
  const [showSubscriptionPlansModal, setShowSubscriptionPlansModal] =
    useState(false);

  const getClients = async () => {
    const res = await http(() => getConnectedClientsRequest());
    setClients(res);
  };

  useEffect(() => {
    getClients();
  }, []);

  // TODO: move these subscription handlers to a global place and reuse them
  const onSubscriptionCreate = async (
    subscriptionPlan,
    interval,
    initPaymentCallback
  ) => {
    const stripePriceID = subscriptionPlan[interval].stripePriceID;
    const subscriptionPlanId = subscriptionPlan.id;

    const res = await http(() =>
      assignSubscriptionRequest({
        clientId: selectedRow.id,
        stripePriceID,
        subscriptionPlanId,
      })
    );
    if (res?.latest_invoice?.payment_intent) {
      initPaymentCallback(res?.latest_invoice);
    } else {
      setOpenState("The operation succeeded successfully");
      setShowSubscriptionPlansModal(false);
      getClients();
    }
  };

  const onSubscriptionUpdate = async (
    subscriptionPlan,
    interval,
    initPaymentCallback
  ) => {
    const stripePriceID = subscriptionPlan[interval].stripePriceID;
    const subscriptionPlanId = subscriptionPlan.id;

    const res = await http(() =>
      updateSubscriptionRequest({
        clientId: selectedRow.id,
        stripePriceID,
        subscriptionPlanId,
      })
    );

    if (res?.latest_invoice?.payment_intent) {
      await initPaymentCallback(res?.latest_invoice);
    } else {
      setOpenState("The operation succeeded successfully");
      setShowSubscriptionPlansModal(false);
      getClients();
    }
  };

  const onSubscriptionCancel = async () => {
    try {
      await http(() => cancelSubscriptionRequest({ clientId: selectedRow.id }));
      await getClients();
      setShowSubscriptionPlansModal(false);
    } catch (e) {
      // TODO: handle error
    }
  };

  const columns = [
    ...PARTNER_USERS_TABLE_COLUMNS,
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <div
          key={params.id}
          style={{ flexDirection: "column", display: "flex", gap: "10px" }}
        >
          <SquareButton
            action={() => {
              navigate(`/user/${params.id}`);
            }}
            extraClasses={"text-xs"}
            padding={"px-2 py-1"}
            title="Edit"
            fill={true}
          />
          <SquareButton
            padding={"px-2 py-1"}
            extraClasses={`text-xs ${!params.row.status && "invisible"}`}
            action={() => {
              setSelectedRow(params.row);
              setShowSubscriptionPlansModal(true);
            }}
            title="Manage Subscription"
            fill={false}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        Icon={GroupIcon}
        action={() => navigate("/user/new")}
        buttonTitle="Add Client"
      />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadowBox overflow-hidden ">
              <div style={{ width: "100%" }}>
                <DataGrid
                  loading={isLoading}
                  style={{ border: "0", padding: "10px 20px" }}
                  rows={clients || []}
                  columns={columns}
                  checkboxSelection={false}
                  pageSize={10}
                  autoHeight={true}
                  rowHeight={100}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSubscriptionPlansModal && (
        <SubscriptionPlansModal
          buttonText="Submit"
          onClose={() => setShowSubscriptionPlansModal(false)}
          selectedClient={selectedRow}
          onSubscriptionCreate={onSubscriptionCreate}
          onSubscriptionUpdate={onSubscriptionUpdate}
          onSubscriptionCancel={onSubscriptionCancel}
          isLoading={isLoading}
          returnPath={"/users"}
        />
      )}
    </div>
  );
};

export default PartnerUsersList;
