export const AUTH_SIGN_IN = "AUTH_SIGN_IN";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP = "AUTH_SIGN_UP";
export const AUTH_IS = "AUTH_IS";
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const USER_DATA = "USER_DATA";
export const USER_DATA_LOADING = "USER_DATA_LOADING";
export const USER_DATA_ERROR = "USER_DATA_ERROR";
export const AUTH_FORGOT_PASSWORD = "AUTH_FORGOT_PASSWORD";
export const AUTH_RESET_PASSWORD = "AUTH_RESET_PASSWORD";
export const AUTH_PROFILE_PICTURE = "AUTH_PROFILE_PICTURE";
export const AUTH_PROFILE_DETAILS = "AUTH_PROFILE_DETAILS";
export const AUTH_CHANGE_PASSWORD = "AUTH_CHANGE_PASSWORD";
export const AUTH_ERROR_CLEAR = "AUTH_ERROR_CLEAR";
export const END_USER_SIGN_OUT = "END_USER_SIGN_OUT";
