import React from "react";
import DashboardsNavbar from "../../ui/nav/DashboardsNavbar";
import Footer from "../../ui/nav/Footer";
import SidebarMarketPlace from "../../ui/nav/SidebarMarketPlace";

const MarketPlaceLayout = ({ sidebar = true, children, user, showSidebar }) => {
  return (
    <div>
      <DashboardsNavbar
        showSidebar={true}
        setShowSidebar={() => {}}
        isMarketPlace={true}
        marketUser={user}
      />
      {sidebar && <SidebarMarketPlace showSidebar={showSidebar} />}
      <div className={`${sidebar && "lg:ml-64"} flex  flex-col  w-auto`}>
        {/* <Outlet /> */}
        {children}
        <Footer />
      </div>

      {/* */}
    </div>
  );
};

export default MarketPlaceLayout;
