import React, { useEffect } from "react";
import LandingPageLayout from "../../Components/layouts/LandingPageLayout";

import "./style.css";
const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full">
      <LandingPageLayout isLandingPage={false} normalLogo={true}>
        <div className="text-left px-8 lg:px-24 my-24">
          <section className="text-gray-500">
            <h1 className="font-bold text-black">PRIVACY NOTICE</h1>
            <h3 className="text-left font-bold text-sm">
              Last updated March 21, 2022
            </h3>
            <br />
            <p>
              This privacy notice for XRETEH SRL (doing business as XRETEH) ("
              <b>XRETEH</b> ," "<b>we</b>," "<b>us</b>," or "<b>our</b>"),
              describes how and why we might collect, store, use, and/or share
              ("<b>process</b>") your information when you use our services ("
              <b>Services</b>"), such as when you:
            </p>
            <ul className="list-disc pl-12">
              <li>
                Visit our website at{" "}
                <a
                  className="decorated-link"
                  href="https://xreteh.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://xreteh.com
                </a>{" "}
                , or any website of ours that links to this privacy notice
              </li>
              <li>
                Download and use our mobile application ( XRETEH) , or any other
                application of ours that links to this privacy notice
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
            <br />
            <p>
              <b>Questions or concerns?</b> Reading this privacy notice will
              help you understand your privacy rights and choices. If you do not
              agree with our policies and practices, please do not use our
              Services. If you still have any questions or concerns, please
              contact us at{" "}
              <a className="decorated-link" href="mailto:privacy@xreteh.com">
                privacy@xreteh.com
              </a>
              .
            </p>
          </section>
          <section className="my-12 text-gray-500">
            <h2 className="text-left text-black text-2xl">
              SUMMARY OF KEY POINTS
            </h2>
            <br />
            <p>
              <b>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our table
                of contents below to find the section you are looking for. You
                can also click{" "}
                <a className="decorated-link" href="#toc">
                  here
                </a>{" "}
                to go directly to our table of contents.
              </b>
            </p>
            <br />
            <p>
              <b>What personal information do we process?</b> When you visit,
              use, or navigate our Services, we may process personal information
              depending on how you interact with XRETEH and the Services, the
              choices you make, and the products and features you use. Click{" "}
              <a className="decorated-link" href="#infocollect">
                here
              </a>{" "}
              to learn more.
            </p>
            <br />
            <p>
              <b>Do we process any sensitive personal information?</b> We do not
              process sensitive personal information.
            </p>
            <br />
            <p>
              <b>Do you receive any information from third parties?</b> We do
              not receive any information from third parties.
            </p>
            <br />
            <p>
              <b>How do you process my information?</b> We process your
              information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent. We process your information only when
              we have a valid legal reason to do so. Click{" "}
              <a className="decorated-link" href="#infouse">
                here
              </a>{" "}
              to learn more.
            </p>
            <br />
            <p>
              <b>
                In what situations and with which types of parties do we share
                personal information?
              </b>{" "}
              We may share information in specific situations and with specific
              categories of third parties. Click{" "}
              <a className="decorated-link" href="#whoshare">
                here
              </a>{" "}
              to learn more.
            </p>
            <br />
            <p>
              <b>How do we keep your information safe?</b> We have
              organizational and technical processes and procedures in place to
              protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Click{" "}
              <a className="decorated-link" href="#infosafe">
                here
              </a>{" "}
              to learn more.
            </p>
            <br />
            <p>
              <b>What are your rights?</b> Depending on where you are located
              geographically, the applicable privacy law may mean you have
              certain rights regarding your personal information. Click{" "}
              <a className="decorated-link" href="#privacyrights">
                here
              </a>{" "}
              to learn more.
            </p>
            <br />
            <p>
              <b>How do I exercise my rights?</b> The easiest way to exercise
              your rights is by filling out our data subject request form
              available here:{" "}
              <a className="decorated-link" href="mailto:privacy@xreteh.com">
                privacy@xreteh.com
              </a>{" "}
              , or by contacting us. We will consider and act upon any request
              in accordance with applicable data protection laws.
            </p>
            <br />
            <p>
              Want to learn more about what XRETEH does with any information we
              collect? Click{" "}
              <a className="decorated-link" href="#toc">
                here
              </a>{" "}
              to review the notice in full.
            </p>
          </section>
          <section className="my-12 text-gray-500" id="toc">
            <h2 className="text-left text-black text-2xl">TABLE OF CONTENTS</h2>
            <br />
            <ol className="list-decimal pl-12">
              <li>
                <a className="decorated-link" href="#infocollect">
                  WHAT INFORMATION DO WE COLLECT?
                </a>
              </li>
              <li>
                <a className="decorated-link" href="#infouse">
                  HOW DO WE PROCESS YOUR INFORMATION?
                </a>
              </li>
              <li>
                <a className="decorated-link" href="#legalbases">
                  WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                  INFORMATION?
                </a>
              </li>
              <li>
                <a className="decorated-link" href="#whoshare">
                  WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </a>
              </li>
              <li>
                <a className="decorated-link" href="#cookies">
                  DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </a>
              </li>
              <li>
                <a className="decorated-link" href="#inforetain">
                  HOW LONG DO WE KEEP YOUR INFORMATION?
                </a>
              </li>
              <li>
                <a className="decorated-link" href="#infosafe">
                  HOW DO WE KEEP YOUR INFORMATION SAFE?
                </a>
              </li>
              <li>
                <a className="decorated-link" href="#infominors">
                  DO WE COLLECT INFORMATION FROM MINORS?
                </a>
              </li>
              <li>
                <a className="decorated-link" href="#privacyrights">
                  WHAT ARE YOUR PRIVACY RIGHTS?
                </a>
              </li>
              <li>
                <a className="decorated-link" href="#DNT">
                  CONTROLS FOR DO-NOT-TRACK FEATURES
                </a>
              </li>
              <li>
                <a className="decorated-link" href="#caresidents">
                  DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </a>
              </li>
              <li>
                <a className="decorated-link" href="#policyupdates">
                  DO WE MAKE UPDATES TO THIS NOTICE?
                </a>
              </li>
              <li>
                <a className="decorated-link" href="#contact">
                  HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </a>
              </li>
              <li>
                <a className="decorated-link" href="#request">
                  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                  YOU?
                </a>
              </li>
            </ol>
          </section>
          <section className="my-12 text-gray-500" id="infocollect">
            <h2 className="text-left text-black text-2xl">
              1.WHAT INFORMATION DO WE COLLECT?
            </h2>
            <h3 className="text-left font-bold text-sm text-black">
              Personal information you disclose to us
            </h3>
            <br />
            <p>
              <i>
                <b>In Short:</b> We collect personal information that you
                provide to us.
              </i>
            </p>
            <br />
            <p>
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </p>
            <br />
            <p>
              <b>Personal Information Provided by You.</b> The personal
              information that we collect depends on the context of your
              interactions with us and the Services, the choices you make, and
              the products and features you use. The personal information we
              collect may include the following:
            </p>
            <ul className="list-disc pl-12">
              <li>names</li>
              <li>phone numbers</li>
              <li>email addresses</li>
              <li>usernames</li>
              <li>passwords</li>
              <li>contact preferences</li>
              <li>contact or authentication data</li>
              <li>billing addresses</li>
              <li>debit/credit card numbers</li>
              <li>job titles</li>
            </ul>
            <br />
            <p>
              <b>Sensitive Information.</b>We do not process sensitive
              information.
            </p>
            <br />
            <p>
              <b>Payment Data.</b>We may collect data necessary to process your
              payment if you make purchases, such as your payment instrument
              number (such as a credit card number), and the security code
              associated with your payment instrument. All payment data is
              stored by Stripe. You may find their privacy notice link(s) here:{" "}
              <a
                className="decorated-link"
                href="https://stripe.com/en-ro/privacy"
                target="_blank"
                rel="noreferrer"
              >
                https://stripe.com/en-ro/privacy
              </a>{" "}
              .
            </p>
            <br />
            <p>
              <b>Application Data.</b>If you use our application(s), we also may
              collect the following information if you choose to provide us with
              access or permission:
            </p>
            <ul className="list-disc pl-12">
              <li>
                <i>Geolocation Information.</i>We may request access or
                permission to track location-based information from your mobile
                device, either continuously or while you are using our mobile
                application(s), to provide certain location-based services. If
                you wish to change our access or permissions, you may do so in
                your device's settings.
              </li>
              <li>
                <i>Mobile Device Access.</i>We may request access or permission
                to certain features from your mobile device, including your
                mobile device's camera , sensors , storage , and other features.
                If you wish to change our access or permissions, you may do so
                in your device's settings.
              </li>
              <li>
                <i>Mobile Device Data.</i>We automatically collect device
                information (such as your mobile device ID, model, and
                manufacturer), operating system, version information and system
                configuration information, device and application identification
                numbers, browser type and version, hardware model Internet
                service provider and/or mobile carrier, and Internet Protocol
                (IP) address (or proxy server). If you are using our
                application(s), we may also collect information about the phone
                network associated with your mobile device, your mobile device™s
                operating system or platform, the type of mobile device you use,
                your mobile devices unique device ID, and information about the
                features of our application(s) you accessed.
              </li>
              <li>
                <i>Push Notifications</i>We may request to send you push
                notifications regarding your account or certain features of the
                application(s). If you wish to opt out from receiving these
                types of communications, you may turn them off in your device's
                settings.
              </li>
            </ul>
            <br />
            <p>
              This information is primarily needed to maintain the security and
              operation of our application(s), for troubleshooting, and for our
              internal analytics and reporting purposes.
            </p>
            <br />
            <p>
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>
            <br />
            <h3 className="text-left font-bold text-black text-xl">
              Information automatically collected
            </h3>
            <br />
            <p>
              <i>
                <b>In Short:</b> Some information such as your Internet Protocol
                (IP) address and/or browser and device characteristics' is
                collected automatically when you visit our Services.
              </i>
            </p>
            <br />
            <p>
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </p>
            <br />
            <p>
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </p>
            <br />
            <p>The information we collect includes:</p>
            <ul className="list-disc pl-12">
              <li>
                <i>Log and Usage Data.</i> Log and usage data is
                service-related, diagnostic, usage, and performance information
                our servers automatically collect when you access or use our
                Services and which we record in log files. Depending on how you
                interact with us, this log data may include your IP address,
                device information, browser type, and settings and information
                about your activity in the Services (such as the date/time
                stamps associated with your usage, pages and files viewed,
                searches, and other actions you take such as which features you
                use), device event information (such as system activity, error
                reports (sometimes called "crash dumps"), and hardware
                settings).
              </li>
              <li>
                <i>Device Data.</i> We collect device data such as information
                about your computer, phone, tablet, or other device you use to
                access the Services. Depending on the device used, this device
                data may include information such as your IP address (or proxy
                server), device and application identification numbers,
                location, browser type, hardware model, Internet service
                provider and/or mobile carrier, operating system, and system
                configuration information.
              </li>
              <li>
                <i>Location Data.</i> We collect location data such as
                information about your device's location, which can be either
                precise or imprecise. How much information we collect depends on
                the type and settings of the device you use to access the
                Services. For example, we may use GPS and other technologies to
                collect geolocation data that tells us your current location
                (based on your IP address). You can opt out of allowing us to
                collect this information either by refusing access to the
                information or by disabling your Location setting on your
                device. However, if you choose to opt out, you may not be able
                to use certain aspects of the Services.
              </li>
            </ul>
          </section>
          <section className="my-12 text-gray-500" id="infouse">
            <h2 className="text-left text-black text-2xl">
              2.HOW DO WE PROCESS YOUR INFORMATION?
            </h2>
            <br />
            <p>
              <i>
                <b>In Short:</b> We process your information to provide,
                improve, and administer our Services, communicate with you, for
                security and fraud prevention, and to comply with law. We may
                also process your information for other purposes with your
                consent.
              </i>
            </p>
            <br />
            <p>
              <b>
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </b>
            </p>
            <ul className="list-disc pl-12">
              <li>
                <b>
                  To facilitate account creation and authentication and
                  otherwise manage user accounts.
                </b>{" "}
                We may process your information so you can create and log in to
                your account, as well as keep your account in working order.
              </li>
              <li>
                <b>
                  To deliver and facilitate delivery of services to the user.
                </b>{" "}
                We may process your information to provide you with the
                requested service.
              </li>
              <li>
                <b>To respond to user inquiries/offer support to users.</b> We
                may process your information to respond to your inquiries and
                solve any potential issues you might have with the requested
                service.
              </li>
              <li>
                <b>To send administrative information to you.</b> We may process
                your information to send you details about our products and
                services, changes to our terms and policies, and other similar
                information.
              </li>
              <li>
                <b>To fulfill and manage your orders.</b> We may process your
                information to fulfill and manage your orders, payments,
                returns, and exchanges made through the Services.
              </li>
              <li>
                <b>To enable user-to-user communications.</b> We may process
                your information if you choose to use any of our offerings that
                allow for communication with another user.
              </li>
              <li>
                <b>To request feedback.</b> We may process your information when
                necessary to request feedback and to contact you about your use
                of our Services.
              </li>
              <li>
                <b>To send you marketing and promotional communications.</b>We
                may process the personal information you send to us for our
                marketing purposes, if this is in accordance with your marketing
                preferences. You can opt out of our marketing emails at any
                time. For more information, see{" "}
                <a className="decorated-link" href="#privacyrights">
                  WHAT ARE YOUR PRIVACY RIGHTS?
                </a>{" "}
                " below).
              </li>
              <li>
                <b>To deliver targeted advertising to you.</b> We may process
                your information to develop and display personalized content and
                advertising tailored to your interests, location, and more.
              </li>
              <li>
                <b>To protect our Services.</b> We may process your information
                as part of our efforts to keep our Services safe and secure,
                including fraud monitoring and prevention.
              </li>
              <li>
                <b>To identify usage trends.</b> We may process information
                about how you use our Services to better understand how they are
                being used so we can improve them.
              </li>
              <li>
                <b>
                  To determine the effectiveness of our marketing and
                  promotional campaigns.
                </b>{" "}
                We may process your information to better understand how to
                provide marketing and promotional campaigns that are most
                relevant to you.
              </li>
              <li>
                <b>To save or protect an individual's vital interest.</b> We may
                process your information when necessary to save or protect an
                individual™s vital interest, such as to prevent harm.
              </li>
            </ul>
          </section>
          <section className="my-12 text-gray-500" id="legalbases">
            <h2 className="text-left text-black text-2xl">
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </h2>
            <br />
            <p>
              <i>
                <b>In Short:</b> We only process your personal information when
                we believe it is necessary and we have a valid legal reason
                (i.e., legal basis) to do so under applicable law, like with
                your consent, to comply with laws, to provide you with services
                to enter into or fulfill our contractual obligations, to protect
                your rights, or to fulfill our legitimate business interests.
              </i>
            </p>
            <br />
            <p>
              <b>
                <u>
                  If you are located in the EU or UK, this section applies to
                  you.
                </u>
              </b>
            </p>
            <br />
            <p>
              The General Data Protection Regulation (GDPR) and UK GDPR require
              us to explain the valid legal bases we rely on in order to process
              your personal information. As such, we may rely on the following
              legal bases to process your personal information:
            </p>
            <br />
            <ul className="list-disc pl-12" id="withdrawconsent">
              <li>
                <b>Consent.</b> We may process your information if you have
                given us permission (i.e., consent) to use your personal
                information for a specific purpose. You can withdraw your
                consent at any time. Click{" "}
                <a className="decorated-link" href="#withdrawconsent">
                  here
                </a>{" "}
                to learn more.
              </li>
              <li>
                <b>Performance of a Contract.</b> We may process your personal
                information when we believe it is necessary to fulfill our
                contractual obligations to you, including providing our Services
                or at your request prior to entering into a contract with you.
              </li>
              <li>
                <b>Legitimate Interests.</b> We may process your information
                when we believe it is reasonably necessary to achieve our
                legitimate business interests and those interests do not
                outweigh your interests and fundamental rights and freedoms. For
                example, we may process your personal information for some of
                the purposes described in order to:
                <br />
                <ul className="list-disc pl-12">
                  <li>
                    Send users information about special offers and discounts on
                    our products and services
                  </li>
                  <li>
                    Develop and display personalized and relevant advertising
                    content for our users
                  </li>
                  <li>
                    Analyze how our services are used so we can improve them to
                    engage and retain users
                  </li>
                  <li>Support our marketing activities</li>
                  <li>
                    Diagnose problems and/or prevent fraudulent activities
                  </li>
                  <li>
                    Understand how our users use our products and services so we
                    can improve user experience
                  </li>
                </ul>
              </li>
              <li>
                <b>Legal Obligations.</b> We may process your information where
                we believe it is necessary for compliance with our legal
                obligations, such as to cooperate with a law enforcement body or
                regulatory agency, exercise or defend our legal rights, or
                disclose your information as evidence in litigation in which we
                are involved.
              </li>
              <li>
                <b>Vital Interests.</b> We may process your information where we
                believe it is necessary to protect your vital interests or the
                vital interests of a third party, such as situations involving
                potential threats to the safety of any person.
              </li>
            </ul>

            <br />
            <p>
              <b>
                <u>
                  If you are located in Canada, this section applies to you.
                </u>
              </b>
            </p>
            <br />
            <p>
              We may process your information if you have given us specific
              permission (i.e., express consent) to use your personal
              information for a specific purpose, or in situations where your
              permission can be inferred (i.e., implied consent). You can
              withdraw your consent at any time. Click{" "}
              <a className="decorated-link" href="#withdrawconsent">
                here
              </a>{" "}
              to learn more.
            </p>
            <br />
            <p>
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
            </p>
            <br />
            <ul className="list-disc pl-12">
              <li>
                If collection is clearly in the interests of an individual and
                consent cannot be obtained in a timely way
              </li>
              <li>For investigations and fraud detection and prevention</li>
              <li>
                For business transactions provided certain conditions are met
              </li>
              <li>
                If it is contained in a witness statement and the collection is
                necessary to assess, process, or settle an insurance claim
              </li>
              <li>
                For identifying injured, ill, or deceased persons and
                communicating with next of kin
              </li>
              <li>
                If we have reasonable grounds to believe an individual has been,
                is, or may be victim of financial abuse
              </li>
              <li>
                If it is reasonable to expect collection and use with consent
                would compromise the availability or the accuracy of the
                information and the collection is reasonable for purposes
                related to investigating a breach of an agreement or a
                contravention of the laws of Canada or a province
              </li>
              <li>
                If disclosure is required to comply with a subpoena, warrant,
                court order, or rules of the court relating to the production of
                records
              </li>
              <li>
                If it was produced by an individual in the course of their
                employment, business, or profession and the collection is
                consistent with the purposes for which the information was
                produced
              </li>
              <li>
                If the collection is solely for journalistic, artistic, or
                literary purposes
              </li>
              <li>
                If the information is publicly available and is specified by the
                regulations
              </li>
            </ul>
          </section>

          <section className="my-12 text-gray-500" id="whoshare">
            <h2 className="text-left text-black text-2xl">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h2>
            <br />
            <p>
              <i>
                <b>In Short:</b> We may share information in specific situations
                described in this section and/or with the following categories
                of third parties.
              </i>
            </p>
            <br />
            <p>
              <b>
                Vendors, Consultants, and Other Third-Party Service Providers.
              </b>{" "}
              We may share your data with third-party vendors, service
              providers, contractors, or agents (<b>third parties</b>) who
              perform services for us or on our behalf and require access to
              such information to do that work. We have contracts in place with
              our third parties, which are designed to help safeguard your
              personal information. This means that they cannot do anything with
              your personal information unless we have instructed them to do it.
              They will also not share your personal information with any
              organization apart from us. They also commit to protect the data
              they hold on our behalf and to retain it for the period we
              instruct. The categories of third parties we may share personal
              information with are as follows:
            </p>

            <ul className="list-disc pl-12">
              <li>Ad Networks</li>
              <li>Affiliate Marketing Programs</li>
              <li>Cloud Computing Services</li>
              <li>Data Analytics Services</li>
              <li>Data Storage Service Providers</li>
              <li>Finance &amp; Accounting Tools</li>
              <li>Communication &amp; Collaboration Tools</li>
              <li>Government Entities</li>
              <li>Payment Processors</li>
              <li>Performance Monitoring Tools</li>
              <li>Website Hosting Service Providers</li>
              <li>Testing Tools</li>
              <li>Social Networks</li>
              <li>Sales &amp; Marketing Tools</li>
              <li>Retargeting Platforms</li>
            </ul>
            <br />
            <p>
              We also may need to share your personal information in the
              following situations:
            </p>

            <ul className="list-disc pl-12">
              <li>
                <b>Business Transfers.</b> We may share or transfer your
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all
                or a portion of our business to another company.
              </li>
              <li>
                <b>Affiliates.</b> We may share your information with our
                affiliates, in which case we will require those affiliates to
                honor this privacy notice. Affiliates include our parent company
                and any subsidiaries, joint venture partners, or other companies
                that we control or that are under common control with us.
              </li>
              <li>
                <b>Business Partners.</b> We may share your information with our
                business partners to offer you certain products, services, or
                promotions.
              </li>
              <li>
                <b>Other Users.</b> When you share personal information (for
                example, by posting comments, contributions, or other content to
                the Services) or otherwise interact with public areas of the
                Services, such personal information may be viewed by all users
                and may be publicly made available outside the Services in
                perpetuity. Similarly, other users will be able to view
                descriptions of your activity, communicate with you within our
                Services, and view your profile.
              </li>
            </ul>
          </section>

          <section className="my-12 text-gray-500" id="cookies">
            <h2 className="text-left text-black text-2xl">
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h2>
            <br />
            <p>
              <i>
                <b>In Short:</b> We may use cookies and other tracking
                technologies to collect and store your information.
              </i>
            </p>
            <br />
            <p>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </p>
          </section>

          <section className="my-12 text-gray-500" id="inforetain">
            <h2 className="text-left text-black text-2xl">
              6. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h2>
            <br />
            <p>
              <i>
                <b>In Short:</b> We keep your information for as long as
                necessary to fulfill the purposes outlined in this privacy
                notice unless otherwise required by law.
              </i>
            </p>
            <br />
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </p>
            <br />
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
          </section>

          <section className="my-12 text-gray-500" id="infosafe">
            <h2 className="text-left text-black text-2xl">
              7. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </h2>
            <br />
            <p>
              <i>
                <b>In Short:</b> We aim to protect your personal information
                through a system of organizational and technical security
                measures.
              </i>
            </p>
            <br />
            <p>
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </p>
          </section>

          <section className="my-12 text-gray-500" id="infominors">
            <h2 className="text-left text-black text-2xl">
              8. DO WE COLLECT INFORMATION FROM MINORS?
            </h2>
            <br />
            <p>
              <i>
                <b>In Short:</b> We do not knowingly collect data from or market
                to children under 18 years of age.
              </i>
            </p>
            <br />
            <p>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent™s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at{" "}
              <a className="decorated-link" href="mailto:privacy@xreteh.com">
                privacy@xreteh.com
              </a>
              .
            </p>
          </section>

          <section className="my-12 text-gray-500" id="privacyrights">
            <h2 className="text-left text-black text-2xl">
              9. WHAT ARE YOUR PRIVACY RIGHTS?
            </h2>
            <br />
            <p>
              <i>
                <b>In Short:</b> In some regions, such as the European Economic
                Area (EEA), United Kingdom (UK), and Canada , you have rights
                that allow you greater access to and control over your personal
                information. You may review, change, or terminate your account
                at any time.
              </i>
            </p>
            <br />
            <p>
              In some regions (like the EEA, UK, and Canada ), you have certain
              rights under applicable data protection laws. These may include
              the right (i) to request access and obtain a copy of your personal
              information, (ii) to request rectification or erasure; (iii) to
              restrict the processing of your personal information; and (iv) if
              applicable, to data portability. In certain circumstances, you may
              also have the right to object to the processing of your personal
              information. You can make such a request by contacting us by using
              the contact details provided in the section{" "}
              <a className="decorated-link" href="#contact">
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE below.
              </a>
            </p>
            <br />
            <p>
              We will consider and act upon any request in accordance with
              applicable data protection laws.
            </p>
            <p>
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details here:{" "}
              <a
                className="decorated-link"
                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                target="_blank"
                rel="noreferrer"
              >
                https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
              </a>
              .
            </p>
            <br />
            <p>
              If you are located in Switzerland, the contact details for the
              data protection authorities are available here:{" "}
              <a
                className="decorated-link"
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.edoeb.admin.ch/edoeb/en/home.html
              </a>
              .
            </p>
            <br />
            <p>
              <b>
                <a href="#withdrawconsent" className="decorated-link">
                  Withdrawing your consent:
                </a>
              </b>{" "}
              If we are relying on your consent to process your personal
              information, which may be express and/or implied consent depending
              on the applicable law, you have the right to withdraw your consent
              at any time. You can withdraw your consent at any time by
              contacting us by using the contact details provided in the section
              "
              <a className="decorated-link" href="#contact">
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
              " below or updating your preferences .
            </p>
            <br />
            <p>
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal, nor when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </p>
            <br />
            <p>
              <b>
                <u>Opting out of marketing and promotional communications:</u>
              </b>
              You can unsubscribe from our marketing and promotional
              communications at any time by clicking on the unsubscribe link in
              the emails that we send, replying STOP or UNSUBSCRIBE to the SMS
              messages that we send, or by contacting us using the details
              provided in the section "
              <a className="decorated-link" href="#contact">
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
              " below. You will then be removed from the marketing lists.
              However, we may still communicate with you ” for example, to send
              you service-related messages that are necessary for the
              administration and use of your account, to respond to service
              requests, or for other non-marketing purposes.
            </p>
            <br />
            <h3 className="text-left font-bold text-black text-xl">
              Account information
            </h3>
            <br />
            <p>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>
            <ul className="list-disc pl-12">
              <li>
                Log in to your account settings and update your user account.
              </li>
            </ul>
            <br />
            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </p>
            <br />
            <p>
              <b>
                <u>Cookies and similar technologies:</u>
              </b>
              Most Web browsers are set to accept cookies by default. If you
              prefer, you can usually choose to set your browser to remove
              cookies and to reject cookies. If you choose to remove cookies or
              reject cookies, this could affect certain features or services of
              our Services. To opt out of interest-based advertising by
              advertisers on our Services visit{" "}
              <a
                className="decorated-link"
                href="http://www.aboutads.info/choices/"
                target="_blank"
                rel="noreferrer"
              >
                http://www.aboutads.info/choices/
              </a>
              .
            </p>
            <br />
            <p>
              If you have questions or comments about your privacy rights, you
              may email us at{" "}
              <a className="decorated-link" href="mailto:privacy@xreteh.com">
                privacy@xreteh.com
              </a>
              .
            </p>
          </section>

          <section className="my-12 text-gray-500" id="DNT">
            <h2 className="text-left text-black text-2xl">
              10. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h2>
            <br />
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </p>
          </section>

          <section className="my-12 text-gray-500" id="caresidents">
            <h2 className="text-left text-black text-2xl">
              11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h2>
            <br />
            <p>
              <i>
                <b>In Short:</b>
              </i>{" "}
              Yes, if you are a resident of California, you are granted specific
              rights regarding access to your personal information.
            </p>
            <br />
            <p>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <br />
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with Services, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Services, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.,
              backups, etc.).
            </p>
            <br />
            <h3 className="text-left font-bold text-black text-xl">
              CCPA Privacy Notice
            </h3>
            <br />
            <p>The California Code of Regulations defines a "resident" as:</p>
            <ul className="pl-12">
              <li>
                (1) every individual who is in the State of California for other
                than a temporary or transitory purpose and
              </li>
              <li>
                (2) every individual who is domiciled in the State of California
                who is outside the State of California for a temporary or
                transitory purpose
              </li>
            </ul>
            <br />
            <p>All other individuals are defined as "non-residents."</p>
            <br />
            <p>
              If this definition of "resident" applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </p>
            <br />
            <p>
              <b>What categories of personal information do we collect?</b>
            </p>
            <br />
            <p>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </p>
            <table className="w-full table-auto border-collapse border border-slate-500 text-left">
              <tr>
                <th className="border-collapse border border-slate-500">
                  Category
                </th>
                <th className="border-collapse border border-slate-500">
                  Examples
                </th>
                <th className="border-collapse border border-slate-500">
                  Collected
                </th>
              </tr>
              <tr>
                <td className="border-collapse border border-slate-500">
                  B. Personal information categories listed in tde California
                  Customer Records statute
                </td>
                <td className="border-collapse border border-slate-500">
                  Name, contact information, education, employment, employment
                  history, and financial information
                </td>
                <td className="border-collapse border border-slate-500">YES</td>
              </tr>
              <tr>
                <td className="border-collapse border border-slate-500">
                  C. Protected classification characteristics under California
                  or federal law
                </td>
                <td className="border-collapse border border-slate-500">
                  Gender and date of birtd
                </td>
                <td className="border-collapse border border-slate-500">NO</td>
              </tr>
              <tr>
                <td className="border-collapse border border-slate-500">
                  D. Commercial information
                </td>
                <td className="border-collapse border border-slate-500">
                  Transaction information, purchase history, financial details,
                  and payment information
                </td>
                <td className="border-collapse border border-slate-500">YES</td>
              </tr>
              <tr>
                <td className="border-collapse border border-slate-500">
                  E. Biometric information
                </td>
                <td className="border-collapse border border-slate-500">
                  Fingerprints and voiceprints
                </td>
                <td className="border-collapse border border-slate-500">YES</td>
              </tr>
              <tr>
                <td className="border-collapse border border-slate-500">
                  F. Internet or otder similar network activity
                </td>
                <td className="border-collapse border border-slate-500">
                  Browsing history, search history, online behavior, interest
                  data, and interactions witd our and otder websites,
                  applications, systems, and advertisements
                </td>
                <td className="border-collapse border border-slate-500">YES</td>
              </tr>
              <tr>
                <td className="border-collapse border border-slate-500">
                  G. Geolocation data
                </td>
                <td className="border-collapse border border-slate-500">
                  Device location
                </td>
                <td className="border-collapse border border-slate-500">YES</td>
              </tr>
              <tr>
                <td className="border-collapse border border-slate-500">
                  H. Audio, electronic, visual, tdermal, olfactory, or similar
                  information
                </td>
                <td className="border-collapse border border-slate-500">
                  Images and audio, video or call recordings created in
                  connection witd our business activities
                </td>
                <td className="border-collapse border border-slate-500">NO</td>
              </tr>
              <tr>
                <td className="border-collapse border border-slate-500">
                  I. Professional or employment-related information
                </td>
                <td className="border-collapse border border-slate-500">
                  Business contact details in order to provide you our services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job witd us
                </td>
                <td className="border-collapse border border-slate-500">NO</td>
              </tr>
              <tr>
                <td className="border-collapse border border-slate-500">
                  J. Education Information
                </td>
                <td className="border-collapse border border-slate-500">
                  Student records and directory information
                </td>
                <td className="border-collapse border border-slate-500">NO</td>
              </tr>
              <tr>
                <td className="border-collapse border border-slate-500">
                  K. Inferences drawn from otder personal information
                </td>
                <td className="border-collapse border border-slate-500">
                  Inferences drawn from any of tde collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual's preferences and characteristics
                </td>
                <td className="border-collapse border border-slate-500">YES</td>
              </tr>
              <tr>
                <td className="border-collapse border border-slate-500">
                  A. Identifiers
                </td>
                <td className="border-collapse border border-slate-500">
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </td>
                <td className="border-collapse border border-slate-500">YES</td>
              </tr>
            </table>
            <br />
            <p>
              We may also collect other personal information outside of these
              categories instances where you interact with us in person, online,
              or by phone or mail in the context of:
            </p>
            <br />
            <ul className="list-disc pl-12">
              <li>Receiving help through our customer support channels;</li>
              <li>Participation in customer surveys or contests; and</li>
              <li>
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
              </li>
            </ul>
            <br />
            <p>
              <b>How do we use and share your personal information?</b>
            </p>
            <br />
            <p>
              XRETEH SRL collects and shares your personal information through:
            </p>
            <br />
            <ul className="list-disc pl-12">
              <li>Targeting cookies/Marketing cookies</li>
              <li>Social media cookies</li>
              <li>Beacons/Pixels/Tags</li>
            </ul>
            <br />
            <p>
              More information about our data collection and sharing practices
              can be found in this privacy notice .
            </p>
            <br />
            <p>
              You may contact us by email at{" "}
              <a className="decorated-link" href="mailto:privacy@xreteh.com">
                privacy@xreteh.com
              </a>{" "}
              , or by referring to the contact details at the bottom of this
              document.
            </p>
            <br />
            <p>
              If you are using an authorized agent to exercise your right to opt
              out we may deny a request if the authorized agent does not submit
              proof that they have been validly authorized to act on your
              behalf.
            </p>
            <br />
            <p>
              <b>Will your information be shared with anyone else?</b>
            </p>
            <br />
            <p>
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf.
            </p>
            <br />
            <p>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be "selling" of your personal information.
            </p>
            <br />
            <p>
              XRETEH SRL has not sold any personal information to third parties
              for a business or commercial purpose in the preceding twelve (12)
              months. XRETEH SRL has disclosed the following categories of
              personal information to third parties for a business or commercial
              purpose in the preceding twelve (12) months:
            </p>
            <br />
            <ul className="list-disc pl-12">
              <li>
                Category A. Identifiers, such as contact details like your real
                name, alias, postal address, telephone or mobile contact number,
                unique personal identifier, online identifier, Internet Protocol
                address, email address, and account name.
              </li>
              <li>
                Category B. Personal information, as defined in the California
                Customer Records law, such as your name, contact information,
                education, employment, employment history, and financial
                information.
              </li>
              <li>
                Category D. Commercial information, such as transaction
                information, purchase history, financial details, and payment
                information.
              </li>
              <li>
                Category E. Biometric information, such as fingerprints and
                voiceprints.
              </li>
              <li>
                Category F. Internet or other electronic network activity
                information, such as browsing history, search history, online
                behavior, interest data, and interactions with our and other
                websites, applications, systems, and advertisements.
              </li>
              <li>Category G. Geolocation data, such as device location.</li>
              <li>
                Category K. Inferences drawn from any of the personal
                information listed above to create a profile or summary about,
                for example, an individual's preferences and characteristics.
              </li>
            </ul>
            <br />
            <p>
              The categories of third parties to whom we disclosed personal
              information for a business or commercial purpose can be found
              under "
              <a className="decorated-link" href="#whoshare">
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
              </a>
              .
            </p>
            <br />
            <h3 className="text-left font-bold text-black text-xl">
              Your rights with respect to your personal data
            </h3>
            <br />
            <p>
              <u>Right to request deletion of the data. Request to delete</u>
            </p>
            <br />
            <p>
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation, or
              any processing that may be required to protect against illegal
              activities.
            </p>
            <br />
            <p>
              <u>Right to be informed. Request to know</u>
            </p>
            <br />
            <p>Depending on the circumstances, you have a right to know:</p>
            <br />
            <ul className="list-disc pl-12">
              <li>whether we collect and use your personal information;</li>
              <li>the categories of personal information that we collect;</li>
              <li>
                the purposes for which the collected personal information is
                used;
              </li>
              <li>
                whether we sell your personal information to third parties;
              </li>
              <li>
                the categories of personal information that we sold or disclosed
                for a business purpose;
              </li>
              <li>
                the categories of third parties to whom the personal information
                was sold or disclosed for a business purpose; and
              </li>
              <li>
                the business or commercial purpose for collecting or selling
                personal information.
              </li>
            </ul>
            <br />
            <p>
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.
            </p>
            <br />
            <p>
              <u>
                Right to Non-Discrimination for the Exercise of a Consumer's
                Privacy Rights
              </u>
            </p>
            <br />
            <p>
              We will not discriminate against you if you exercise your privacy
              rights.
            </p>
            <br />
            <p>
              <u>Verification process</u>
            </p>
            <br />
            <p>
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g., phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </p>
            <br />
            <p>
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. However, if we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.
            </p>
            <br />
            <p>
              <u>Other privacy rights</u>
            </p>
            <br />
            <ul className="list-disc pl-12">
              <li>
                You may object to the processing of your personal information.
              </li>
              <li>
                You may request correction of your personal data if it is
                incorrect or no longer relevant, or ask to restrict the
                processing of the information.
              </li>
              <li>
                You can designate an authorized agent to make a request under
                the CCPA on your behalf. We may deny a request from an
                authorized agent that does not submit proof that they have been
                validly authorized to act on your behalf in accordance with the
                CCPA.
              </li>
              <li>
                You may request to opt out from future selling of your personal
                information to third parties. Upon receiving an opt-out request,
                we will act upon the request as soon as feasibly possible, but
                no later than fifteen (15) days from the date of the request
                submission.
              </li>
            </ul>
            <br />
            <p>
              To exercise these rights, you can contact us by email at{" "}
              <a className="decorated-link" href="mailto:privacy@xreteh.com">
                privacy@xreteh.com
              </a>
              , or by referring to the contact details at the bottom of this
              document. If you have a complaint about how we handle your data,
              we would like to hear from you.
            </p>
          </section>

          <section className="my-12 text-gray-500" id="policyupdates">
            <h2 className="text-left text-black text-2xl">
              12. DO WE MAKE UPDATES TO THIS NOTICE?
            </h2>
            <br />
            <p>
              <i>
                <b>In Short:</b>
              </i>{" "}
              Yes, we will update this notice as necessary to stay compliant
              with relevant laws.
            </p>
            <br />
            <p>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
          </section>

          <section className="my-12 text-gray-500" id="contact">
            <h2 className="text-left text-black text-2xl">
              13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </h2>
            <br />
            <p>
              If you have questions or comments about this notice, you may email
              us at{" "}
              <a className="decorated-link" href="mailto:privacy@xreteh.com">
                privacy@xreteh.com
              </a>{" "}
              or by post to:
            </p>
            <br />
            <p>
              XRETEH SRL
              <br />
              Str. GHEORGHE SINCAI, Nr. 33
              <br />
              Municipiul Baia Mare , Judet Maramures 430311
              <br />
              Romania
            </p>
          </section>

          <section className="my-12 text-gray-500" id="request">
            <h2 className="text-left text-black text-2xl">
              14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h2>
            <br />
            <p>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it in some
              circumstances. To request to review, update, or delete your
              personal information, please visit:{" "}
              <a className="decorated-link" href="mailto:privacy@xreteh.com">
                privacy@xreteh.com
              </a>
              .
            </p>
            <br />
          </section>
        </div>
      </LandingPageLayout>
    </div>
  );
};

export default PrivacyPage;
