import { request } from "../api.service";

export const getSubscriptionPlansRequest = () => {
  return request(`/admin/subscription-plans`, "get");
};

export const createSubscriptionPlanRequest = (data) => {
  return request(`/admin/subscription-plans`, "post", data);
};

export const getSubscriptionPlanRequest = async (id) => {
  return request(`/admin/subscription-plans/${id}`, "get");
};

export const updateSubscriptionPlanRequest = (id, data) => {
  return request(`/admin/subscription-plans/${id}`, "put", data);
};

export const deleteSubscriptionPlanRequest = (id) => {
  return request(`/admin/subscription-plans/${id}`, "delete");
};
