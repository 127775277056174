import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import LoginPage from "./pages/login";
import Dashboard from "./Components/layouts/Dashboard";
import AdminDashboard from "./Components/layouts/AdminDashboard";
import { useDispatch, useSelector } from "react-redux";
import "./assets/styles/tailwind.css";

import SignupPage from "./pages/signup";
import MyModels from "./pages/myModels";
import ModelsAdmin from "./pages/adminModels";
import FormModelPage from "./pages/formModelPage";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import ProfilePage from "./pages/profile";
import ViewPartnerOptions from "./pages/viewPartnerOptions";
import ViewEndOptions from "./pages/viewEndOptions";
import SingleClient from "./pages/singleClient";
import MarketPage from "./pages/marketPlace";
import { ModalContext } from "./contexts/SuccessModalContext";
import SuccessModal from "./Components/ui/modals/SuccessModal";
import SingleMarketModel from "./pages/singleMarketModel/SingleMarketModel";
import SingInEndUserPage from "./pages/signInEndUser";
import SignUpEndUserPage from "./pages/signupEndUser";
import MyAccountEndUserPage from "./pages/myAcountEndUserPage";
import ErrorPage from "./pages/errorPage";
import MyFavoritesEndUser from "./pages/myFavoritesEndUsers";
import MyModelsEndUser from "./pages/myModelsEndUser";
import Disclaimer from "./pages/disclaimer";
import LandingPage from "./pages/landingPage";
import PrivacyPage from "./pages/privacy";
import TermsPage from "./pages/termsAndConditions";
import PartnerDashboard from "./Components/layouts/PartnerDashboard";
import PartnerSingleUser from "./pages/partnerSingleUser";
import SupportDashboard from "./Components/layouts/SupportDashboard";
import ViewSupportUserDetails from "./pages/viewSupportUserDetails";
import ViewClientOptions from "./pages/viewClientOptions";
import ViewCategoriesView from "./pages/viewCategoriesOptions";
import CreateCategoriesView from "./pages/createCategoryOptions";
import CreateCustomField from "./pages/createCustomField";
import ViewCustomFields from "./pages/viewCustomFields";
import AdminSubscriptionPlansForm from "./pages/adminSubscriptionPlans/adminSubscriptionPlans.form";
import DashboardPage from "./pages/dashboardPage";

import SubscriptionPlansList from "./pages/adminSubscriptionPlans/adminSubscriptionPlans.list";
import AdminSupportList from "./pages/adminSupport/adminSupport.list";
import AdminPartnersList from "./pages/adminPartners/adminPartners.list";
import AdminClientsList from "./pages/adminClients/adminClients.list";
import AdminEndUsersList from "./pages/adminEndUsers/adminEndUsers.list";
import AdminCategoriesList from "./pages/adminCategories/adminCategories.list";
import UserAssignmentHandling from "./Components/auth/UserAssignmentHandling";
import AdminCustomFieldsList from "./pages/adminCustomFields/adminCustomFields.list";
import ClientModifiedModels from "./pages/clientModifiedModels/clientModifiedModels";
import PartnerUsersList from "./pages/partnerUsers/partnerUsers.list";
import MyClientsList from "./pages/myClients/myClients.list";
import AdminPartnerTiersList from "./pages/adminPartnerTiers/adminPartnerTiers.list";
import AdminPartnerTierForm from "./pages/adminPartnerTiers/adminPartnerTier.form";
import SingleModifiedModels from "./pages/singleModifiedModels";
import EndUserDashboard from "./Components/layouts/EndUserDashboard";
import axios from "axios";
import { signout } from "./redux/modules/auth/actions";

function App() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);
  const role = state.user.role;

  const [showSuccessModal, setShowSuccessModal] = useState("");

  const PrivateWrapper = ({ auth: { isAuthenticated, role } }) => {
    if (isAuthenticated && role === "userA") {
      return <Dashboard />;
    } else if (isAuthenticated && role === "Admin") {
      return <AdminDashboard />;
    } else if (isAuthenticated && role === "Support") {
      return <SupportDashboard />;
    } else if (isAuthenticated && role === "Partner") {
      return <PartnerDashboard />;
    } else if (isAuthenticated && !role) {
      return <EndUserDashboard />;
    } else {
      return <Navigate to="/login" />;
    }
  };

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async (error) => {
      const errRes = error?.response;

      if (errRes?.status === 401) {
        dispatch(
          signout(() => {
            window.location.href = "/login";
          })
        );
      }

      return Promise.reject(error);
    }
  );

  return (
    <>
      <SuccessModal
        visibility={showSuccessModal}
        setVisibility={setShowSuccessModal}
        title={
          showSuccessModal &&
          typeof showSuccessModal === "string" &&
          showSuccessModal.length > 0
            ? showSuccessModal
            : "The update operation succeeded"
        }
      />

      <ModalContext.Provider
        value={{
          setOpenState: setShowSuccessModal,
        }}
      >
        <Router>
          <Routes>
            <Route path="/" exact element={<LandingPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route
              path="/user-assignment/:userId"
              element={<UserAssignmentHandling />}
            />
            <Route path="/marketplace" element={<MarketPage />} />
            <Route
              path="/marketplace/:modelId"
              element={<SingleMarketModel />}
            />

            <Route path="/signup-end" element={<SignUpEndUserPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/signin" element={<SingInEndUserPage />} />
            <Route
              element={
                <PrivateWrapper
                  auth={{
                    isAuthenticated: state.isAuthenticated,
                    role: role,
                  }}
                />
              }
            >
              <Route path="/my-models" element={<MyModels />} />
              <Route
                path="/modified-models"
                element={<ClientModifiedModels />}
              />
              <Route
                path="/modified-models/new"
                element={<SingleModifiedModels />}
              />
              <Route
                path="/modified-models/:id"
                element={<SingleModifiedModels />}
              />
              <Route path="/my-clients" element={<MyClientsList />} />
              <Route path="/my-client/:id" element={<SingleClient />} />
              <Route path="/my-client/new" element={<SingleClient />} />
              <Route path="/models/new" element={<FormModelPage />} />
              <Route
                path="/my-account"
                element={role ? <ProfilePage /> : <MyAccountEndUserPage />}
              />
              <Route path="/model/:id" element={<FormModelPage />} />
            </Route>

            {["Admin", "Support", "Partner", "userA"].includes(role) && (
              <Route path="/dashboard" exact element={<DashboardPage />} />
            )}

            {role === "Admin" ? (
              <Route
                element={
                  <PrivateWrapper
                    auth={{
                      isAuthenticated: state.isAuthenticated,
                      role: role,
                    }}
                  />
                }
              >
                <Route path="/models" element={<ModelsAdmin />} />
                <Route path="/clients" element={<AdminClientsList />} />
                <Route path="/clients/:id" element={<ViewClientOptions />} />
                <Route path="/clients/new" element={<ViewClientOptions />} />
                <Route path="/support" element={<AdminSupportList />} />

                {/* Subscription Plans */}
                <Route
                  path="/subscription-plans"
                  element={<SubscriptionPlansList />}
                />
                <Route
                  path="/subscription-plans/:id"
                  element={<AdminSubscriptionPlansForm />}
                />
                <Route
                  path="/subscription-plans/new"
                  element={<AdminSubscriptionPlansForm />}
                />
                {/* END Subscription Plans */}

                {/* Partner Tiers */}
                <Route
                  path="/partner-tiers"
                  element={<AdminPartnerTiersList />}
                />
                <Route
                  path="/partner-tiers/new"
                  element={<AdminPartnerTierForm />}
                />
                <Route
                  path="/partner-tiers/:id"
                  element={<AdminPartnerTierForm />}
                />
                {/* END Partner Tiers */}

                <Route
                  path="/custom-fields"
                  element={<AdminCustomFieldsList />}
                />
                <Route
                  path="/custom-fields/new"
                  element={<CreateCustomField />}
                />
                <Route
                  path="/custom-fields/edit"
                  element={<ViewCustomFields />}
                />

                <Route path="/categories" element={<AdminCategoriesList />} />
                <Route
                  path="/categories/new"
                  element={<CreateCategoriesView />}
                />
                <Route path="/category/:id" element={<ViewCategoriesView />} />

                <Route
                  path="/support/:id"
                  element={<ViewSupportUserDetails />}
                />
                <Route
                  path="/support/new"
                  element={<ViewSupportUserDetails />}
                />
                <Route path="/end-users" element={<AdminEndUsersList />} />
                <Route path="/end-users/:id" element={<ViewEndOptions />} />
                <Route path="/end-users/new" element={<ViewEndOptions />} />
                <Route path="/models/:id" element={<FormModelPage />} />
                <Route path="/partners" element={<AdminPartnersList />} />
                <Route path="/partners/new" element={<ViewPartnerOptions />} />
                <Route path="/partners/:id" element={<ViewPartnerOptions />} />
              </Route>
            ) : role === "Partner" ? (
              <Route
                element={
                  <PrivateWrapper
                    auth={{
                      isAuthenticated: state.isAuthenticated,
                      role: role,
                    }}
                  />
                }
              >
                <Route path="/users" element={<PartnerUsersList />} />
                <Route path="/user/:id" element={<PartnerSingleUser />} />
                <Route path="/user/new" element={<PartnerSingleUser />} />
              </Route>
            ) : (
              role === "Support" && (
                <Route
                  element={
                    <PrivateWrapper
                      auth={{
                        isAuthenticated: state.isAuthenticated,
                        role: role,
                      }}
                    />
                  }
                >
                  <Route path="/models" element={<ModelsAdmin />} />
                  <Route path="/clients" element={<AdminClientsList />} />
                  <Route path="/clients/:id" element={<ViewClientOptions />} />
                  <Route path="/clients/new" element={<ViewClientOptions />} />
                  <Route path="/end-users" element={<AdminEndUsersList />} />
                  <Route path="/end-users/:id" element={<ViewEndOptions />} />
                  <Route path="/end-users/new" element={<ViewEndOptions />} />
                  <Route path="/models/:id" element={<FormModelPage />} />
                  <Route path="/categories" element={<AdminCategoriesList />} />
                  <Route
                    path="/categories/new"
                    element={<CreateCategoriesView />}
                  />
                  <Route
                    path="/category/:id"
                    element={<ViewCategoriesView />}
                  />
                  <Route path="/partners" element={<AdminPartnersList />} />
                  <Route
                    path="/partners/:id"
                    element={<ViewPartnerOptions />}
                  />
                  <Route
                    path="/partners/new"
                    element={<ViewPartnerOptions />}
                  />
                  <Route
                    path="/custom-fields"
                    element={<AdminCustomFieldsList />}
                  />
                  <Route
                    path="/custom-fields/new"
                    element={<CreateCustomField />}
                  />
                  <Route
                    path="/custom-fields/edit"
                    element={<ViewCustomFields />}
                  />
                </Route>
              )
            )}
            <Route
              element={
                <PrivateWrapper
                  auth={{
                    isAuthenticated: state.isAuthenticated,
                  }}
                />
              }
            >
              <Route path="/my-favorites" element={<MyFavoritesEndUser />} />
              <Route path="/my-assigned-models" element={<MyModelsEndUser />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </ModalContext.Provider>
    </>
  );
}

export default App;
