import { request } from "../api.service";

export const removeCustomFieldRequest = ({ customFieldId }) => {
  return request(`/admin/custom-fields/${customFieldId}`, "delete");
};

export const getAllCustomFieldsRequest = (params) => {
  const urlParams = new URLSearchParams(params);
  return request(`/admin/custom-fields${params ? `?${urlParams}` : ""}`, "get");
};

export const getFiltersBySubcategoryId = (subCategoryId, isMarketplace) => {
  return request(
    `/user/filters?subCategoryId=${subCategoryId}&isMarketplace`,
    "get"
  );
};
