import {
  CLIENTS_ERROR,
  CLIENTS_LOADING,
  CLIENT_CREATE_SUCCESS,
  FETCH_CLIENTS,
  CLIENTS_ERROR_CLEAR,
  CLIENTS_SINGLE_USER,
  FETCH_CLIENTS_SEARCH,
  USER_DATA_BREAK_CONNECTION,
  FETCH_LATEST_CLIENTS,
} from "./constants";

export const initialState = {
  clients: {},
  latestClients: {},
  singleClient: {},
  isLoading: false,
  hasError: [],
  success: "",
};

const fetchClientsError = (state, action) => {
  return {
    ...state,
    hasError: action.payload,
    isLoading: false,
  };
};

const fetchClientsLoading = (state, action) => {
  return {
    ...state,
    hasError: [],
    isLoading: true,
  };
};

const fetchClientsSuccess = (state, action) => {
  return {
    ...state,
    clients: action.payload,

    hasError: [],
    isLoading: false,
  };
};
const fetchLatestClientsSuccess = (state, action) => {
  return {
    ...state,
    latestClients: action.payload,
    hasError: [],
    isLoading: false,
  };
};
const fetchClientsSearchSuccess = (state, action) => {
  return {
    ...state,
    clients: action.payload,
    hasError: [],
    isLoading: false,
  };
};
const createClientsSuccess = (state, action) => {
  return {
    ...state,
    hasError: [],
    isLoading: false,
    success: action.payload,
  };
};
const fetchSingleClientSuccess = (state, action) => {
  return {
    ...state,
    singleClient: action.payload,
    hasError: [],
    isLoading: false,
  };
};
function errorClear(state) {
  const newState = { ...state };

  newState.hasError = [];
  return newState;
}
function updateConnection(state, action) {
  const newState = { ...state, action };

  newState.userLoading = false;
  newState.hasError = [];
  return newState;
}
const handlers = {
  [CLIENTS_ERROR]: fetchClientsError,
  [CLIENTS_LOADING]: fetchClientsLoading,
  [CLIENT_CREATE_SUCCESS]: createClientsSuccess,
  [FETCH_CLIENTS]: fetchClientsSuccess,
  [CLIENTS_ERROR_CLEAR]: errorClear,
  [CLIENTS_SINGLE_USER]: fetchSingleClientSuccess,
  [FETCH_CLIENTS_SEARCH]: fetchClientsSearchSuccess,
  [USER_DATA_BREAK_CONNECTION]: updateConnection,
  [FETCH_LATEST_CLIENTS]: fetchLatestClientsSuccess,
};

export default function clientsData(state = initialState, action) {
  const handler = handlers[action.type];

  if (!handler) return state;
  return handler(state, action);
}
