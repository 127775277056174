import moment from "moment";
import FaceIcon from "@mui/icons-material/Face";
import { useParams } from "react-router-dom";

export const ADMIN_ASSIGN_CLIENTS_TABLE_COLUMNS = [
  {
    field: "profilePicture",
    renderCell: (params) => (
      <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full overflow-hidden">
        {params.row.profilePicture ? (
          <img
            src={`${process.env.REACT_APP_API}${params.row.profilePicture.usePath}/${params.row.profilePicture.filename}`}
            alt={params.row.name}
            className="object-cover w-full h-full"
          />
        ) : (
          <FaceIcon className="text-white " />
        )}
      </div>
    ),
    headerName: "",
    maxWidth: 50,
  },
  {
    field: "name",
    valueGetter: (params) =>
      `${params.row.name} ${params.row.phone} ${params.row.email}`,
    renderCell: (params) => {
      const { id } = useParams();
      return (
        <div className="flex-column justify-right">
          <p>
            <b>{params.row.name}</b>
          </p>
          {params.row.partnerId && params.row.partnerId !== id ? (
            <p className="text-xs text-red-500">Assigned to another partner</p>
          ) : (
            ""
          )}
        </div>
      );
    },
    flex: 1,
    headerName: "Name",
  },
  {
    field: "phone",
    valueGetter: (params) => params.row.phone,
    headerName: "Phone",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "email",
    valueGetter: (params) => params.row.email,
    headerName: "Email",
    minWidth: 200,
    flex: 1.5,
  },
  {
    field: "createdAt",
    valueGetter: (params) => {
      return moment(params.row.createdAt).format("ll");
    },
    headerName: "Join Date",
    flex: 1,
  },
  {
    field: "relatedEndUsers",
    valueGetter: (params) => {
      return params.row.connectedEndUsers.length;
    },
    headerName: "Assigned End Users",
    minWidth: 100,
    flex: 1,
  },
];
