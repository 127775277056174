export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const FETCH_CLIENTS_SEARCH = "FETCH_CLIENTS_SEARCH";
export const CLIENTS_LOADING = "CLIENTS_LOADING";
export const CLIENTS_ERROR = "CLIENTS_ERROR";
export const CLIENTS_SUCCESS = "CLIENTS_SUCCESS";
export const CLIENT_CREATE_SUCCESS = "CLIENT_CREATE_SUCCESS";
export const CLIENTS_ERROR_CLEAR = "CLIENTS_ERROR_CLEAR";
export const USER_DATA_BREAK_CONNECTION = "USER_DATA_BREAK_CONNECTION";
export const CLIENTS_SINGLE_USER = "CLIENTS_SINGLE_USER";
export const FETCH_LATEST_CLIENTS = "FETCH_LATEST_CLIENTS";
