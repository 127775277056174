import React from "react";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ModelsListAdmin from "../../Components/ui/pageElementes/ModelsListAdmin";
const ModelsAdmin = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        Icon={HomeOutlinedIcon}
        link="/models/new"
        buttonTitle="Upload a model"
      />
      <ModelsListAdmin fetchModels={true} />
    </div>
  );
};

export default ModelsAdmin;
