import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import MainButton from "../../ui/buttons/MainButton";
import React, { useState } from "react";

const StripeCheckoutForm = ({ returnPath }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event?.preventDefault();

    setIsLoading(true);
    setErrorMessage("");

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_DOMAIN}${
          returnPath || "/my-account"
        }`,
      },
    });

    setIsLoading(false);

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setErrorMessage(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <br />

      {errorMessage && (
        <p
          className="text-xs text-red-500 text-center"
          style={{ minHeight: "1rem" }}
        >
          {errorMessage}
        </p>
      )}
      <br />
      <div className={"text-center"}>
        <MainButton
          isLoading={isLoading}
          disabled={!stripe}
          title={"Submit"}
          action={handleSubmit}
        />
      </div>
    </form>
  );
};

export default StripeCheckoutForm;
