import React from "react";
import usePagination from "../../hooks/usePagination";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Pagination = ({ page, setPage, total, pageSize, totalPages }) => {
  const { pagination } = usePagination({
    numberOfArticles: total || 0,
    articlesPerPage: pageSize || 12,
    numberOfButtons: 3,
    totalPages: totalPages || 0,
    currentPage: page || 0,
  });

  const handleClick = (page) => setPage(page);

  return (
    <ul className="w-full flex justify-center  items-center py-4 px-7 ">
      <li
        className={`${
          pagination[0] === page && "pointer-events-none hidden"
        } cursor-pointer border border-gray-200 rounded-full m-2 w-10 h-10 flex justify-center items-center`}
        onClick={handleClick.bind(null, page - 1)}
      >
        <ArrowBackIcon />
      </li>
      {pagination.map((current, i) => (
        <li
          key={i}
          className={`${
            page === current && "bg-gray-200"
          } cursor-pointer border border-gray-200 rounded-full m-2 w-10 h-10 flex justify-center items-center`}
          onClick={handleClick.bind(null, current)}
        >
          {current}
        </li>
      ))}
      <li
        className={`${
          pagination.reverse()[0] === page && "pointer-events-none hidden"
        }  cursor-pointer border border-gray-200 rounded-full m-2 w-10 h-10 flex justify-center items-center`}
        onClick={handleClick.bind(null, page + 1)}
      >
        <ArrowForwardIcon />
      </li>
    </ul>
  );
};

export default Pagination;
