import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { signout } from "../redux/modules/auth/actions";
import { toast } from "tailwind-toast";

export const useHttp = ({ withToastMessage } = { withToastMessage: true }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [backendErrors, setBackendErrors] = useState([]);

  useEffect(() => {
    if (withToastMessage && toastMessage) {
      const colors = {
        success: "green",
        danger: "red-500",
      };
      const toastWithOptions = {
        shape: "pill",
        duration: 4000,
        speed: 1000,
        positionX: "end",
        positionY: "bottom",
        color: `bg-${colors[toastMessage.severity]}`,
        fontTone: 200,
      };

      if (toastMessage.severity === "success") {
        toast()
          .success("Success! ", toastMessage.text)
          .with(toastWithOptions)
          .show();
      }
      if (toastMessage.severity === "danger") {
        toast()
          .danger("Error! ", toastMessage.text)
          .with(toastWithOptions)
          .show();
      }
    }
  }, [toastMessage]);

  const http = async function (makeRequest) {
    setIsLoading(true);

    try {
      const response = await makeRequest();
      setIsLoading(false);

      const isNotGetRequest = response?.config?.method !== "get";
      if (isNotGetRequest) {
        setToastMessage({
          severity: "success",
          text: "Operation completed successfully",
        });
      }

      return response?.data;
    } catch (e) {
      setIsLoading(false);

      if (e.code === "ERR_NETWORK" || !e.response) {
        setToastMessage({
          severity: "danger",
          text: e.message,
        });
        throw e;
      }

      const errRes = e.response;

      if (errRes === 401) {
        dispatch(
          signout(() => {
            window.location.href = "/login";
          })
        );
      }

      setToastMessage({
        text: "Something went wrong",
        severity: "danger",
      });
      setBackendErrors(errRes?.data?.errors);

      throw e;
    }
  };

  return { http, isLoading, backendErrors, toastMessage };
};
