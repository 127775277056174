import React, { useEffect } from "react";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import FaceIcon from "@mui/icons-material/Face";
import { useDispatch, useSelector } from "react-redux";
import { fetchLatestUsers } from "../../../redux/modules/admin/actions";
const Statistics = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = React.useState([]);
  const {
    user: { role },
  } = useSelector((state) => state.auth);

  const { latestUsers } = useSelector((state) => state.adminData);

  useEffect(() => {
    dispatch(fetchLatestUsers());
  }, []);

  useEffect(() => {
    if (!latestUsers || !Object.keys(latestUsers).length) return;

    const sortedUsers = latestUsers?.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    setUsers(sortedUsers?.splice(0, 5));
  }, [latestUsers]);

  return (
    <div className="w-full flex justify-center p-5 overflow-y-auto">
      {users.length > 0 && (
        <table
          className={
            "shadowBox w-full bg-white shadow-md rounded-xl flex flex-col"
          }
        >
          <thead className="w-full">
            <tr className="flex justify-center items-center my-1 py-2">
              <th>
                <span className="mr-2">
                  <PeopleAltOutlinedIcon color="primary" />
                </span>
                {role === "Admin" ? "Latest created clients" : "Your clients"}
              </th>
            </tr>
          </thead>
          <tbody className="">
            {users.map((item, index) => {
              return (
                <tr key={index} className="w-full">
                  <td
                    className="w-full flex items-center p-5"
                    style={{
                      borderBottom: "1px solid #EDF2F7",
                    }}
                  >
                    <div className="mr-4 flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full overflow-hidden">
                      {item.profilePicture ? (
                        <img
                          src={`${process.env.REACT_APP_API}${item.profilePicture.usePath}/${item.profilePicture.filename}`}
                          alt={item.name}
                          className="object-cover w-full h-full"
                        />
                      ) : (
                        <FaceIcon className="text-white " />
                      )}
                    </div>
                    <span>
                      {item.name
                        ? item.name
                        : item.email
                        ? item.email
                        : item.phone}{" "}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Statistics;
