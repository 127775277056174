import axios from "axios";
import cookie from "js-cookie";

export const request = async (url, method, payload, headers, options) => {
  const DEFAULT_HEADERS = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${cookie.get("uac")}`,
  };

  try {
    return await axios.request({
      headers: { ...DEFAULT_HEADERS, ...headers },
      baseURL: process.env.REACT_APP_API,
      withCredentials: true,
      url,
      method,
      data: payload,
      ...options,
    });
  } catch (e) {
    if (e.code === "ERR_NETWORK" || !e.response) {
      console.log(`[Axios Error]: ${e.message}`);
    }
    throw e;
  }
};
