import React from "react";
import { Link } from "react-router-dom";
const SecondaryButton = ({
  action,
  type,
  title,
  link,
  extraClasses,
  width,
  alert,
  Icon,
  padding,
  disabled,
  color,
}) => {
  const classList = `text-xs relative text-center ${
    color || "text-red-500"
  } font-bold outline-none focus:outline-nonenpm ease-linear transition-all duration-150 flex justify-center items-center
   cursor-pointer
   ${extraClasses || ""} ${width || "w-full"} ${
    !padding ? "px-2 py-3" : padding
  }`;
  if (link) {
    return (
      <Link to={link}>
        <div className={classList}>
          {Icon && <span className={"mr-1"}>{Icon}</span>}
          {title}
        </div>
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      type={type || "button"}
      onClick={action || (() => {})}
      className={classList}
    >
      {Icon && <span className={"mr-1"}>{Icon}</span>}
      {title}
    </button>
  );
};

export default SecondaryButton;
