import React, { useState, useEffect } from "react";
import FaceIcon from "@mui/icons-material/Face";
import {
  fetchSingleClient,
  fetchClients,
  updateClient,
  fetchLatestClients,
  addClient,
} from "../../redux/modules/clients/actions";
import { fetchUserData } from "../../redux/modules/user/actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import { Controller, useForm } from "react-hook-form";

import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import { useModalContext } from "../../contexts/SuccessModalContext";
import CountriesAutocomplete from "../../Components/ui/forms/CountriesAutocomplete";
const SingleClient = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isEdit = id !== undefined;
  const { singleClient, hasError } = useSelector((state) => state.clientsData);
  const { setOpenState } = useModalContext();
  const [isActive, setIsActive] = useState(singleClient.status);
  useEffect(() => {
    window.scrollTo(0, 0);

    isEdit && dispatch(fetchSingleClient(id));
  }, []);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    isEdit ? reset(singleClient) : reset();
  }, [singleClient]);
  const callback = () => {
    dispatch(fetchUserData());
    dispatch(fetchClients());
    dispatch(fetchLatestClients());
    setOpenState(true);
    navigate("/my-clients");
  };

  const onToggleChange = () => {
    setIsActive(!isActive);
  };

  const submit = (data) => {
    dispatch(updateClient(id, { ...data, status: isActive }, callback));
  };
  const onCreateCallback = () => {
    setOpenState(true);
    navigate("/my-clients");
  };
  const onCreateSubmit = (data) => {
    dispatch(addClient(data, onCreateCallback));
  };
  const backendErrors = () => {
    if (!hasError || hasError.length <= 0) {
      return;
    }

    return hasError.map((e) => (
      <li className="text-xs text-red-500 " key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };
  return (
    <div className="w-full h-full flex flex-col justify-around">
      {isEdit && (
        <PageHeader
          Profile={
            singleClient?.profilePicture ? (
              <img
                src={`${process.env.REACT_APP_API}${singleClient.user?.profilePicture?.usePath}/${singleClient?.profilePicture?.filename}`}
                alt={singleClient?.name}
                className="w-full object-cover h-full"
              />
            ) : (
              <FaceIcon className="text-white " />
            )
          }
          buttonSecondary={true}
          title={singleClient.name ? singleClient.name : singleClient.phone}
          toggleChecked={isActive}
          onToggleChange={onToggleChange}
          backLink="/my-clients"
        />
      )}
      <div className="w-full h-full flex flex-col justify-around">
        <div className="py-2 align-middle inline-block min-w-full w-full">
          <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex font-medium leading-6 text-gray-900">
                  Email
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    id="end user-email"
                    control={control}
                    fieldName="email"
                    disabled={isEdit && true}
                    type="email"
                    defaultValue={isEdit ? singleClient?.email : ""}
                    placeholder={"Email"}
                    rules={{
                      pattern: {
                        value:
                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        message: "Please use a valid email address",
                      },
                      required: {
                        value: true,
                        message: "Please add the users email",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.email && errors.email.message}
                </p>
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex font-medium leading-6 text-gray-900">
                  Name
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    id="end user-name"
                    control={control}
                    fieldName="name"
                    type="text"
                    defaultValue={isEdit ? singleClient?.name : ""}
                    placeholder={"Name"}
                    rules={{
                      minLength: {
                        value: 4,
                        message: "The min length of the name is 4",
                      },
                      required: {
                        value: true,
                        message: "Name is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.name && errors.name.message}
                </p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Country
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-1 px-7  border border-fade rounded-4xl my-4`}
              >
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    return (
                      <CountriesAutocomplete
                        onChange={(country) => {
                          onChange(country?.code);
                        }}
                        defaultValue={value || singleClient?.country}
                      />
                    );
                  }}
                  name="country"
                  rules={{
                    required: {
                      value: true,
                      message: "Country is required",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.country && errors.country.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Phone
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="end user-phone"
                  control={control}
                  fieldName="phone"
                  type="text"
                  defaultValue={isEdit ? singleClient?.phone : ""}
                  placeholder={"+CC XXX XXX XXX"}
                  rules={{
                    pattern: {
                      value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                      message: "Please use a valid phone number",
                    },
                    required: {
                      value: true,
                      message: "Please add the users phone number",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.phone && errors.phone.message}
              </p>
            </div>
            <div className="w-full p-2">
              <MainButton
                action={handleSubmit(isEdit ? submit : onCreateSubmit)}
                title={isEdit ? "Save" : "Add end user"}
              />
            </div>{" "}
            <ul className="w-full flex flex-col justify-center items-center">
              {backendErrors()}
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SingleClient;
