import { createContext, useContext } from "react";

export const ModalContext = createContext({
  setOpenState: () => {},
});

/**
 * Forward the popup menu context.
 */
export function useModalContext() {
  return useContext(ModalContext);
}
