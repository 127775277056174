import {
  PARTNERS_LOADING,
  PARTNERS_ERROR,
  PARTNERS_SUCCESS,
  PARTNERS_FETCH_USERS,
  PARTNERS_FETCH_SINGLE_USER,
  PARTNERS_ERROR_CLEAR,
} from "./constants";

export const initialState = {
  users: {},
  models: {},
  singleUser: {},
  singleModel: {},
  loading: false,
  error: [],
  success: "",
};

const partnersError = (state, action) => {
  return {
    ...state,
    error: action.payload,
    loading: false,
  };
};

const partnersLoading = (state, action) => {
  return {
    ...state,
    error: [],
    loading: true,
  };
};
const partnersSuccess = (state, action) => {
  return {
    ...state,
    error: [],
    loading: false,
    success: action.payload,
  };
};

const fetchPartnerUsers = (state, action) => {
  return {
    ...state,
    users: action.payload,
    error: [],
    loading: false,
  };
};
const fetchPartnerSingleUser = (state, action) => {
  return {
    ...state,
    singleUser: action.payload,
    error: [],
    loading: false,
  };
};

function errorClear(state) {
  const newState = { ...state };

  newState.hasError = [];
  return newState;
}

const handlers = {
  [PARTNERS_LOADING]: partnersLoading,
  [PARTNERS_ERROR]: partnersError,
  [PARTNERS_SUCCESS]: partnersSuccess,
  [PARTNERS_FETCH_USERS]: fetchPartnerUsers,
  [PARTNERS_FETCH_SINGLE_USER]: fetchPartnerSingleUser,
  [PARTNERS_ERROR_CLEAR]: errorClear,
};

export default function partnersData(state = initialState, action) {
  const handler = handlers[action.type];

  if (!handler) return state;
  return handler(state, action);
}
//
