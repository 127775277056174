import React, { useState, useEffect } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/images/Logo.png";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../redux/modules/auth/actions";
import Page from "../../Components/auth/Page";
import Container from "../../Components/auth/Container";
import MainButton from "../../Components/ui/buttons/MainButton";
const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const { isAuthenticated, user, error } = useSelector((state) => state.auth);
  const [values, setValues] = React.useState({
    password: "",
    // weight: "",
    // weightRange: "",
    success: "",
    showPassword: false,
  });
  const [showErrors, setShowErrors] = useState(false);
  useEffect(() => {
    if (isAuthenticated) {
      if (user.role === "Admin") {
        navigate("/models");
      } else if (user.role === "userA") {
        navigate("/my-models");
      }
    }
  }, []);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const callback = () => {
    setValues({
      ...values,
      password: "",
      success: "Your password has been changed. You can now ",
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(resetPassword(values.password, token, callback));
  };
  useEffect(() => {
    if (error && error.length > 0) {
      setShowErrors(true);
    }
  }, [error]);

  const errorDisplay = () => {
    if (error.length > 0) {
      return error.map((error, i) => {
        return (
          <p
            key={i}
            className="text-xs text-red-500 "
            style={{ minHeight: "1rem" }}
          >
            {error.message}
          </p>
        );
      });
    }
  };
  return (
    <Page>
      <Container className="flex items-center">
        <Link to="/">
          <img
            src={logo}
            style={{ margin: "0 auto" }}
            className="logo h-20 w-48 sm:h-56 sm:w-72"
          />
        </Link>
        <div className="z-10 w-full bg-white mt-5 p-5 box-shadow-default rounded-lg flex justify-center items-center flex-col">
          <div className="py-7">
            <h2 className="font-bold text-center text-black py-2.5">
              Getting Started
            </h2>
            <p className="font-medium text-center text-fade leading-7 tracking-wide">
              Set up your new password
            </p>
          </div>
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="form flex flex-col justify-between items-center w-full"
          >
            <div className="w-full sm:w-5/6 flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4">
              <LockOutlinedIcon />
              <input
                id="reset-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                placeholder="Password"
                onChange={handleChange("password")}
                className="flex flex-1 appearance-none  w-1/2 py-3 ml-5 leading-tight focus:outline-none"
              />
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            </div>
            <div className="w-full flex justify-evenly my-2">
              <Link
                className="text-primary font-medium text-center sm:text-right"
                to="/login"
              >
                Login
              </Link>
            </div>
            {showErrors && error.length > 0 && errorDisplay()}
            {values.success !== "" && (
              <div className="rounded-xl bg-green-100 px-2 py-1 text-green-700 flex justify-center">
                <div>
                  {values.success}{" "}
                  <Link
                    className="text-primary font-medium text-center sm:text-right"
                    to="/login"
                  >
                    Login
                  </Link>
                </div>
              </div>
            )}
            <div className="flex justify-center w-full my-2">
              <MainButton
                title="Reset Password"
                type="submit"
                width="w-full sm:w-5/6"
              />
            </div>
          </form>
        </div>
      </Container>
    </Page>
  );
};

export default ResetPassword;
