import React, { useState, useEffect } from "react";
import FaceIcon from "@mui/icons-material/Face";
import { useParams, useNavigate } from "react-router-dom";
import MainButton from "../../Components/ui/buttons/MainButton";
import { useForm } from "react-hook-form";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import ConfirmModal from "../../Components/ui/modals/ConfirmModal";
import SuccessModal from "../../Components/ui/modals/SuccessModal";
import { useHttp } from "../../hooks/useHttp";
import LoadingIcon from "../../Components/ui/icons/LoadingIcon";
import {
  createPartnerTierRequest,
  deletePartnerTierRequest,
  getPartnerTierRequest,
  updatePartnerTierRequest,
} from "../../api/modules/partnerTiers";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";

const AdminPartnerTierForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { http, isLoading, backendErrors } = useHttp();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [partnerTier, setPartnerTier] = useState();
  const [removeModal, setRemoveModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!id) return;

    http(() =>
      getPartnerTierRequest(id).then((res) => {
        setPartnerTier(res);
      })
    );
  }, []);

  const removeSubmit = async () => {
    await http(() => deletePartnerTierRequest(id));
  };

  const prepareData = (data) => {
    return {
      name: data.name,
      salesUpTo: +data.salesUpTo,
      discountPercentage: +data.discountPercentage,
    };
  };

  const onSubmit = async (data) => {
    await http(() =>
      id
        ? updatePartnerTierRequest(id, prepareData(data))
        : createPartnerTierRequest(prepareData(data))
    );

    navigate("/partner-tiers");
  };

  if (id && !partnerTier) {
    return <LoadingIcon />;
  }

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        backLink={"/partner-tiers"}
        Profile={<FaceIcon className="text-white " />}
        buttonSecondary={true}
        title={
          partnerTier
            ? `Partner tier: ${partnerTier?.name}`
            : "New Partner Tier"
        }
      />

      <div className="py-2 align-middle inline-block min-w-full w-full">
        <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Name
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="partnerTier-name"
                  control={control}
                  fieldName={`name`}
                  type="text"
                  defaultValue={partnerTier?.name}
                  disabled={false}
                  placeholder={"Name"}
                  rules={{
                    minLength: {
                      value: 4,
                      message: "The min length is 4 chars",
                    },
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors?.name?.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Overall Sales Up to:
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="partnerTier-salesUpTo"
                  control={control}
                  fieldName={`salesUpTo`}
                  type="text"
                  defaultValue={partnerTier?.salesUpTo}
                  placeholder={"50000$"}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    min: {
                      value: -1,
                      message: "The number should be > 0 or -1",
                    },
                  }}
                />
              </div>
              <p className="text-xs" style={{ minHeight: "1rem" }}>
                Use "-1" for no limit
              </p>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors?.salesUpTo?.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Discount Percentage
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="partnerTier-discountPercentage"
                  control={control}
                  fieldName={`discountPercentage`}
                  type="text"
                  defaultValue={partnerTier?.discountPercentage}
                  placeholder={"10%"}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    min: {
                      value: 0,
                      message: "Min 0",
                    },
                    max: {
                      value: 100,
                      message: "Max 100",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors?.discountPercentage?.message}
              </p>
            </div>
          </div>
          <div className="w-full p-2">
            <MainButton
              isLoading={isLoading}
              action={handleSubmit(onSubmit)}
              title={"Save"}
            />
          </div>
          <ConfirmModal
            buttonText="Remove SubscriptionPlan"
            visibility={removeModal}
            setVisibility={setRemoveModal}
            submitAction={removeSubmit}
            title="Are you sure you want to remove this subscription plan?"
            description={`This action is irreversible.`}
          />
          <SuccessModal
            visibility={showSuccessModal}
            setVisibility={setShowSuccessModal}
            title={"The update operation succeeded"}
          />
          <ul className="w-full flex flex-col justify-center items-center">
            {(backendErrors || []).map((e) => (
              <li className="text-xs text-red-500 " key={e.code}>
                {e.message}
                <br />
              </li>
            ))}
          </ul>
        </form>
      </div>
    </div>
  );
};

export default AdminPartnerTierForm;
