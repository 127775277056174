import {
  ADMIN_FETCH_USERS,
  ADMIN_ERROR,
  ADMIN_SUCCESS,
  ADMIN_LOADING,
  ADMIN_FETCH_MODELS,
  ADMIN_FETCH_SINGLE_MODEL,
  ADMIN_CREATE_ARCHITECT,
  ADMIN_FETCH_END_USERS,
  ADMIN_ERROR_CLEAR,
  ADMIN_FETCH_SINGLE_END_USER,
  ADMIN_FETCH_SINGLE_USER,
  ADMIN_FETCH_USERS_SEARCH,
  ADMIN_FETCH_LATEST_USERS,
  DUPLICATE_MODEL,
  FETCH_PARTNERS,
  ADMIN_FETCH_CATEGORIES,
  ADMIN_FETCH_SUPPORT,
  ADMIN_CREATE_SUPPORT,
  CHANGE_USER_STATUS,
  ADMIN_SELECT_CATEGORY,
  ADMIN_FETCH_CUSTOM_FIELDS,
  ADMIN_SELECT_CUSTOM_FIELD,
  ADMIN_FETCH_FILTERS,
} from "./constants";

export const initialState = {
  singleModel: {},
  createdArchitectUser: {},
  createdSupportUser: {},
  users: {},
  support: {},
  partners: {},
  latestUsers: {},
  endUsers: {},
  models: {},
  filters: {},
  materials: [],
  customFields: [],
  categories: {},
  selectedCategory: {},
  singleUser: {},
  selectedCustomField: {},
  singleEndUser: {},
  duplicatedModel: {},
  loading: false,
  error: [],
  success: "",
};

const error = (state, action) => {
  return {
    ...state,
    error: action.payload,
    loading: false,
  };
};

const loading = (state, action) => {
  return {
    ...state,
    loading: true,
    error: [],
  };
};
const success = (state, action) => {
  return {
    ...state,
    loading: false,
    error: [],
    success: action.payload,
  };
};
const duplicateModelSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: [],
    duplicatedModel: action.payload,
  };
};

const fetchUsersSuccess = (state, action) => {
  return {
    ...state,
    users: action.payload,
    error: [],

    loading: false,
  };
};
const fetchSupportSuccess = (state, action) => {
  return {
    ...state,
    support: action.payload,
    error: [],

    loading: false,
  };
};
const fetchLatestUsersSuccess = (state, action) => {
  return {
    ...state,

    latestUsers: action.payload,
    error: [],

    loading: false,
  };
};
const fetchUsersSearchSuccess = (state, action) => {
  return {
    ...state,
    users: action.payload,
    error: [],

    loading: false,
  };
};
const fetchModelsSuccess = (state, action) => {
  return {
    ...state,
    models: action.payload,
    singleModel: {},
    error: [],
    loading: false,
  };
};
const fetchSingleModelsSuccess = (state, action) => {
  return {
    ...state,
    singleModel: action.payload,
    duplicatedModel: {},
    error: [],
    loading: false,
  };
};
const fetchSingleEndSuccess = (state, action) => {
  return {
    ...state,
    singleEndUser: action.payload,
    error: [],
    loading: false,
  };
};
const fetchEndUsersSuccess = (state, action) => {
  return {
    ...state,
    endUsers: action.payload,
    error: [],
    loading: false,
  };
};
const getFilters = (state, action) => {
  return {
    ...state,
    filters: action.payload,
    error: [],
    loading: false,
  };
};
const createdArchitectUserSuccess = (state, action) => {
  return {
    ...state,
    createdArchitectUser: action.payload,
    error: [],
    loading: false,
  };
};
const createdSupportUserSuccess = (state, action) => {
  return {
    ...state,
    createdSupportUser: action.payload,
    error: [],
    loading: false,
  };
};

const fetchCustomFields = (state, action) => {
  return {
    ...state,
    customFields: action.payload,
    error: [],
    loading: false,
  };
};

const selectCategory = (state, action) => {
  return {
    ...state,
    selectedCategory: action.payload,
    error: [],
    loading: false,
  };
};

const selectCustomField = (state, action) => {
  return {
    ...state,
    selectedCustomField: action.payload,
    error: [],
    loading: false,
  };
};

const fetchCategories = (state, action) => {
  return {
    ...state,
    categories: action.payload,
    error: [],
    loading: false,
  };
};

const fetchSingleUserSuccess = (state, action) => {
  return {
    ...state,
    singleUser: action.payload,
    error: [],
    loading: false,
  };
};
const fetchPartnersSuccess = (state, action) => {
  return {
    ...state,
    partners: action.payload,
    error: [],
    loading: false,
  };
};
const changeUserStatus = (state, action) => {
  return {
    ...state,
    singleEndUser: action.payload,
    error: [],
    loading: false,
  };
};
function errorClear(state) {
  const newState = { ...state };

  newState.error = [];
  return newState;
}
const handlers = {
  [ADMIN_ERROR]: error,
  [ADMIN_LOADING]: loading,
  [ADMIN_SUCCESS]: success,
  [ADMIN_FETCH_USERS]: fetchUsersSuccess,
  [ADMIN_FETCH_MODELS]: fetchModelsSuccess,
  [ADMIN_FETCH_SUPPORT]: fetchSupportSuccess,
  [ADMIN_FETCH_SINGLE_MODEL]: fetchSingleModelsSuccess,
  [ADMIN_CREATE_ARCHITECT]: createdArchitectUserSuccess,
  [ADMIN_CREATE_SUPPORT]: createdSupportUserSuccess,
  [ADMIN_FETCH_END_USERS]: fetchEndUsersSuccess,
  [ADMIN_FETCH_SINGLE_USER]: fetchSingleUserSuccess,
  [ADMIN_ERROR_CLEAR]: errorClear,
  [ADMIN_FETCH_SINGLE_END_USER]: fetchSingleEndSuccess,
  [ADMIN_FETCH_USERS_SEARCH]: fetchUsersSearchSuccess,
  [ADMIN_FETCH_CUSTOM_FIELDS]: fetchCustomFields,
  [ADMIN_SELECT_CATEGORY]: selectCategory,
  [ADMIN_FETCH_FILTERS]: getFilters,
  [ADMIN_SELECT_CUSTOM_FIELD]: selectCustomField,
  [ADMIN_FETCH_LATEST_USERS]: fetchLatestUsersSuccess,
  [ADMIN_FETCH_CATEGORIES]: fetchCategories,
  [DUPLICATE_MODEL]: duplicateModelSuccess,
  [FETCH_PARTNERS]: fetchPartnersSuccess,
  [CHANGE_USER_STATUS]: changeUserStatus,
};

export default function adminData(state = initialState, action) {
  const handler = handlers[action.type];

  if (!handler) return state;
  return handler(state, action);
}
