import {
  USER_DATA,
  USER_DATA_LOADING,
  USER_DATA_ERROR,
  USER_DATA_ERROR_CLEAR,
  USER_DATA_PROFILE_PICTURE,
  SIGN_UP_END_USER,
  SIGN_UP_END_USER_ERROR,
  SIGN_UP_END_USER_LOADING,
  RESET_SIGIN_UPDATA,
  REMOVE_MODEL_TO_FAVORITE_SUCCESS,
  ADD_MODEL_TO_FAVORITE_SUCCESS,
  NOT_AUTH_USER_FAVORITE,
} from "./constants";

export const initialState = {
  user: {},
  userError: [],
  userLoading: false,
  notAuthFavorite: "",
  endUserSaved: false,
  endUserSignUpError: [],
  endUserSignUpLoading: false,
};

function resetSignUpData(state, action) {
  return {
    ...state,
    endUserSaved: false,
    endUserSignUpError: [],
    endUserSignUpLoading: false,
  };
}

function profilePicture(state, action) {
  const newState = { ...state, action };

  newState.userLoading = false;
  newState.userError = [];
  return newState;
}

const userDataError = (state, action) => {
  const newState = { ...state };

  newState.userLoading = false;
  newState.userError = action.payload;

  return newState;
};

const userDataLoading = (state, action) => {
  const newState = { ...state };

  newState.userLoading = true;
  newState.userError = [];
  return newState;
};

function updateUserData(state, action) {
  const newState = { ...state };

  newState.user = action.payload;
  newState.userLoading = false;
  newState.userError = [];
  return newState;
}

function errorClear(state) {
  const newState = { ...state };

  newState.userError = [];
  return newState;
}

function endUserSaved(state, action) {
  const newState = { ...state };

  newState.endUserSaved = true;
  newState.endUserSignUpError = [];
  newState.endUserSignUpLoading = false;
  return newState;
}

function endUserSignUpError(state, action) {
  return {
    ...state,
    endUserSignUpError: action.payload,
    endUserSignUpLoading: false,
  };
}

function endUserSignUpLoading(state, action) {
  return {
    ...state,
    endUserSignUpLoading: true,
  };
}
function addModelToFavorite(state, action) {
  const newState = { ...state };

  newState.user = action.payload;
  newState.userLoading = false;
  newState.userError = [];
  return newState;
}
function removeModelToFavorite(state, action) {
  const newState = { ...state };

  newState.loading = false;
  newState.error = [];
  return newState;
}
const saveNotAuthUserFavorite = (state, action) => {
  const newState = { ...state };
  newState.notAuthFavorite = action.payload;
  return newState;
};
export default function userData(state = initialState, action) {
  switch (action.type) {
    case RESET_SIGIN_UPDATA:
      return resetSignUpData(state, action);
    case SIGN_UP_END_USER_LOADING:
      return endUserSignUpLoading(state, action);
    case SIGN_UP_END_USER_ERROR:
      return endUserSignUpError(state, action);
    case SIGN_UP_END_USER:
      return endUserSaved(state, action);
    case USER_DATA:
      return updateUserData(state, action);
    case USER_DATA_ERROR:
      return userDataError(state, action);
    case USER_DATA_LOADING:
      return userDataLoading(state, action);
    case USER_DATA_PROFILE_PICTURE:
      return profilePicture(state, action);
    case USER_DATA_ERROR_CLEAR:
      return errorClear(state);
    case ADD_MODEL_TO_FAVORITE_SUCCESS:
      return addModelToFavorite(state, action);
    case REMOVE_MODEL_TO_FAVORITE_SUCCESS:
      return removeModelToFavorite(state, action);
    case NOT_AUTH_USER_FAVORITE:
      return saveNotAuthUserFavorite(state, action);
    default:
      return state;
  }
}
