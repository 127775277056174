import { request } from "../api.service";

export const getPartnersRequest = () => {
  return request(`admin/users/partner-user`, "get");
};

export const getActivePartnersRequest = () => {
  return request(`admin/users/partner-user?status=true`, "get");
};

export const deletePartnerRequest = (id) => {
  return request(`admin/users/partner-user/remove/${id}`, "delete");
};

export const assignClientsToPartnerRequest = (data) => {
  return request("admin/users/partner-user/assign-aUser", "post", data);
};
