import React from "react";
import { Outlet } from "react-router-dom";

import Version from "../../ui/primitives/Version";
import Footer from "../../ui/nav/Footer";
import SidebarDashboard from "../../ui/nav/SidebarDashboard";
const AdminDashboard = () => {
  return (
    <div className="p-2">
      <SidebarDashboard />
      <div className="lg:ml-64 ">
        <div className="flex flex-col xl:flex-row w-auto">
          <Outlet />
        </div>
        <Footer />
      </div>
      <Version />
    </div>
  );
};

export default AdminDashboard;
