import React from "react";
import { Link } from "react-router-dom";

class ErrorPage extends React.Component {
  render() {
    return (
      <div className="mt-10 w-full flex justify-center  items-center flex-col">
        <h3 className={"text-center"}> Something went wrong</h3>
        <br />
        <p style={{ textAlign: "center" }}>
          <Link to="/">Go to Home </Link>
        </p>
      </div>
    );
  }
}
export default ErrorPage;
